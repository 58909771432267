import { Autocomplete, Box, Chip, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Control, Controller, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FilterResultsDate, FilterResultsFormValues, ResultIssuer } from 'models/result.model';
import CustomDatePicker from 'components/custom-date-calendar/custom-date-picker';
import { redesignColors } from 'resources/theme/theme.colors';

interface CentersFormProps {
  register: UseFormRegister<FilterResultsFormValues>;
  watch: UseFormWatch<FilterResultsFormValues>;
  control: Control<FilterResultsFormValues>;
  defaultValues: FilterResultsFormValues;
  setValue: UseFormSetValue<FilterResultsFormValues>;
  doctors: ResultIssuer[];
}
export const mockTags = [
  { id: 10, name: 'Tag 1' },
  { id: 20, name: 'Tag 2' },
  { id: 30, name: 'Tag 3' },
];

const ResultsForm: FC<CentersFormProps> = ({ doctors: allDoctors, register, control, watch, defaultValues, setValue }) => {
  const { t } = useTranslation();

  const { date, doctors: doctorIds, tags } = watch();

  const updateArrayItem = (name: 'doctors' | 'tags', eventValue: number[]) => {
    const allIds = [...watch(name), ...eventValue];
    const updatedValue = [...new Set<number>(allIds)];
    setValue(name, updatedValue);
  };
  return (
    <form>
      <Grid container>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('result_doctor.filter_drawer.tag')}
            </Typography>
            <Select
              variant='filled'
              multiple
              displayEmpty
              value={tags}
              sx={{ width: '100%', mb: 2, '& .MuiSelect-select': { paddingTop: '16px !important', paddingBottom: '12px !important' } }}
              renderValue={() => (
                <Typography variant='body1' color={redesignColors.grey.grey2}>
                  {t('result_doctor.filter_drawer.choose_tags')}
                </Typography>
              )}
              onChange={(event) => updateArrayItem('tags', event.target.value as number[])}
            >
              {mockTags.map((doctor) => (
                <MenuItem value={doctor.id} key={doctor.id}>
                  {doctor.name}
                </MenuItem>
              ))}
            </Select>
            <Box>
              {tags.map((id) => {
                const tag = mockTags.find((doc) => doc.id === id);

                return (
                  !!tag && (
                    <Chip
                      variant='outlined'
                      color='secondary'
                      label={tag.name}
                      onDelete={() =>
                        setValue(
                          'tags',
                          tags.filter((docId) => docId !== id),
                        )
                      }
                      sx={{ mr: 1, mb: 1 }}
                    />
                  )
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('result_doctor.filter_drawer.doctor')}
            </Typography>
            <Select
              variant='filled'
              disabled={allDoctors.length === 0}
              multiple
              displayEmpty
              value={doctorIds}
              sx={{ width: '100%', mb: 2, '& .MuiSelect-select': { paddingTop: '16px !important', paddingBottom: '12px !important' } }}
              renderValue={() => (
                <Typography variant='body1' color={redesignColors.grey.grey2}>
                  {t('result_doctor.filter_drawer.choose_doctors')}
                </Typography>
              )}
              onChange={(event) => updateArrayItem('doctors', event.target.value as number[])}
            >
              {allDoctors.map((doctor) => (
                <MenuItem value={doctor.id} key={doctor.id}>
                  {doctor.first_name} {doctor.last_name}
                </MenuItem>
              ))}
            </Select>
            <Box>
              {doctorIds.map((id) => {
                const doctor = allDoctors.find((doc) => doc.id === id);
                return (
                  !!doctor && (
                    <Chip
                      variant='outlined'
                      color='secondary'
                      label={`${doctor.first_name} ${doctor.last_name}`}
                      onDelete={() =>
                        setValue(
                          'doctors',
                          doctorIds.filter((docId) => docId !== id),
                        )
                      }
                      sx={{ mr: 1, mb: 1 }}
                    />
                  )
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('result_doctor.filter_drawer.date')}
            </Typography>
            <RadioGroup defaultValue={defaultValues.date} name='date'>
              <FormControlLabel {...register('date')} value={FilterResultsDate.all} control={<Radio />} label={t('result_doctor.filter_drawer.all_time')} />
              <FormControlLabel {...register('date')} value={FilterResultsDate.today} control={<Radio />} label={t('result_doctor.filter_drawer.today')} />
              <FormControlLabel {...register('date')} value={FilterResultsDate.lastMonth} control={<Radio />} label={t('result_doctor.filter_drawer.last_month')} />
              <FormControlLabel {...register('date')} value={FilterResultsDate.last12Month} control={<Radio />} label={t('result_doctor.filter_drawer.last_12_months')} />
              <FormControlLabel {...register('date')} value={FilterResultsDate.datesRange} control={<Radio />} label={t('result_doctor.filter_drawer.choose_dates')} />
            </RadioGroup>
          </Grid>
          {date === FilterResultsDate.datesRange && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Controller
                control={control}
                name='datesRangeFrom'
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomDatePicker
                    fullWidth
                    format='yyyy-MM-dd'
                    sx={{ width: '48%' }}
                    value={value}
                    defaultValue={defaultValues.datesRangeFrom}
                    label={t('result_doctor.filter_drawer.from_date')}
                    onChange={onChange}
                    slotProps={{
                      textField: {
                        inputProps: {
                          onBlur: onBlur,
                        },
                      },
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name='datesRangeTo'
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomDatePicker
                    fullWidth
                    format='yyyy-MM-dd'
                    sx={{ width: '48%' }}
                    value={value}
                    defaultValue={defaultValues.datesRangeFrom}
                    label={t('result_doctor.filter_drawer.to_date')}
                    onChange={onChange}
                    slotProps={{
                      textField: {
                        inputProps: {
                          onBlur: onBlur,
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default ResultsForm;
