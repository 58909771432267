import theme_color, { redesignColors } from 'resources/theme/theme.colors';
import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiSlider: {
      defaultProps: {
        disabled: true,
      },
      styleOverrides: {
        root: ({ ownerState }) =>
          ownerState.color === 'secondary' && {
            width: '150px',
            '& .MuiSlider-track': {
              display: 'none',
            },
            '& .MuiSlider-rail': {
              backgroundColor: redesignColors.grey.grey2,
              height: ownerState.size==='medium'?'16px': '8px',
            },
            '& .MuiSlider-thumb': {
              height:  ownerState.size==='medium'?'16px':'8px',
              width: ownerState.size==='medium' ?'16px':'8px',
              color:
                typeof ownerState?.defaultValue === 'number' && ownerState.defaultValue >= 0 && ownerState.defaultValue <= 100 ? theme_color.palette.graph.success : theme_color.palette.graph.error,
              '&:before': {
                boxShadow: 'none',
              },
            },
          },
      },
    },
  },
});

export default theme;
