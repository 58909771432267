import { ArrowBackIos, ArrowForwardIos, ArrowLeft, ArrowRight } from "@mui/icons-material";
import { TextField, InputAdornment, styled, IconButton } from "@mui/material";
import { FC, MouseEvent } from "react";

interface DateFieldProps {
   handleClick: (e: MouseEvent<HTMLInputElement>) => void;
   setPickedYear: (year: number) => void;
   pickedMonth: number;
   pickedMonthString: string;
   pickedYear: number;
   showMonth: boolean;
   variant: 'outlined' | 'standard';
   fullWidth: boolean;
}

const StyledIconButton = styled(IconButton)({
   width: '1rem',
   height: '1rem',
});


const DateField: FC<DateFieldProps> = ({
   handleClick,
   setPickedYear,
   pickedMonth,
   pickedMonthString,
   pickedYear,
   showMonth = true,
   fullWidth = false,
   variant = 'outlined'
}) => {
   return (
      <TextField
            sx={{ 
               width: fullWidth ? '100%' : '30%', 
               "& .MuiInputBase-root.Mui-disabled": {
                  color: "rgba(0, 0, 0, 1)",
                  fontWeight: 600,
               },
               borderRadius: '8px',
            }}
            InputProps={{
               onClick: handleClick,
               sx: {
                  borderRadius: '8px',
                  height: '44px',
               },
               inputProps: {
                  style: {
                     textAlign: 'center',
                     color: 'black',
                     caretColor: 'transparent',
                     backgroundColor: variant === 'standard' ? 'transparent' : 'white',
                  }
               },
               startAdornment: (
                  <InputAdornment position="start">
                     <StyledIconButton onClick={(e) => {e.preventDefault(); setPickedYear(pickedYear-1)}}>
                        {variant === 'outlined' && <ArrowBackIos sx={{height: '1rem', width: '1rem', alignSelf: 'center'}} />}
                        {variant === 'standard' && <ArrowLeft sx={{height: '1.5rem', width: '1.5rem'}} />}
                     </StyledIconButton>
                  </InputAdornment>
               ),
               endAdornment: (
                  <InputAdornment position="end">
                     <StyledIconButton onClick={(e) => {e.preventDefault(); setPickedYear(pickedYear+1)}}>
                        {variant === 'outlined' && <ArrowForwardIos sx={{height: '1rem', width: '1rem'}} />}
                        {variant === 'standard' && <ArrowRight sx={{height: '1.5rem', width: '1.5rem'}} />}
                     </StyledIconButton>
                  </InputAdornment>
               ),
               disableUnderline: true,
            }}
            variant={variant}
            value={showMonth ? `${pickedMonthString}, ${pickedYear}` : pickedYear}
         />
   );
};

export default DateField;