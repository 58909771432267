import { ListItem, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { FC, ReactElement } from 'react';
import { ReactComponent as RightArowIcon } from 'resources/icons/right_arrow.svg';
import { redesignColors } from 'resources/theme/theme.colors';

const HomepageListingItems: FC<{ title: ReactElement; showIcon?: boolean }> = ({ title, showIcon = true }) => {
  return (
    <ListItem disableGutters sx={{ borderBottom: `1px solid ${redesignColors.grey.grey4}`, pr: 1 }}>
      <ListItemIcon sx={{ minWidth: 'auto', marginRight: '10px' }}></ListItemIcon>
      <ListItemText primary={title} />
      {showIcon && (
        <SvgIcon
          component={RightArowIcon}
          sx={{
            color: 'text.secondary',
          }}
        />
      )}
    </ListItem>
  );
};

export default HomepageListingItems;
