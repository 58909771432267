import { useCallback, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import api from 'api';
import { useNotification } from 'hooks';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ContactRequest } from 'models/contact-request.model';
import ContextLink from 'components/_base/ContextLink';
import { redesignColors } from 'resources/theme/theme.colors';

const ContactForm = () => {
  const { t } = useTranslation();
  const { notification } = useNotification();

  const [submitting, setSubmitting] = useState(false);
  const [readPrivacy, setReadPrivacy] = useState(false);
  const { register, handleSubmit, formState, watch } = useForm<ContactRequest>({
    mode: 'onChange',
  });
  const onSubmit: SubmitHandler<ContactRequest> = useCallback(
    (formData) => {
      setSubmitting(true);
      const requestData: ContactRequest = {
        subject: formData.subject,
        message: formData.message,
      };
      api.contact
        .postContact(requestData)
        .then(() => {
          notification(t('contact.notifications.success'), 'success');
        })
        .catch((error) => {
          notification(t('contact.notifications.error'), 'error');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [notification, t],
  );

  return (
    <Card
      sx={{
        width: 'auto',
      }}
    >
      <CardContent>
        <Grid item xs={12} md={6} p={0}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: 'grid', gridTemplateRows: '1fr 6fr 1fr', gap: 2 }}>
              <FormControl fullWidth>
                <TextField select {...register('subject', { required: true })} defaultValue={undefined} variant='filled' label={t('contact.form.subject')}>
                  <MenuItem value='Results'>Results</MenuItem>
                  <MenuItem value='Covid'>Covid</MenuItem>
                  <MenuItem value='billing'>billing</MenuItem>
                </TextField>
              </FormControl>
              <TextField
                variant='filled'
                multiline
                {...register('message', { required: true })}
                defaultValue={undefined}
                label={t('contact.form.message')}
                fullWidth
                sx={{
                  '&>.MuiFilledInput-root': {
                    height: '100%',
                    '&>.MuiFilledInput-input': {
                      height: '100% !important',
                      overflow: 'auto !important',
                    },
                  },
                }}
              />
              <FormControlLabel
                control={<Checkbox value={readPrivacy} onChange={(_, checked) => setReadPrivacy(checked)} sx={{ mx: 1 }} />}
                label={
                  <Typography display='flex'>
                    {t('contact.form.privacy_policy.label')}&nbsp;:&nbsp;
                    <ContextLink to='GDPR'>
                      <Typography sx={{ color: redesignColors.blue.dark, textDecoration: 'underline' }}> {t('contact.form.privacy_policy.link')}</Typography>
                    </ContextLink>
                  </Typography>
                }
              />
              <Box sx={{ display: 'flex' }}>
                <LoadingButton type='submit' variant='contained' disabled={!formState.isValid || !readPrivacy} loading={submitting} sx={{ width: '328px' }}>
                  {t('contact.form.submit')}
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContactForm;
