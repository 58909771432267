import { formatToLocaleNumber } from 'utils/func/Number.func';

export const formatUsualValues = (minValue: number, maxValue: number, unit: string, limitText?: string) => {
  if (minValue !== null && maxValue === null) {
    return `> ${formatToLocaleNumber(minValue)} ${unit || ''}`;
  }

  if (minValue === null && maxValue !== null) {
    return `< ${formatToLocaleNumber(maxValue)} ${unit || ''}`;
  }

  if (minValue === null && maxValue === null) {
    if (limitText !== null) {
      return limitText;
    } else {
      return `-`;
    }
  }

  if (minValue !== null && maxValue !== null) {
    return `${formatToLocaleNumber(minValue)}-${formatToLocaleNumber(maxValue)} ${unit || ''}`;
  }
};

export const isOK = (value: number, min: number, max: number) => {
  if (!min) min = value;
  if (!max) max = value;
  return value >= min && value <= max;
};

export const values = (type: string, value: string, unit: string) => {
  if (value === '' && type === 'title') {
    return '';
  }

  if (value === null && type === 'value') {
    return '-';
  }

  if (value !== '' && type === 'value') {
    return formatToLocaleNumber(value) + ' ';
  }
};
