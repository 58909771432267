import { Card, CardContent, Grid, Tab, Tabs } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import ContextLink from 'components/_base/ContextLink';
import { useNotification } from 'hooks';
import { InvoicesResult, InvoiceStatus } from 'models/invoices.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import InvoicesTable from './components/InvoicesTable';
import { redesignColors } from 'resources/theme/theme.colors';

const Invoices: FC = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const [invoices, setInvoices] = useState<InvoicesResult[]>();
  const paginatorData: GenericPaginator = {
    total: 0,
    size: DEFAULT_PAGINATION_SIZE,
    page: 1,
  };
  const [paginator, setPaginator] = useState(paginatorData);
  const [isLoading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(InvoiceStatus.OPEN);
  const { notification } = useNotification();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    currentUser &&
      api.invoices
        .getInvoices(paginator.size, paginator.page, currentUser.id, selectedTab)
        .then((res) => {
          setInvoices(res.results);
          setPaginator({
            total: res.total,
            size: res.size,
            page: res.page,
          });
          setLoading(false);
        })
        .catch(() => notification(t('invoices.notification.error'), 'error'));
  }, [currentUser, dispatch, selectedTab, notification, paginator.size, paginator.page, t]);

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginator(pagination);
  };

  const handleChange = (event: React.SyntheticEvent, selectedTab: InvoiceStatus) => {
    setSelectedTab(selectedTab);
  };

  return (
    <BodyTemplate title={t('bodyTemplate.invoices')}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant='scrollable'
            sx={{ borderBottom: `1px solid ${redesignColors.grey.grey4}` }}
            TabIndicatorProps={{
              sx: { background: 'transparent', display: 'flex', justifyContent: 'center', height: '3px' },
              children: <div className='MuiTabs-indicatorSpan' style={{ width: '38px', background: redesignColors.blue.main, borderRadius: '100px 100px 0 0' }} />,
            }}
          >
            <Tab label={<ContextLink to={'INVOICES_OPEN'}>{t('invoices.to_pay')}</ContextLink>} value={InvoiceStatus.OPEN} />
            <Tab label={<ContextLink to={'INVOICES_PAID'}>{t('invoices.paid_plural')}</ContextLink>} value={InvoiceStatus.PAID} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ p: 0 }}>
            <CardContent>
              <InvoicesTable toPayTab={selectedTab === InvoiceStatus.OPEN} invoices={invoices} pagination={paginator} onPaginationChange={handlePaginationChange} isLoading={isLoading} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Invoices;
