
import { Divider, Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { FC, useState, MouseEvent } from "react";
import DatePopover from "./components/DatePopover";
import DateField from "./components/DateField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DaySlider from "./components/DaySlider";
import { AvailableBookingSlots } from "models/available-appointment-slots.model";
import AvailableSlots from "./components/AvailableSlots";

interface AppointmentCalendarProps {
   pickedYear: number;
   pickedMonth: number;
   pickedDay: number;
   pickedTime: string;
   setPickedYear: (year: number) => void;
   setPickedMonth: (month: number) => void;
   setPickedDay: (day: number) => void;
   setPickedTime: (time: string) => void;
   availableSlots: AvailableBookingSlots[];
}

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const AppointmentCalendar: FC<AppointmentCalendarProps> = ({
   pickedMonth,
   pickedYear,
   pickedDay,
   pickedTime,
   setPickedMonth,
   setPickedYear,
   setPickedDay,
   setPickedTime,
   availableSlots
}) => {
   const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
   
   const handleDateFieldClick = (e: MouseEvent<HTMLInputElement>) => {
      e.preventDefault();
      setAnchorEl(e.currentTarget);
   }

   const handlePopoverClose = () => {
      setAnchorEl(null);
   }

   const handleYearChange = (year: number) => {
      if (dayjs().year() <= year) {
         setPickedYear(year);
      }
   };

   return (
      <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={{ monthShort: 'MMMM' }}>
         <Stack sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', paddingTop: '8px' }} spacing={2}>
            <DateField pickedMonth={pickedMonth} pickedMonthString={months[pickedMonth]} pickedYear={pickedYear} handleClick={handleDateFieldClick} setPickedYear={handleYearChange} showMonth={true} variant="outlined" fullWidth={false} />
            <DatePopover pickedMonth={pickedMonth} pickedMonthString={months[pickedMonth]} pickedYear={pickedYear} handleClose={handlePopoverClose} anchorEl={anchorEl} setPickedMonth={setPickedMonth} setPickedYear={handleYearChange} setPickedDay={setPickedDay}/>

            <DaySlider pickedMonth={pickedMonth} pickedYear={pickedYear} setPickedDay={setPickedDay} setPickedTime={setPickedTime} availableSlots={availableSlots}/>

            {pickedDay !== -1 && <AvailableSlots slots={availableSlots.find(slot => slot.date === `${pickedYear}-${pickedMonth+1}-${pickedDay+1}`)?.slots || []}  pickedTime={pickedTime} setPickedTime={setPickedTime} />}
         </Stack>
      </LocalizationProvider>
   )
};

export default AppointmentCalendar;