import { Button, IconButton, Stack, StepButton, styled, SvgIcon, ToggleButton, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as plusIcon } from 'resources/icons/plus.svg';
import { ReactComponent as deleteIcon } from 'resources/icons/delete.svg';
import CheckMark from 'resources/icons/checkmark.svg';
import AddPatientModal from './AddPatientModal';
import { PatientType, Patient } from '../types/Patient';
import { useTranslation } from 'react-i18next';
import { useAuthenticatedUser } from 'hooks';

interface ChoosePatientsProps {
  setNextButtonActive: (state: boolean) => void;
}

const stackStyles = { width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 0 };

const buttonStyles = {
  mt: 0,
  border: '1px solid #7A9099',
  borderRadius: '8px',
  padding: '12px 32px 12px 32px',
};

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '100%',
  height: '44px',
  ...buttonStyles,
  [`&.Mui-selected`]: {
    border: 'none',
    background: '98% 50% no-repeat url("' + CheckMark + '"), ' + theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const ChoosePatients: FC<ChoosePatientsProps> = ({ setNextButtonActive }) => {
  const authenticatedUser = useAuthenticatedUser();

  const {t} = useTranslation();
  const [patients, setPatients] = useState<Patient[]>([
    {
      key: 0,
      type: PatientType.Adult,
      selected: true,
    },
  ]);

  useEffect(() => {
    if (authenticatedUser) {
      setPatients([
        {
          key: 0,
          type: PatientType.Adult,
          isCurrentUser: true,
          name: `${authenticatedUser.first_name} ${authenticatedUser.last_name}`,
          selected: true,
          id: authenticatedUser.id,
        },
      ]);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    if (patients.length > 0 && patients.some((patient) => patient.selected)) {
      setNextButtonActive(true);
    } else {
      setNextButtonActive(false);
    }
  }, [patients, setNextButtonActive]);

  const [isModalOpen, setModalOpen] = useState(false);

  const handlePatientsChange = (key: number) => {
    const updatedPatients = patients.map((patient) => {
      if (patient.key === key) {
        return { ...patient, selected: !patient.selected };
      }

      return patient;
    });
    setPatients(updatedPatients);
  };

  const handleAddPatient = (selected: PatientType) => {
    setModalOpen(false);

    setPatients([...patients, { key: patients.length, type: selected, selected: true }]);
  };

  const handleDeletePatient = (key: number) => {
    const filteredPatient = patients.filter((patient) => patient.key !== key);

    setPatients(
      filteredPatient.map((patient, index) => {
        patient.key = index;

        return patient;
      }),
    );
  };

  return (
    <>
      <Stack sx={stackStyles} spacing={2}>
        <Typography component='h2' variant='h2' sx={{ fontWeight: 600 }}>
          {t('checkin.stepper.steps.choose_patient.title')}
        </Typography>

        <Stack sx={{ ...stackStyles, width: '50%' }} spacing={2}>
          {patients.map((patient, index) => (
            <Stack key={index} sx={{ ...stackStyles, flexDirection: 'row', alignContent: 'center', gap: '16px' }}>
              <StyledToggleButton aria-label={`patient-toggle-${index}`} key={index} value={patient.type} selected={patient.selected} onChange={(e, v) => handlePatientsChange(index)}>
                {patient.name || (patient.type === PatientType.Adult ? t('checkin.stepper.steps.choose_patient.adult') : t('checkin.stepper.steps.choose_patient.child'))} 
              </StyledToggleButton>
              <IconButton sx={{ border: 'none', marginTop: 0 }} onClick={() => handleDeletePatient(index)}>
                <SvgIcon component={deleteIcon} />
              </IconButton>
            </Stack>
          ))}
        </Stack>

        <Button sx={{ border: 'none', color: '#0F6F99' }} startIcon={<SvgIcon component={plusIcon} />} onClick={() => setModalOpen(true)}>
          {t('checkin.stepper.steps.choose_patient.button.add_patient')}
        </Button>
      </Stack>

      <AddPatientModal open={isModalOpen} onClose={() => setModalOpen(false)} onAddPatient={handleAddPatient} />
    </>
  );
};

export default ChoosePatients;
