import { AppBar, Toolbar } from '@mui/material';
import { FC } from 'react';

import HeaderRightBloc from './HeaderRightBloc';
import HeaderLeftBloc from './HeaderLeftBloc';

const UserIsNotLogged: FC = () => {
  return (
    <AppBar
      position='static'
      sx={{
        height: '72px',
        boxShadow: 'none',
        justifyContent: 'center',
        backgroundColor: 'transparent',
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'flex-end',
          padding: { xs: '18px', sm: '18px 24px 18px' },
          //boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.16)',
          minHeight: '0px !important',
        }}
      >
        <HeaderLeftBloc isOpen={false} />
        <HeaderRightBloc />
      </Toolbar>
    </AppBar>
  );
};

export default UserIsNotLogged;
