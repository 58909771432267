import { Autocomplete,  IconButton,  InputAdornment, Stack, styled, TextField, Typography } from "@mui/material";
import { FC, MouseEvent, useEffect, useState } from "react";
import { ReactComponent as ZoomIcon } from "resources/icons/zoom.svg";
import { ReactComponent as LocationIcon } from "resources/icons/location.svg";
import { useTranslation } from "react-i18next";
import SavedAddresses from "./SavedAddresses";
import { useAuthenticatedUser } from "hooks";
interface AddressProps {
   setNextButtonActive: (state: boolean) => void;
}

const stackStyles = { width: '60%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 0 };

const StyledAddressInput = styled(TextField)(({theme}) => ({
  height: '100%',
  backgroundColor: 'white',
  width: '100%',
}));

const Address: FC<AddressProps> = ({ setNextButtonActive }) => {
   const {t} = useTranslation();
   const authenticatedUser = useAuthenticatedUser();
   
   const [options, setOptions] = useState<string[]>([]);
   const [selectedAddress, setSelectedAddress] = useState("");

   useEffect(() => {
      setNextButtonActive(false);
   }, [setNextButtonActive]);

   const handleSelectAddress = (e: any, val: string | null) => {
      if (val) {
         setSelectedAddress(val);
         setNextButtonActive(true);
      } else {
         setSelectedAddress("");
         setNextButtonActive(false);
      }
   }

   const handleSelectSavedAddress = (address: string) => {
      if (address) {
         setSelectedAddress(address);
         setNextButtonActive(true);
      }
   }

   const handleSearch = async (val: string | null) => {
      if (val) {
         setOptions([val]);
      }
   };

   const handleLocation = async () => {
      let longitude = 0;
      let latitude = 0;

      navigator.geolocation.getCurrentPosition((position) => {
         longitude = position.coords.longitude;
         latitude = position.coords.latitude;

         console.log('Position:', longitude, latitude);
         //API Call to backend
      }, () => {
         alert('Unexpected error while determining your location. Please try again.');
      });
   }

   return (
      <>
         <Stack sx={stackStyles} spacing={2}>
            <Typography component='h2' variant='h2' sx={{ fontWeight: 600 }}>
               {t('checkin.stepper.steps.address.title')}
            </Typography>

            <Stack sx={{...stackStyles, alignItems: 'flex-end'}} spacing={0.5}>
               <Autocomplete
                  id="search-address"
                  sx={{
                     width: '100%',
                     height: '56px',
                     borderRadius: '8px',
                  }}
                  freeSolo
                  options={options}
                  onChange={(e, v) => handleSelectAddress(e, v)}
                  renderInput={(params) => (
                     <StyledAddressInput 
                        {...params} 
                        placeholder={t('checkin.stepper.steps.address.search_placeholder')}
                        variant="outlined"
                        onChange={(e) => handleSearch(e.target.value)}
                        InputProps={{
                           ...params.InputProps,
                           sx: {
                              borderRadius: '8px',
                           },
                           startAdornment: (
                              <InputAdornment position="start">
                                 <ZoomIcon />
                              </InputAdornment>
                           )
                        }}
                     />
                     )}
               />
               

               <IconButton sx={{
                  display: 'flex',
                  gap: '4px'
               }} onClick={handleLocation}>
                  <LocationIcon />
                  <Typography component="span" variant="button" sx={{
                     color: '#0F6F99'
                  }}>{t('checkin.stepper.steps.address.determine_location')}</Typography>
               </IconButton>
            </Stack>

            {
               (!selectedAddress && authenticatedUser) && 
               <SavedAddresses setSelectedAddress={handleSelectSavedAddress}/>
            }

            {selectedAddress && 
               <Stack sx={{...stackStyles, alignItems: 'flex-start'}} spacing={2}>
                  <Typography component="span" variant="button" sx={{
                     fontWeight: '600'
                  }}>
                     {selectedAddress}
                  </Typography>

                  <TextField multiline sx={{ width: '100%', backgroundColor: 'white' }} id="address-additional-info" placeholder={t('checkin.stepper.steps.address.additional_info_placeholder')} rows={5}/>
               </Stack>
            }
         </Stack>
      </>
   )
};

export default Address;