import { redesignColors } from 'resources/theme/theme.colors';
import { alpha, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          maxHeight: '300px',
          borderRadius: 1,
          '& .MuiMenu-paper': {
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.16)',
            border: `1px solid ${redesignColors.grey.grey3}`,
            borderRadius: '8px',
          },
          '& .MuiMenu-list': {
            backgroundColor: redesignColors.blue.blue2,
            padding: '16px 0',
          },
          '& .MuiMenuItem-root': {
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: alpha(redesignColors.blue.blue1, 0.6),
            },
            '&.Mui-selected': {
              fontWeight: '500',
              backgroundColor: redesignColors.blue.blue1,
            },
          },
        },
      },
    },
  },
});

export default theme;
