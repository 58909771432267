import { ElementType, FC } from 'react';
import { Box, Grid, Icon, SvgIcon, Typography } from '@mui/material';
import { AnalysisAttributeTag } from 'models/analysis-attribute.model';
import { Analysis } from 'models/analysis.model';
import { ReactComponent as AddDelayIcon } from 'resources/icons/catalogue-analysis/add_delay.svg';
import { ReactComponent as CommentIcon } from 'resources/icons/catalogue-analysis/comment.svg';
import { ReactComponent as FastingIcon } from 'resources/icons/catalogue-analysis/fasting.svg';
import { ReactComponent as RefundedIcon } from 'resources/icons/catalogue-analysis/refunded.svg';
import { ReactComponent as SynonymsIcon } from 'resources/icons/catalogue-analysis/synonyms.svg';
import { ReactComponent as TransportTimeIcon } from 'resources/icons/catalogue-analysis/transport_time.svg';
import { ReactComponent as TubeIcon } from 'resources/icons/catalogue-analysis/tube.svg';
import { ReactComponent as SubcontractedAnalysisIcon } from 'resources/icons/catalogue-analysis/subcontracted_analysis.svg';
import { ReactComponent as ResultTimeIcon } from 'resources/icons/catalogue-analysis/result_time.svg';
import { redesignColors } from 'resources/theme/theme.colors';

type AnalysisAttributesProps = {
  analysis: Analysis;
};

const tagIconMap: Record<AnalysisAttributeTag, ElementType<any> | null> = {
  [AnalysisAttributeTag.tubes]: TubeIcon,
  [AnalysisAttributeTag.add_delay]: AddDelayIcon,
  [AnalysisAttributeTag.sampling_comment]: CommentIcon,
  [AnalysisAttributeTag.fasting]: FastingIcon,
  [AnalysisAttributeTag.refund_yes]: RefundedIcon,
  [AnalysisAttributeTag.delay]: ResultTimeIcon,
  [AnalysisAttributeTag.subcontracted]: SubcontractedAnalysisIcon,
  [AnalysisAttributeTag.synonyms]: SynonymsIcon,
  [AnalysisAttributeTag.transport_delay]: TransportTimeIcon,
  [AnalysisAttributeTag.refund_no]: null,
  [AnalysisAttributeTag.shipping_costs]: null,
  [AnalysisAttributeTag.diet]: null,
  [AnalysisAttributeTag.home_forbidden]: null,
};

const AnalysisAttributes: FC<AnalysisAttributesProps> = ({ analysis }) => {
  return (
    <Grid container display='flex' textAlign='center' spacing={2} p={3}>
      {analysis.attributes.map((attribute, key) => {
        const TagIcon = tagIconMap[attribute.tag];

        return (
          <Grid item xs={12} sm={6} md={3} key={key} py={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                border: `1px solid ${redesignColors.grey.grey4}`,
                borderRadius: 1,
              }}
              display='flex'
              flexDirection='column'
              textAlign='left'
              p={1}
            >
              {TagIcon ? (
                <SvgIcon fontSize='large' sx={{ fontSize: '40px', mb: 1 }} component={TagIcon} />
              ) : (
                <Icon sx={{ fontSize: 34, height: 'auto', mb: 1 }}>
                  <img src={attribute.icon} alt={attribute.tag} />
                </Icon>
              )}

              <Typography variant='subtitle1' sx={{ fontSize: 14, color: redesignColors.dark }}>
                {attribute.name}
              </Typography>
              <Typography sx={{ fontSize: 14, color: redesignColors.grey.grey1 }}>{attribute.value}</Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AnalysisAttributes;
