import { Box, Button, Popover, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as FillIcon } from 'resources/icons/fill.svg';
import { ReactComponent as CloseIcon } from 'resources/icons/close.svg';
import { ReactComponent as CheckIcon } from 'resources/icons/check.svg';
import { FC, useState } from 'react';
import { redesignColors } from 'resources/theme/theme.colors';
import { useTranslation } from 'react-i18next';
import { resultTagColors } from '../utils/colors';

type Props = {
  color: string;
  setColor: (val: string) => any;
};

const TagColorPicker: FC<Props> = ({ color, setColor }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <SvgIcon fontSize='small' component={FillIcon} sx={{ mr: 1, color: redesignColors.blue.main }} />
      <Button sx={{ borderRadius: '4px', border: `2px solid ${redesignColors.blue.main}`, width: '40px', height: '40px', background: color }} aria-describedby={id} onClick={handleClick}></Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Button
          variant='text'
          onClick={() => setColor(resultTagColors[0])}
          sx={{ color: redesignColors.blue.dark, display: 'flex', margin: '8px auto', p: 0.5 }}
          startIcon={<SvgIcon fontSize='small' component={CloseIcon} sx={{ fill: redesignColors.blue.dark, fontSize: '20px' }} />}
        >
          {t('result_doctor.tag_dialog.reset_color')}
        </Button>
        <Box display='flex' sx={{ width: '305px !important', height: '200px !important', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
          {resultTagColors.map((colorItem) => (
            <Box
              sx={{
                borderRadius: '4px',
                border: `2px solid ${redesignColors.blue.main}`,
                width: '40px',
                height: '40px',
                background: colorItem || 'inherit',
                margin: 0.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                setColor(colorItem);
              }}
            >
              {colorItem === color ? <SvgIcon component={CheckIcon} sx={{ fill: redesignColors.dark }} /> : null}
            </Box>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};
export default TagColorPicker;
