import * as React from 'react';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import { Box, Slider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type RatingItem = { emojiNumber: number; mark: number; rating: number; color: string; translateCode: string };
const StyledRating = styled(Rating)(({ theme }) => ({
  width: 288,
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
  '& .MuiRating-icon': {
    fontSize: '36px',
    margin: 0,
  },
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  width: 288,
  height: 8,
  padding: 0,
  marginTop: 2,
  background: 'linear-gradient(90deg, rgba(255,106,103,1) 0%, rgba(255,162,75,1) 25%, rgba(255,211,52,1) 50%, rgba(159,221,87,1) 75%, rgba(48,228,128,1) 100%)',
  '& .MuiSlider-mark': {
    width: 2,
    height: 6,
    background: 'rgba(0,0,0,0.2)',
    opacity: 0.8,
  },
  '& .MuiSlider-rail': {
    border: 0,
  },
  '& .MuiSlider-thumb': {
    border: 0,
  },
}));
const ratingItems: RatingItem[] = [
  {
    emojiNumber: 128544,
    translateCode: 'feedback.emoji_rating.terrible',
    mark: 5,
    color: '#FF6A66',
    rating: 1,
  },
  {
    emojiNumber: 128533,
    translateCode: 'feedback.emoji_rating.bad',
    mark: 25,
    color: '#FFA24B',
    rating: 2,
  },
  {
    emojiNumber: 128528,
    translateCode: 'feedback.emoji_rating.neutral',
    mark: 50,
    color: '#FFD334',
    rating: 3,
  },
  {
    emojiNumber: 128578,
    translateCode: 'feedback.emoji_rating.good',
    mark: 75,
    color: '#A0DC56',
    rating: 4,
  },
  {
    emojiNumber: 129321,
    translateCode: 'feedback.emoji_rating.excellent',
    mark: 95,
    color: '#2EE581',
    rating: 5,
  },
];

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return (
    <Box
      {...other}
      sx={{
        marginRight: 2,
      }}
    >
      {String.fromCodePoint(ratingItems.find((item) => item.rating === value)?.emojiNumber as number)}
    </Box>
  );
}

export default function EmojiRating() {
  const { t } = useTranslation();

  const [value, setValue] = React.useState<number>(5);
  const selectedValue = React.useMemo(() => {
    return ratingItems.find((item) => item.mark === value);
  }, [value]);
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <StyledRating
        readOnly
        value={selectedValue?.rating}
        name='highlight-selected-only'
        defaultValue={1}
        IconContainerComponent={IconContainer}
        getLabelText={(value: number) => '' as string}
        highlightSelectedOnly
      />
      <StyledSlider
        defaultValue={5}
        disabled={false}
        value={value}
        onChange={(_, newValue: number | number[]) => {
          setValue(newValue as number);
        }}
        slotProps={{ thumb: { style: { color: selectedValue?.color as any } } }}
        marks={Object.values(ratingItems).map((item) => ({ value: item.mark }))}
        min={0}
        max={100}
        step={null}
      />
      <Typography fontWeight='500' mt={2}>
        {t(selectedValue?.translateCode as string)}
      </Typography>
    </Box>
  );
}
