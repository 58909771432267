import { Card, CardContent, IconButton, SvgIcon, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';
import { redesignColors } from 'resources/theme/theme.colors';

type Props = {
  close: () => void;
};

const HomepageWhatsNew: FC<Props> = ({ close }) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ background: redesignColors.blue.blue1 }}>
      <CardContent sx={{ position: 'relative' }}>
        <IconButton size='small' onClick={close} sx={{ position: 'absolute', zIndex: 2, top: 2, right: 2 }}>
          <SvgIcon fontSize='small' component={IconClose} />
        </IconButton>
        <Typography variant='body2' fontSize={15} sx={{ mx: { md: 'auto' }, width: '100%' }} dangerouslySetInnerHTML={{ __html: t('homepage.whatsnew') }} />
      </CardContent>
    </Card>
  );
};

export default HomepageWhatsNew;
