import { Box, Button, Card, CardActions, CardContent, List, SvgIcon, Typography } from '@mui/material';
import api from 'api';
import ContextLink from 'components/_base/ContextLink';
import { isAfter, isEqual } from 'date-fns';
import { useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { convertDate } from 'utils/func/Date.func';
import { ReactComponent as RightArrowIcon } from 'resources/icons/right_arrow.svg';
import { redesignColors } from 'resources/theme/theme.colors';
import HomepageListingItems from './HomepageListingItems';
import { PreregistrationResponse } from 'models/preregistration.model';

const HomepagePreregistrations: FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PreregistrationResponse[]>();
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const { notification } = useNotification();

  useEffect(() => {
    currentUser &&
      api.preregistration
        .getPreregistrations(currentUser.id)
        .then((preregistrations) => setData(preregistrations.slice(0, 3)))
        .catch(() => notification(t('homepage.preregistration.notification.error'), 'error'));
  }, [currentUser, notification, t]);

  return (
    <Card
      sx={{
        display: 'grid',
        width: '100%',
        minHeight: (theme) => theme.spacing(25),
      }}
    >
      <CardContent>
        <Typography variant='h2' fontSize='20px' fontWeight='500'>
          {t('homepage.preregistration.title', { count: data ? data.length : 0 })}
        </Typography>
        <ContextLink to='PREREGISTRATIONS'>
          <List dense sx={{ mb: 2 }}>
            {data && data.length > 0 ? (
              data.map((preregistration, key: number) => {
                return (
                  <HomepageListingItems
                    title={
                      <Box sx={{ cursor: 'pointer' }}>
                        <Typography fontWeight='700'>
                          {preregistration.first_name} {preregistration.last_name}
                        </Typography>
                        <Typography fontSize='14px' variant='body2'>
                          {convertDate(preregistration.created_at, false, 'yyyy-MM-dd HH:mm')}
                        </Typography>
                      </Box>
                    }
                    key={key}
                  />
                );
              })
            ) : (
              <Typography>{t('homepage.preregistration.noMessage')}</Typography>
            )}
          </List>
        </ContextLink>
      </CardContent>
      <CardActions sx={{ alignItems: 'flex-end' }}>
        <Box width='100%' display='flex' justifyContent='flex-end'>
          <ContextLink to='PREREGISTRATIONS'>
            <Button variant='text' endIcon={<SvgIcon component={RightArrowIcon} fill={redesignColors.blue.dark} sx={{ mr: 1, fontSize: '28px !important' }} />}>
              {t('homepage.preregistration.buttonLabel')}
            </Button>
          </ContextLink>
        </Box>
      </CardActions>
    </Card>
  );
};

export default HomepagePreregistrations;
