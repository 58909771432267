import { Box, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Slider, styled, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CentersFormValues, CityChoice, ScheduleChoice, SpecialityChoice } from 'models/centers.model';
import { FC } from 'react';
import { UseFormGetValues, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { redesignColors } from 'resources/theme/theme.colors';
import { Days, Hours } from '../utils/constants';

interface CentersFormProps {
  cities: CityChoice[];
  specialities: SpecialityChoice[];
  schedules: ScheduleChoice[];
  locationEnabled?: boolean;
  register: UseFormRegister<CentersFormValues>;
  watch: UseFormWatch<CentersFormValues>;
  defaultValues: CentersFormValues;
}

export const MaxDistance = 50;
const StyledSlider = styled(Slider)(({ theme }) => ({
  height: 8,
  padding: 0,
  marginTop: 2,
  width: '100%',
  '& .MuiSlider-mark': {
    width: 2,
    height: 6,
    opacity: 0.8,
  },
  '& .MuiSlider-rail': {
    border: 0,
    background: redesignColors.grey.grey4,
  },
  '& .MuiSlider-thumb': {
    border: `2px solid ${theme.palette.secondary.main}`,
    background: theme.palette.primary.main,
  },
}));
const CentersForm: FC<CentersFormProps> = ({ cities, specialities, schedules, register, watch, defaultValues, locationEnabled }) => {
  const { t } = useTranslation();

  const { schedule, distance } = watch();
  return (
    <form>
      <Grid container>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('centers.form.city.label')}
            </Typography>
            <TextField select variant='filled' label={t('centers.form.city.label')} {...register('city')} defaultValue={defaultValues.city} fullWidth>
              <MenuItem value={-1}>{t('centers.form.city.all')}</MenuItem>
              {cities.map((e, i) => {
                return (
                  <MenuItem value={e.value} key={i}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('centers.form.speciality.title')}
            </Typography>
            <TextField
              select
              variant='filled'
              label={t('centers.form.speciality.label')}
              {...register('speciality')}
              defaultValue={defaultValues.speciality}
              inputProps={{
                name: 'speciality',
                id: 'speciality',
              }}
              fullWidth
            >
              <MenuItem value={-1}>{t('centers.form.speciality.all')}</MenuItem>
              {specialities.map((e, i) => {
                return (
                  <MenuItem value={e.value} key={i}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {locationEnabled && (
            <>
              <Grid item xs={12}>
                <Typography variant='body1' fontWeight='600' mb={1}>
                  {t('centers.form.distance.title')}
                </Typography>
                <Box>
                  <StyledSlider aria-label='Default' color='primary' {...register('distance')} max={MaxDistance} defaultValue={defaultValues.distance} disabled={false} min={0} />
                  <Typography variant='body1'>
                    {t('centers.form.distance.search_area')} {distance === MaxDistance ? `${distance}+` : distance} km
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('centers.form.schedule.label')}
            </Typography>
            <RadioGroup aria-labelledby='demo-radio-buttons-group-label' defaultValue={defaultValues.schedule} name='schedule'>
              <FormControlLabel {...register('schedule')} value='all' control={<Radio />} label={t('centers.form.schedule.all')} />
              <FormControlLabel {...register('schedule')} value='open_now' control={<Radio />} label={t('centers.form.schedule.open_now')} />
              <FormControlLabel {...register('schedule')} value='choose_day_time' control={<Radio />} label={t('centers.form.schedule.choose_day_time')} />
            </RadioGroup>
            <Grid container>
              <Grid item xs={5}>
                <TextField
                  select
                  variant='filled'
                  label={t('centers.form.schedule.day_label')}
                  {...register('day')}
                  defaultValue={defaultValues.day}
                  disabled={schedule !== 'choose_day_time'}
                  fullWidth
                >
                  {Days.map((e, i) => {
                    return (
                      <MenuItem value={e.value} key={i}>
                        {e.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  select
                  variant='filled'
                  label={t('centers.form.schedule.time_label')}
                  {...register('time')}
                  defaultValue={defaultValues.time}
                  disabled={schedule !== 'choose_day_time'}
                  fullWidth
                >
                  <MenuItem value={-1}>{t('centers.form.schedule.any')}</MenuItem>
                  {Hours.map((e, i) => {
                    return (
                      <MenuItem value={e.value} key={i}>
                        {e.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item spacing={0.5}>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('centers.form.center_types.title')}
            </Typography>
            <FormControlLabel
              {...register('laboratoire')}
              name='laboratoire'
              control={<Checkbox defaultChecked={!!defaultValues.laboratoire} />}
              label={t('centers.form.center_types.laboratoire')}
              sx={{ ml: 0 }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              {...register('labomobile')}
              name='labomobile'
              control={<Checkbox defaultChecked={!!defaultValues.labomobile} />}
              label={t('centers.form.center_types.labomobile')}
              sx={{ ml: 0 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              {...register('pharmacy')}
              name='pharmacy'
              control={<Checkbox defaultChecked={!!defaultValues.pharmacy} />}
              label={t('centers.form.center_types.pharmacy')}
              sx={{ ml: 0 }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item spacing={0.5}>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('centers.form.services_title')}
            </Typography>
            <FormControlLabel
              {...register('PRMAccessRequired')}
              name='PRMAccessRequired'
              control={<Checkbox defaultChecked={!!defaultValues.PRMAccessRequired} />}
              label={t('centers.form.prm_access')}
              sx={{ ml: 0 }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              {...register('publicParkingRequired')}
              name='publicParkingRequired'
              control={<Checkbox defaultChecked={!!defaultValues.publicParkingRequired} />}
              label={t('centers.form.parking')}
              sx={{ ml: 0 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CentersForm;
