import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, FormControl, Grid, MenuItem, TextField, Theme, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useAuthenticated, useLocale, useNotification, useIsDoctor } from 'hooks';
import { FAQ, QA } from 'models/faq.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';

const Faqpage: FC = () => {
  const [faq, setFaq] = useState<FAQ>([]);
  const [sortedFaq, setSortedFaq] = useState<FAQ>([]);

  const [tags, setTags] = useState<string[]>([]);
  const [tagSelected, setTagSelected] = useState('');

  const { t } = useTranslation();
  const isAuthenticate = useAuthenticated();
  const isDoctor = useIsDoctor();
  const locale = useLocale();
  const { notification } = useNotification();

  // Set the tags to select when the component did mount or when the language change
  useEffect(() => {
    let CategoryType = 'Public';
    if (isAuthenticate && !isDoctor) {
      CategoryType = 'Patient';
    } else if (isDoctor) {
      CategoryType = 'Médecin';
    }
    api.bnl
      .getFAQ(locale, CategoryType)
      .then((response: QA[]) => {
        setFaq(response);
        setTags(Array.from(new Set(response.map<string[]>((val) => val.tags).flat())));
        setTagSelected('all');
      })
      .catch(() => notification(t('faq.notification.error'), 'error'));
  }, [isAuthenticate, isDoctor, locale, notification, t]);

  /**
   * @desc handle the change of tag selected
   * @param event is the select change event
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagSelected(event.target.value as string);
  };

  // Update the FAQ data when the tag selected changes
  useEffect(() => {
    setSortedFaq(faq.filter((qa) => (tagSelected === 'all' ? true : qa.tags.includes(tagSelected))));
  }, [tagSelected, faq]);

  const [openedQuestions, setOpenedQuestions] = useState<string[]>([]);

  /**
   * @desc Toggles a question
   * @param id the id of the question
   */
  function toggleQuestion(id: string): void {
    if (openedQuestions.includes(id)) {
      setOpenedQuestions(openedQuestions.filter((questions) => questions !== id));
    } else {
      setOpenedQuestions([...openedQuestions, id]);
    }
  }

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleAccordionChange = (id: string, panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    toggleQuestion(id);
    setExpanded(isExpanded ? panel : false);
  };
  const linkStyle = {
    a: {
      color: (theme: Theme) => theme.palette.common.black,
      fontWeight: 500,
      textDecoration: 'none',
      '&[href^="https"], &[href^="mailto"], &[href^="tel"]': {
        color: redesignColors.blue.dark,
        borderBottom: `1px solid ${redesignColors.blue.dark}`,
        transition: (theme: Theme) => `border-bottom ${theme.transitions.duration.enteringScreen}ms  ${theme.transitions.easing.easeOut} 0ms`,
        ':hover': {
          borderColor: (theme: Theme) => theme.palette.common.black,
        },
      },
    },
    'li>a:hover': {
      borderBottom: (theme: Theme) => `1px solid ${theme.palette.common.black}`,
    },
    h2: {
      fontSize: '20px',
    },
  };

  return (
    <BodyTemplate title={t(`bodyTemplate.faq`)} backButton={!isAuthenticate}>
      <Grid>
        <Grid container justifyContent='center'>
          <Grid item xs={12}>
            <Card>
              <Box py={2}>
                <FormControl fullWidth>
                  <TextField value={tagSelected} onChange={handleChange} select variant='filled' label={t(`faq.category`)}>
                    <MenuItem value='all'>{t(`faq.all`)}</MenuItem>
                    {tags.map((tag, i) => {
                      return (
                        <MenuItem value={tag} key={`tag-${i}`}>
                          {tag}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Box>
              {sortedFaq.map((qa, key) => {
                return (
                  <Accordion key={key} onChange={handleAccordionChange(qa.id, `panel${key}`)} disableGutters expanded={expanded === `panel${key}`}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: qa.question,
                        }}
                      ></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={linkStyle}
                        dangerouslySetInnerHTML={{
                          __html: qa.answer,
                        }}
                      ></Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Faqpage;
