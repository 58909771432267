import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import ContextRedirect from 'components/_base/ContextRedirect';
import { useAuthenticated, useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import NewsCard from './components/NewsCard';
import { DataProps } from 'models/news.model';
import api from 'api';
import RoutePaths from 'utils/RoutePaths';
import { useTranslation } from 'react-i18next';
import { DrupalTypes } from 'models/drupal.model';

const NewsPage: FC = () => {
  const isAuthenticated = useAuthenticated();
  const { t, i18n } = useTranslation();
  const [newsData, setNewsData] = useState<DataProps[]>();
  const [isLoading, setIsLoading] = useState(true);
  const { notification } = useNotification();

  useEffect(() => {
    api.bnl
      .getNews()
      .then((res) => {
        const filteredNews: DataProps[] = [];
        res.data.forEach((news) => {
          filteredNews.push({
            news: news,
            file: res.included.find((included) => news.relationships.field_image.data?.id === included.id && included.type === DrupalTypes.file),
          });
        });
        setNewsData(filteredNews);
        setIsLoading(false);
      })
      .catch(() => {
        notification(t('homepage.rightDrawer.error'), 'error');
      });
  }, [i18n.language, notification, t]);

  return !isAuthenticated ? (
    <>
      <Container maxWidth={false} disableGutters sx={{ pt: 2 }}>
        <Grid
          container
          spacing={1}
          justifyContent='flex-start'
          sx={{
            alignItems: 'stretch',
            padding: 3,
          }}
        >
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography variant='h1'>{t('news_page.title')}</Typography>
            </Grid>
            {isLoading && !newsData ? (
              <Box sx={{ margin: '15% auto 0' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid item xs={12} md={6} container>
                {newsData?.map((newsItem) => (
                  <Grid item xs={12}>
                    <NewsCard newsItem={newsItem} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  ) : (
    <ContextRedirect to={RoutePaths['HOME']} />
  );
};

export default NewsPage;
