import { APIConfiguration, axiosMYLAB, axiosMYRDV } from 'api';
import { AppointmentMedicalAct, AppointmentMedicalAct2023 } from 'models/appointment.medical.act.model';
import { Appointment, AppointmentResponse, Rdv, RdvAnalyse, RdvPrescription } from 'models/appointment.model';
import { AppointmentSlots, AppointmentSlots2023 } from 'models/appointment.slots.model';
import { convertDate } from 'utils/func/Date.func';
import { BaseService } from './base.service';
import config from 'config';

class AppointmentService extends BaseService {
  async getToken() {
    // Check if token is in sessionStorage
    const token = sessionStorage.getItem('myRdvToken');
    if (token) {
      return token;
    }

    // Get token from API
    const { data } = await axiosMYRDV.post(this.apiConfig.APPOINTMENTS.GET_TOKEN, {
      username: config.myRdvApiUser,
      password: config.myRdvApiPassword,
    });

    if (!data.token) {
      throw new Error('No token found');
    }

    // Save token in sessionStorage
    sessionStorage.setItem('myRdvToken', data.token);
    return data.token;
  }

  /**
   * @deprecated Use getMedicalActs() instead
   */
  async getMedicalActs2023() {
    const { data } = await axiosMYLAB.get<AppointmentMedicalAct2023[]>(this.apiConfig.APPOINTMENTS.GET_MEDICAL_ACTS_2023(this.getLang()));

    this.log(`getMedicalActs()`);

    return data;
  }

  async getMedicalActs(lang: string) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.get<AppointmentMedicalAct[]>(this.apiConfig.APPOINTMENTS.GET_MEDICAL_ACTS(lang), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    this.log(`getMedicalActs()`);

    return data;
  }

  /**
   * @deprecated Use getSlots() instead
   */
  async getSlots2023(dateFrom: Date, dateTo: Date, medicalActsIds: string[], addressZipCode: string, addressCountryIso: string) {
    const { data } = await axiosMYLAB.post<AppointmentSlots2023>(this.apiConfig.APPOINTMENTS.GET_SLOTS_2023(this.getLang()), {
      date_from: convertDate(dateFrom.toISOString(), false, 'yyyy-MM-dd'),
      date_to: convertDate(dateTo.toISOString(), false, 'yyyy-MM-dd'),
      medical_acts: medicalActsIds.map((id) => parseInt(id)),
      zip_code: addressZipCode,
      country_iso: addressCountryIso,
    });

    this.log(`getSlots()`);

    return data;
  }

  async getSlots(dateFrom: Date, dateTo: Date, prescriptions: RdvPrescription[], lat: number, lon: number) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post<AppointmentSlots>(
      this.apiConfig.APPOINTMENTS.GET_SLOTS,
      {
        loc: [lon, lat],
        // Format date correctly with timezone offset
        start: new Date(dateFrom.getTime() - dateFrom.getTimezoneOffset() * 60000).toISOString(),
        end: new Date(dateTo.getTime() - dateTo.getTimezoneOffset() * 60000).toISOString(),
        prescriptions,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`getSlots()`);

    return data;
  }

  /**
   * @deprecated Use bookAppointment() instead
   */
  async bookAppointment2023(appointment: Appointment) {
    const { data } = await axiosMYLAB.post(this.apiConfig.APPOINTMENTS.POST_APPOINTMENT_2023(this.getLang()), appointment);

    this.log(`bookAppointment()`);

    return data;
  }

  async bookAppointment(rdv: Rdv, locale: string) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post(
      this.apiConfig.APPOINTMENTS.POST_APPOINTMENT,
      {
        ...rdv,
        locale,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`bookAppointment()`);

    return data;
  }

  async getCentersFromActs(medical_acts: RdvAnalyse[]) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post(
      `${this.apiConfig.APPOINTMENTS.GET_NEAREST_CENTERS}?nextSlot=true`,
      {
        medical_acts,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`getCentersFromActs()`);

    return data;
  }

  async getNearestCenters(lat: number, lon: number, medical_acts: RdvAnalyse[], radius?: number) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post(
      `${this.apiConfig.APPOINTMENTS.GET_NEAREST_CENTERS}?nextSlot=true`,
      {
        coord: [lon, lat],
        radius,
        medical_acts,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`getNearestCenters()`);

    return data;
  }

  async getCenterSlots(centerId: number, start: Date, end: Date, medical_acts: RdvAnalyse[]) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post(
      this.apiConfig.APPOINTMENTS.GET_CENTER_SLOTS,
      {
        centerId,
        start: new Date(start.getTime() - start.getTimezoneOffset() * 60000).toISOString(),
        end: new Date(end.getTime() - end.getTimezoneOffset() * 60000).toISOString(),
        medical_acts,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`getCenterSlots()`);

    return data;
  }

  async getAppointmentsByToken(cancelToken: string) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.get(this.apiConfig.APPOINTMENTS.GET_APPOINTMENTS_BY_TOKEN(cancelToken), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    this.log(`getAppointmentsByToken()`);

    return data;
  }

  async getAppointments(patientId: number): Promise<AppointmentResponse[]> {
    const { data } = await axiosMYLAB.get(this.apiConfig.APPOINTMENTS.GET_APPOINTMENTS(this.getLang()), {
      params: {
        patient_id: patientId,
      },
    });

    this.log(`getAppointments()`);

    return data;
  }

  async getAppointment(appointId: string): Promise<AppointmentResponse[]> {
    const { data } = await axiosMYLAB.get(this.apiConfig.APPOINTMENTS.GET_APPOINTMENT(this.getLang(), appointId), {});

    this.log(`getAppointment()`);

    return data;
  }

  async cancelAppointment(id: number) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post(
      this.apiConfig.APPOINTMENTS.CANCEL_APPOINTMENT,
      {
        appointIds: [id],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`cancelAppointment(${id})`);

    return data;
  }

  /**
   * @deprecated Use cancelAppointment() instead
   */
  async cancelAppointment2023(id: number) {
    await axiosMYLAB.delete(this.apiConfig.APPOINTMENTS.CANCEL_APPOINTMENT_2023(this.getLang(), id));

    this.log(`cancelAppointment(${id})`);
  }
}

export default (apiConfig: APIConfiguration) => {
  return new AppointmentService(apiConfig);
};
