export enum AnalysisAttributeTag {
  fasting = 'fasting',
  tubes = 'tubes',
  transport_delay = 'transport_delay',
  delay = 'delay',
  add_delay = 'add_delay',
  synonyms = 'synonyms',
  subcontracted = 'subcontracted',
  refund_yes = 'refund_yes',
  refund_no = 'refund_no',
  sampling_comment = 'sampling_comment',
  shipping_costs = 'shipping_costs',
  diet = 'diet',
  home_forbidden = 'home_forbidden',
}

export interface AnalysisAttribute {
  tag: AnalysisAttributeTag;
  name: string;
  icon: string;
  value: string;
  extra: string;
}
