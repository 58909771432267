import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { redesignColors } from '../theme.colors';


const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 52,
          height: 24,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 3,
            transitionDuration: '300ms',
            '& .MuiSwitch-input':{
              left: '0px !important'
            },
            '&.Mui-checked': {
              transform: 'translateX(28px)',
              color: '#fff',
              '& .MuiSwitch-input':{
                left: '-200% !important'
              },
              '& .MuiSwitch-thumb': {
                backgroundColor: '#ffffff',
                transitionDuration: '300ms',
              },
              '& + .MuiSwitch-track': {
                backgroundColor: redesignColors.blue.main,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            }, 
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: grey[100],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            boxShadow:'none',
            backgroundColor: redesignColors.grey.grey2,

            width: 18,
            height: 18,
          },
          '& .MuiSwitch-track': {
            borderRadius: 24 / 2,
            backgroundColor: redesignColors.grey.grey4,
            border:`1px solid ${ redesignColors.grey.grey2}`,
            opacity: 1,
            transition: '0.5s ease',
          },
        },
      },
    },

  },
});

export default theme;
