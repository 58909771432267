import { Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, SvgIcon, Typography, Stack, Grid, Divider } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as IconToken } from 'resources/icons/token.svg';
import ForgotPassword from '../form/ForgotPasswordForm';
import ResetPassword from '../form/ResetPasswordForm';
import LoginForm from '../form/LoginForm';
import LoginTokenForm from '../form/LoginTokenForm';
import GooglePlayBadge from 'resources/images/logo_googleplay.svg';
import AppStoreBadge from 'resources/images/logo_appstore.svg';
import Logo from 'resources/images/MYLAB_mono-white.svg';
import { useParams } from 'react-router-dom';
import { useContextRedirection } from 'hooks';
import RoutePaths from 'utils/RoutePaths';
import { redesignColors } from 'resources/theme/theme.colors';

export type LoginDisplayStatus = 'SELECT' | 'CLASSIC' | 'TOKEN';

const LeftCard: FC = () => {
  const { t } = useTranslation();
  const [isTokenLogin, setIsTokenLogin] = useState(false);
  const [isForgotPwd, setIsForgotPwd] = useState(false);
  const [isResetPwd, setIsResetPwd] = useState(true);
  const params = useParams();
  const navigate = useContextRedirection();

  useEffect(() => {
    if (params.resetToken) {
      setIsResetPwd(true);
    } else {
      setIsResetPwd(false);
    }
  }, [params]);

  const renderForm = () => {
    if (isForgotPwd) {
      return (
        <Box>
          <ForgotPassword />
          <Divider
            sx={{
              marginTop: '4%',
              marginBottom: '4%',
              width: '100%',
              '&::before, &::after': {
                borderColor: 'black',
              },
            }}
          >
            <Typography variant='h3' sx={{ fontSize: '16px', fontWeight: '500' }}>
              {t('landing_page.login.or')}
            </Typography>
          </Divider>
          <Button variant='link' sx={{ width: 'fit-content', color: redesignColors.blue.dark }} onClick={() => setIsForgotPwd(false)}>
            {t('landing_page.login.back_to_login')}
          </Button>
        </Box>
      );
    }
    if (isResetPwd) {
      return (
        <Box p={3}>
          <ResetPassword />

          <Button
            variant='link'
            sx={{ width: 'fit-content', color: redesignColors.blue.dark, marginTop: '4%' }}
            onClick={() => {
              setIsResetPwd(false);
              navigate(RoutePaths['LANDING_PAGE']);
            }}
          >
            {t('landing_page.login.back')}
          </Button>
        </Box>
      );
    }

    if (isTokenLogin) {
      return (
        <Box p={3}>
          <LoginTokenForm />
          <Divider
            sx={{
              marginTop: '4%',
              marginBottom: '4%',
              width: '100%',
              '&::before, &::after': {
                borderColor: 'black',
              },
            }}
          >
            <Typography variant='h3' sx={{ fontSize: '16px', fontWeight: '500' }}>
              {t('landing_page.login.or')}
            </Typography>
          </Divider>
          <Button variant='link' sx={{ width: 'fit-content', color: redesignColors.blue.dark }} onClick={() => setIsTokenLogin(false)}>
            {t('landing_page.login.back')}
          </Button>
        </Box>
      );
    }

    return (
      <>
        <Box p={3}>
          <LoginForm />
          <Divider
            sx={{
              marginTop: '4%',
              marginBottom: '4%',
              '&::before, &::after': {
                borderColor: 'black',
              },
            }}
          >
            <Typography variant='h3' sx={{ fontSize: '16px', fontWeight: '500' }}>
              {t('landing_page.login.or')}
            </Typography>
          </Divider>
          <Button
            variant='outlined'
            startIcon={<SvgIcon component={IconToken} sx={{ marginRight: '10px', width: '100%' }} />}
            onClick={() => setIsTokenLogin(true)}
            fullWidth
            sx={{ backgroundColor: '#FFFFFF' }}
          >
            {t('landing_page.login.connection_with_token')}
          </Button>
          {setIsForgotPwd && (
            <Button variant='link' sx={{ width: 'fit-content', color: redesignColors.blue.dark, marginTop: '4%' }} onClick={() => setIsForgotPwd(true)}>
              {t('landing_page.login.forgotten_password')}
            </Button>
          )}
        </Box>
        <Stack spacing={1} sx={{ alignItems: 'center' }}>
          <Grid item>
            <Grid container py={1} spacing={2}>
              <Grid item lg={12}>
                <Typography variant='body2' sx={{ fontSize: '16px', textAlign: 'center', fontWeight: '500' }}>
                  {t('landing_page.download_app')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container py={1} spacing={2} sx={{ justifyContent: 'center' }}>
              <Grid item>
                <ContextLink to='GOOGLE_PLAY_STORE' external>
                  <Box sx={{ width: 152 }} component='img' alt='Google Play badge' src={GooglePlayBadge} />
                </ContextLink>
              </Grid>
              <Grid item>
                <ContextLink to='APP_STORE' external>
                  <Box sx={{ width: 152 }} component='img' alt='App Store Badge' src={AppStoreBadge} />
                </ContextLink>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </>
    );
  };

  return (
    <Card
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        background: '#FFFFFF',
        backgroundImage: `url(${Logo}) `,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top 200px left 87px',
        backgroundSize: 'cover',
        boxShadow: 'none',
      }}
    >
      <CardContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Grid item>
          <Typography variant='h1'>{t('landing_page.login.title')}</Typography>
        </Grid>
      </CardContent>
      <CardContent sx={{ textAlign: 'center' }}>{renderForm()}</CardContent>
    </Card>
  );
};
export default LeftCard;
