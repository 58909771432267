import { GoogleMap, Marker, MarkerClusterer, useLoadScript } from '@react-google-maps/api';
import { mapStyle } from 'resources/theme/GoogleMapStyle';
import config from 'config';
import { useIsMobileView } from 'hooks';
import { Center, CenterIncludedFile, CenterIncludedService } from 'models/centers.model';
import { FC, useCallback, useEffect, useState } from 'react';
import MapMarker from './MapMarker';
import getMarkerIcon, { getPersonMarkerIcon } from '../utils/getMarkerIcon';

interface MapCentersProps {
  centersInfo: {
    center: Center;
    file: CenterIncludedFile;
    services: CenterIncludedService[];
  }[];
  myPosition:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
  markersState: boolean[];
  favorites: string[];
  handleOpenMapMarker: (index: number) => void;
  handleCloseMapMarker: () => void;
}

const defaultMapCenter = { lat: 49.8153, lng: 6.1296 }; // Luxembourg
const defaultMapZoom = 9;

const MapCenters: FC<MapCentersProps> = ({ centersInfo, myPosition, markersState, handleOpenMapMarker, favorites }) => {
  const { isLoaded: isGoogleLoaded /* , loadError: googleLoadError */ } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey || '',
  });
  const isMobileView = useIsMobileView();
  const [map, setMap] = useState<google.maps.Map>();
  const handleLoad = useCallback(function onLoad(mapInstance: google.maps.Map) {
    setMap(mapInstance);
  }, []);

  useEffect(() => {
    if (map && myPosition && !isMobileView) {
      map.panTo(myPosition);
      google.maps.event.trigger(map, 'resize');
    }
  }, [myPosition, map, isMobileView]);

  return (
    <>
      {isGoogleLoaded && (
        <GoogleMap onLoad={handleLoad} center={defaultMapCenter} zoom={defaultMapZoom} mapContainerStyle={{ height: '100%', width: '100%' }} options={{ styles: mapStyle }}>
          <MarkerClusterer>
            {() => (
              <>
                {centersInfo.map(
                  (info, index) =>
                    info.center.attributes.field_prelevement_latitude &&
                    info.center.attributes.field_prelevement_longitude && (
                      <MapMarker
                        key={index}
                        position={{
                          lat: parseFloat(info.center.attributes.field_prelevement_latitude.replaceAll(',', '.')),
                          lng: parseFloat(info.center.attributes.field_prelevement_longitude.replaceAll(',', '.')),
                        }}
                        opened={markersState[index]}
                        handleOpen={handleOpenMapMarker}
                        index={index}
                        icon={getMarkerIcon(info.center.attributes.field_type_de_laboratoire, favorites.includes(info.center.id))}
                      />
                    ),
                )}
              </>
            )}
          </MarkerClusterer>
          {myPosition && <Marker position={myPosition} animation={google.maps.Animation.DROP} icon={getPersonMarkerIcon()} />}
        </GoogleMap>
      )}
    </>
  );
};

export default MapCenters;
