export enum PatientType {
  Adult = 'Adult',
  Child = 'Child',
}

export interface Patient {
  key: number;
  type: PatientType;
  name?: string;
  isCurrentUser?: boolean;
  selected: boolean;
  id?: number;
}
