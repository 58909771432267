import { Stack, ToggleButton, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface AvailableSlotsProps {
   slots: string[];
   pickedTime: string;
   setPickedTime: (time: string) => void;
}

const AvailableSlots: FC<AvailableSlotsProps> = ({slots, pickedTime, setPickedTime }) => {
   const {t} = useTranslation();
   return (
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%', alignItems: 'start', gap: '10px', borderTop: '0.5px solid lightgrey', padding: '16px 0px 8px 0px' }}>
         {slots.map((slot, index) => (
            <ToggleButton
               key={index}
               value={slot}
               selected={pickedTime === slot}
               onChange={() => setPickedTime(slot)}
               sx={(theme) => ({ 
                  width: 'fit-content',
                  height: 'fit-content', 
                  padding: '8px 16px', 
                  borderRadius: '8px', 
                  border: '1px solid #7A9099', 
                  backgroundColor: 'white' ,
                  '&:hover': {
                     backgroundColor:  theme.palette.primary.light,
                  },
                  '&.Mui-selected': {
                     backgroundColor: theme.palette.primary.main,
                     '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                     },
                  }
               })}
            >
               {slot}
             </ToggleButton>
         ))}
         {slots.length < 1 && <Typography variant="body1">{t('checkin.stepper.steps.services.calendar.no_slots')}</Typography>}
      </Stack>
   )
};

export default AvailableSlots;