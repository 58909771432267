import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import config from 'config';

const position = { lat: 49.56516480567657, lng: 6.080922439223879 };

const LaboratoryMap = () => {
  const { isLoaded: isGoogleLoaded /* , loadError: googleLoadError */ } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey || '',
  });

  return (
    <>
      {isGoogleLoaded && (
        <GoogleMap
          center={position}
          zoom={16}
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
        >
          <Marker position={{ lat: 49.56516480567657, lng: 6.080922439223879 }} clickable={true} onClick={() => alert('marker was clicked!')} title={'clickable google.maps.Marker'} />
        </GoogleMap>
      )}
    </>
  );
};

export default LaboratoryMap;
