import { Nullable } from 'models/utils.model';
import { useEffect, useState } from 'react';

type Geolocation = Nullable<{ lat: number; long: number }>;

export const useGeolocation = () => {
  const [location, setLocation] = useState<Geolocation>(null);
  const [hasLocation, setHasLocation] = useState(false);

  const handleGeolocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setLocation({ lat: pos.coords.latitude, long: pos.coords.longitude });
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error(error);
              break;
            case error.POSITION_UNAVAILABLE:
              console.error('Les informations de localisation sont indisponibles.');
              break;
            case error.TIMEOUT:
              console.error('La demande de géolocalisation a expiré.');
              break;
            default:
              console.error('Une erreur inconnue est survenue.');
          }
        },
      );
    } else {
      console.error("La géolocalisation n'est pas supportée par ce navigateur.");
    }
  };

  const resetPosition = () => setLocation(null);

  useEffect(() => {
    if (location) {
      return setHasLocation(true);
    }
    setHasLocation(false);
  }, [location]);

  return { location, handleGeolocation, hasLocation, resetPosition };
};