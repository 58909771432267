import { Box, Button, Card, CardContent, Grid, Skeleton, SvgIcon, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import MissingData from 'components/missing-data';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { Patient } from 'models/patients.model';
import { Gender } from 'models/profile.model';
import { FC } from 'react';
import { ReactComponent as BirthdayIcon } from 'resources/icons/birthday.svg';
import { ReactComponent as CnsIcon } from 'resources/icons/cns.svg';
import { ReactComponent as AddressIcon } from 'resources/icons/home.svg';
import { ReactComponent as MaleIcon } from 'resources/icons/man.svg';
import { ReactComponent as MobileIcon } from 'resources/icons/smartphone.svg';
import { ReactComponent as PhoneIcon } from 'resources/icons/telephone.svg';
import { ReactComponent as FemaleIcon } from 'resources/icons/woman.svg';
import { convertDate } from 'utils/func/Date.func';
import { useAuthenticatedUser, useContextRedirection } from 'hooks';
import { PrescriptionRequestMaker } from 'utils/func/Prescription.func';

export interface PatientDetailsProps {
  patient?: Patient;
  showPatientRecordAction?: boolean;
  showNewPrescriptionAction?: boolean;
}

const PatientDetails: FC<PatientDetailsProps> = ({ patient, showPatientRecordAction: showPatientDetailAction = false, showNewPrescriptionAction = false }) => {
  const { t } = useTranslation();
  const authenticatedUser = useAuthenticatedUser();
  const navigate = useContextRedirection();

  const skeletonElement: JSX.Element = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: (theme) => theme.spacing(3),
      }}
    >
      <Skeleton variant='circular' width={20} height={20}></Skeleton>
      <Box sx={{ flex: '1 0 0' }}>
        <Skeleton variant='text' width='100%'></Skeleton>
      </Box>
    </Box>
  );
  const skeletonMultilineElement: JSX.Element = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: (theme) => theme.spacing(3),
      }}
    >
      <Skeleton variant='circular' width={20} height={20}></Skeleton>
      <Box sx={{ flex: '1 0 0' }}>
        <Skeleton variant='text' width='100%'></Skeleton>
        <Skeleton variant='text' width='100%'></Skeleton>
      </Box>
    </Box>
  );

  const boxStyle: SxProps<Theme> = (theme: Theme) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
  });

  const iconStyle = (dataToCheck?: string | null): SxProps<Theme> => {
    return {
      path: {
        fill: (theme) => (dataToCheck ? 'inherit' : theme.palette.text.disabled),
      },
    };
  };
  const dataStyle = (dataToCheck?: string | null): SxProps<Theme> => {
    return {
      color: (theme) => (dataToCheck ? 'inherit' : theme.palette.text.disabled),
    };
  };
  const displayData = (dataToCheck?: string | null): string | React.ReactNode => {
    // see MYLAB-411 consider empty string as missing
    return dataToCheck ? dataToCheck : <MissingData />;
  };

  return (
    <Card>
      <CardContent>
        <Grid container item xs={12}>
          {patient ? (
            <>
              <Grid container item spacing={{ xs: 2 }}>
                <Grid item xs={12} md={showPatientDetailAction || showNewPrescriptionAction ? 5 : 6}>
                  <Box sx={boxStyle}>
                    <SvgIcon fontSize='small' component={patient.gender === Gender.FEMALE ? FemaleIcon : MaleIcon} />
                    <Box width='100%'>
                      <Typography variant='h3'>{`${patient.last_name} ${patient.first_name}`}</Typography>
                      {patient.birth_name && (
                        <Typography variant='body1'>
                          {`(${t('patient_details.born', {
                            context: patient.gender === Gender.FEMALE ? Gender.FEMALE_FULL : Gender.MALE_FULL,
                          })} ${patient.birth_name})`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={showPatientDetailAction || showNewPrescriptionAction ? 2 : 3}>
                  <Box sx={boxStyle}>
                    <SvgIcon fontSize='small' component={BirthdayIcon} sx={iconStyle(patient.birth_date)} />
                    <Typography variant='body1' sx={dataStyle(patient.birth_date)}>
                      {patient.birth_date ? convertDate(patient.birth_date) : <MissingData />}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={showPatientDetailAction || showNewPrescriptionAction ? 2 : 3}>
                  <Box sx={boxStyle}>
                    <SvgIcon fontSize='small' component={CnsIcon} sx={iconStyle(patient.cns)} />
                    <Typography variant='body1' sx={dataStyle(patient.cns)}>
                      {displayData(patient.cns)}
                    </Typography>
                  </Box>
                </Grid>
                {showPatientDetailAction && (
                  <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={2} alignItems='center'>
                    <ContextLink to='DOCTOR_PATIENTS_DETAILS' params={patient.id.toString()} fullWidth>
                      <Button variant='outlined' fullWidth>
                        {t('result_doctor.details.buttons.see_patient_record')}
                      </Button>
                    </ContextLink>
                  </Grid>
                )}
              </Grid>
              <Grid container item spacing={{ xs: 2 }}>
                <Grid item xs={12} md={showPatientDetailAction || showNewPrescriptionAction ? 5 : 6}>
                  <Box sx={boxStyle}>
                    <SvgIcon fontSize='small' component={AddressIcon} sx={iconStyle(patient.addresses?.home?.city ? patient.addresses.home.city : null)} />
                    <Box width='100%'>
                      {patient.addresses?.home?.city ? (
                        <>
                          <Typography variant='body1'>{`${patient.addresses.home.number ?? ''} ${patient.addresses.home.street ?? ''}`}</Typography>
                          <Typography variant='body1'>{`${patient.addresses.home.postal_code ?? ''} ${patient.addresses.home.city}`}</Typography>
                          <Typography variant='body1'>{patient.addresses.home.country ?? ''}</Typography>
                        </>
                      ) : (
                        <Typography variant='body1' sx={dataStyle(patient.addresses?.home?.city)}>
                          <MissingData />
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={showPatientDetailAction || showNewPrescriptionAction ? 2 : 3}>
                  <Box sx={boxStyle}>
                    <SvgIcon fontSize='small' component={PhoneIcon} sx={iconStyle(patient.phone)} />
                    <Typography variant='body1' sx={dataStyle(patient.phone)}>
                      {displayData(patient.phone)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={showPatientDetailAction || showNewPrescriptionAction ? 2 : 3}>
                  <Box sx={boxStyle}>
                    <SvgIcon fontSize='small' component={MobileIcon} sx={iconStyle(patient.mobile_phone)} />
                    <Typography variant='body1' sx={dataStyle(patient.mobile_phone)}>
                      {displayData(patient.mobile_phone)}
                    </Typography>
                  </Box>
                </Grid>
                {showNewPrescriptionAction && (
                  <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={2} alignItems='center'>
                    <ContextLink to='DOCTOR_PATIENTS_DETAILS' params={patient.id.toString()} fullWidth>
                      <Button variant='outlined' fullWidth>
                        {t('result_doctor.details.buttons.see_patient_record')}
                      </Button>
                    </ContextLink>
                    <Button
                      variant='outlined'
                      fullWidth
                      onClick={() => {
                        authenticatedUser && patient.id && PrescriptionRequestMaker(patient.id, navigate);
                      }}
                    >
                      {t('common.action.makePrescriptionAgain')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid container item spacing={{ xs: 2 }}>
                <Grid item xs={12} md={5}>
                  {skeletonMultilineElement}
                </Grid>
                <Grid item xs={12} md={2}>
                  {skeletonElement}
                </Grid>
                <Grid item xs={12} md={2}>
                  {skeletonElement}
                </Grid>
              </Grid>
              <Grid container item spacing={{ xs: 2 }}>
                <Grid item xs={12} md={5}>
                  {skeletonMultilineElement}
                </Grid>
                <Grid item xs={12} md={2}>
                  {skeletonElement}
                </Grid>
                <Grid item xs={12} md={2}>
                  {skeletonElement}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default PatientDetails;
