import { Box, ListItemButton, ListItemText, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as RightArrowIcon } from 'resources/icons/right_arrow.svg';
import { ReactComponent as TimeUpdatesIcon } from 'resources/icons/time_updates.svg';
import { ReactComponent as AlertIcon } from 'resources/icons/alert.svg';
import { FC } from 'react';
import { redesignColors } from 'resources/theme/theme.colors';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  subtitle?: string;
  status: string;
  statusDescription?: string;
  timeLabel?: string;
  onClick?: () => void;
  showIcon?: boolean;
}

const CenterListItem: FC<Props> = ({ title, subtitle, status, timeLabel, showIcon, onClick, statusDescription }) => {
  const { t } = useTranslation();

  return (
    <ListItemButton
      sx={{
        borderBottom: `1px solid ${redesignColors.grey.grey3}`,
        pl: '2px',
        pb: 1,
        height: 'auto',
        '&:hover': {
          backgroundColor: redesignColors.blue.blue1,
        },
      }}
      {...(onClick !== undefined && {
        onClick: () => onClick(),
      })}
    >
      <Box>
        {timeLabel && (
          <Typography variant='subtitle2' color={redesignColors.grey.grey1} fontWeight={400}>
            {timeLabel}
          </Typography>
        )}
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            sx: {
              mb: 0.5,
              fontWeight: '500',
            },
          }}
          secondary={subtitle}
          secondaryTypographyProps={{
            sx: {
              fontSize: '14px',
            },
          }}
        />
        <Box display='flex' alignItems='center'>
          {showIcon && <SvgIcon component={status === 'open' ? TimeUpdatesIcon : AlertIcon} fill={redesignColors.grey.grey2} sx={{ mr: 1 }} />}
          <Box sx={{ fontSize: 14 }} display={{ lg: 'block', xl: 'flex' }}>
            <span style={{ color: status === 'open' ? 'green' : 'red', fontWeight: 500, marginRight: 4 }}>{t(`centers.details.schedule.${status}`)}</span>
            {statusDescription}
          </Box>
        </Box>
        <SvgIcon component={RightArrowIcon} fill={redesignColors.grey.grey2} sx={{ position: 'absolute', right: 0, top: 10, mr: 1, fontSize: '28px !important' }} />
      </Box>
    </ListItemButton>
  );
};

export default CenterListItem;
