import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 3,
      },
    },
  },
});

export default theme;
