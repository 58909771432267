import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Control, Controller, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { FilterResultsDate, FilterResultsFormValues } from 'models/result.model';
import CustomDatePicker from 'components/custom-date-calendar/custom-date-picker';

interface CentersFormProps {
  register: UseFormRegister<FilterResultsFormValues>;
  watch: UseFormWatch<FilterResultsFormValues>;
  control: Control<FilterResultsFormValues>;
  defaultValues: FilterResultsFormValues;
}

const HistoryResultsForm: FC<CentersFormProps> = ({ register, control, watch, defaultValues }) => {
  const { t } = useTranslation();

  const { date } = watch();
  return (
    <form>
      <Grid container>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('result_doctor.filter_drawer.date')}
            </Typography>
            <RadioGroup defaultValue={defaultValues.date} name='date'>
              <FormControlLabel {...register('date')} value={FilterResultsDate.all} control={<Radio />} label={t('result_doctor.filter_drawer.all_time')} />
              <FormControlLabel {...register('date')} value={FilterResultsDate.today} control={<Radio />} label={t('result_doctor.filter_drawer.today')} />
              <FormControlLabel {...register('date')} value={FilterResultsDate.lastMonth} control={<Radio />} label={t('result_doctor.filter_drawer.last_month')} />
              <FormControlLabel {...register('date')} value={FilterResultsDate.last12Month} control={<Radio />} label={t('result_doctor.filter_drawer.last_12_months')} />
              <FormControlLabel {...register('date')} value={FilterResultsDate.datesRange} control={<Radio />} label={t('result_doctor.filter_drawer.choose_dates')} />
            </RadioGroup>
          </Grid>
          {date === FilterResultsDate.datesRange && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Controller
                control={control}
                name='datesRangeFrom'
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomDatePicker
                    fullWidth
                    format='yyyy-MM-dd'
                    sx={{ width: '48%' }}
                    value={value}
                    defaultValue={defaultValues.datesRangeFrom}
                    label={t('result_doctor.filter_drawer.from_date')}
                    onChange={onChange}
                    slotProps={{
                      textField: {
                        inputProps: {
                          onBlur: onBlur,
                        },
                      },
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name='datesRangeTo'
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomDatePicker
                    fullWidth
                    format='yyyy-MM-dd'
                    sx={{ width: '48%' }}
                    value={value}
                    defaultValue={defaultValues.datesRangeFrom}
                    label={t('result_doctor.filter_drawer.to_date')}
                    onChange={onChange}
                    slotProps={{
                      textField: {
                        inputProps: {
                          onBlur: onBlur,
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default HistoryResultsForm;
