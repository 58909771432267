import { Grid } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ContactCard from './components/ContactCard';
import HeadquarterCard from './components/HeadquarterCard';

const ContactPage: FC = () => {
  const { t } = useTranslation();

  return (
    <BodyTemplate title={t('bodyTemplate.contact')}>
      <Grid container direction='row' spacing={0} p={0}>
        <Grid item xs={12}>
          <ContactCard />
        </Grid>
        <Grid item xs={12} mt={3}>
          <HeadquarterCard />
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default ContactPage;
