import { Box, Button, Card, CardActions, CardContent, Divider, List, SvgIcon, Typography } from '@mui/material';
import api from 'api';
import ContextLink from 'components/_base/ContextLink';
import { useIsMobileView, useNotification } from 'hooks';
import { Record } from 'models/record.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { convertDate } from 'utils/func/Date.func';
import { ReactComponent as ResultsIcon } from 'resources/icons/results.svg';
import { ReactComponent as RightArrowIcon } from 'resources/icons/right_arrow.svg';
import HomepageListingItems from './HomepageListingItems';
import { redesignColors } from 'resources/theme/theme.colors';
import { resultStatusColors } from 'pages/patient/results/utils/colors';

const HomepageResults: FC = () => {
  const isMobile = useIsMobileView();
  const { t } = useTranslation();
  const [data, setData] = useState<Record[]>([]);
  const { notification } = useNotification();
  const [error, setError] = useState(false);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);

  useEffect(() => {
    currentUser &&
      api.results
        .getResults(3, 0, currentUser.id)
        .then((res) => setData(res.results))
        .catch(() => {
          setError(true);
          notification(t('homepage.results.notification.error'), 'error');
        });
  }, [currentUser, notification, t]);

  return (
    <Card
      sx={{
        display: 'grid',
        width: '100%',
        minHeight: (theme) => theme.spacing(25),
      }}
    >
      <CardContent>
        <Typography variant='h2' display='flex' alignItems='center' fontWeight='500'>
          <SvgIcon component={ResultsIcon} sx={{ mr: 1 }} />
          {t('common.results')}
        </Typography>
        {error ? (
          <Typography>{t('homepage.results.notification.error')}</Typography>
        ) : (
          <List dense>
            {data.map((result: Record, i: number) => {
              const consultationTime = result.sampling_time ? convertDate(result.sampling_time, true) : `${t('homepage.results.dateNoValid')}`;
              const issuer = [result.issuer.title, result.issuer.first_name, result.issuer.last_name].join(' ');
              const status = `homepage.results.status.${result.status}`;
              return (
                <HomepageListingItems
                  title={
                    <ContextLink to='DETAIL_RESULT' params={{ id: result.reference }}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          cursor: 'pointer',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography sx={{ fontSize: '14px', alignSelf: 'center', py: 1, width: '25%' }}>{consultationTime}</Typography>
                        <Typography sx={{ fontSize: '14px', placeSelf: 'center', py: 1, width: '25%', textAlign: 'center' }}>{issuer}</Typography>
                        <Typography sx={{ fontSize: '14px', placeSelf: 'center', py: 1, width: '25%', textAlign: 'center' }}>{result.reference}</Typography>
                        <Typography sx={{ fontSize: '14px', placeSelf: 'center', py: 1, width: '25%', textAlign: 'center', fontWeight: '600', color: resultStatusColors[result.status] }}>
                          {t(status)}
                        </Typography>
                      </Box>
                    </ContextLink>
                  }
                  key={`result-${i}`}
                  showIcon={false}
                />
              );
            })}
          </List>
        )}
      </CardContent>
      <CardActions sx={{ alignItems: 'flex-end' }}>
        <Box width='100%' display='flex' justifyContent='flex-end'>
          <ContextLink to='RESULTS'>
            <Button variant='text' endIcon={<SvgIcon component={RightArrowIcon} fill={redesignColors.blue.dark} sx={{ mr: 1, fontSize: '28px !important' }} />}>
              {t('homepage.results.buttonLabel')}
            </Button>
          </ContextLink>
        </Box>
      </CardActions>
    </Card>
  );
};

export default HomepageResults;
