import theme_color from 'resources/theme/theme.colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'Verdana'].join(','),
  },
});

theme.typography.h1 = {
  fontSize: 30,
  fontWeight: 700,
  lineSpacing: 39,
  textTransform: 'none',
  color: theme_color.palette.text.primary,

  [theme.breakpoints.down('md')]: {
    fontSize: 20,
  },
};

theme.typography.h2 = {
  fontSize: 20,
  fontWeight: 700,
  lineSpacing: 27,
  textTransform: 'none',
  color: theme_color.palette.text.primary,

  [theme.breakpoints.down('md')]: {
    fontSize: 18,
  },
};

theme.typography.h3 = {
  fontSize: 18,
  fontWeight: 700,
  lineSpacing: 19,
  textTransform: 'none',
  color: theme_color.palette.text.primary,

  [theme.breakpoints.down('md')]: {
    fontSize: 16,
  },
};

theme.typography.subtitle1 = {
  fontSize: 16,
  fontWeight: 600,
  lineSpacing: 19,
  textTransform: 'none',
  color: theme_color.palette.text.primary,

  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  },
};

theme.typography.subtitle2 = {
  fontSize: 12,
  fontWeight: 600,
  textTransform: 'none',
  color: theme_color.palette.text.primary,

  [theme.breakpoints.down('md')]: {
    fontSize: 10,
  },
};

theme.typography.body1 = {
  fontSize: 16,
  fontWeight: 400,
  lineSpacing: 19,
  textTransform: 'none',
  color: theme_color.palette.text.primary,

  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  },
};

theme.typography.body2 = {
  fontSize: 12,
  fontWeight: 400,
  textTransform: 'none',
  color: theme_color.palette.text.primary,

  [theme.breakpoints.down('md')]: {
    fontSize: 10,
  },
};

theme.typography.button = {
  fontSize: 16,
  fontWeight: 500,
  lineSpacing: 16,
  textTransform: 'none',
  color: theme_color.palette.text.primary,

  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  },
};

export default theme;
