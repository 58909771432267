import { CurrencyFormat } from 'utils/func/Number.func';
import { Patient } from './patients.model';

export enum InvoiceStatus {
  OPEN = 'open',
  PAID = 'paid',
}

export type InvoicesResult = {
  amount: number;
  client_secret: string;
  currency: CurrencyFormat;
  date: string;
  dossier_reference: string;
  file: string;
  id: number;
  patient: Patient;
  reference: string;
  status: InvoiceStatus;
  transaction_date: string | null;
};

export type InvoiceDisconnectedRequest = {
  reference: string;
  amount: number;
  email?: string;
};
