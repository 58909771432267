import { Box, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import { useIsMobileView, useNotification } from 'hooks';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import DesktopResults from './components/DesktopResults';
import MobileResults from './components/MobileResults';
import { useTranslation } from 'react-i18next';
import { FilterResultsFormValues, ResultIssuer, ResultStatus } from 'models/result.model';
import ResultStatusTabs from './components/ResultStatusTabs';
import ResultsFilterDrawer from './components/ResultsFilterDrawer';
import { defaultValues, filterByDate } from './utils/filters';
import ConfirmDialog from 'components/confirm-dialog';
import ResultsFilterBar from './components/ResultsFilterBar';
import useHideRedesign from 'hooks/useHideRedesign';

const Results: FC = () => {
  const isMobileView = useIsMobileView();
  const { hideRedesign, toggleRedesign: toggleHideRedesign } = useHideRedesign();
  const [results, setResults] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(true);
  const [sortFromNewToOld, setSortFromNewToOld] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<ResultStatus>(ResultStatus.partial);
  const [filterData, setFilterData] = useState<FilterResultsFormValues | null>(null);
  const paginatorData: GenericPaginator = {
    total: 0,
    size: DEFAULT_PAGINATION_SIZE,
    page: 1,
  };
  const [paginator, setPaginator] = useState(paginatorData);
  const { t } = useTranslation();
  const { notification } = useNotification();
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const [isLoading, setLoading] = useState(true);
  const getData = useCallback(async () => {
    const { date, datesRangeFrom, datesRangeTo, doctors: doctorIds } = filterData || defaultValues;
    setLoading(true);
    currentUser &&
      api.results
        .getResults(paginator.size, paginator.page, currentUser.id)
        .then((res) => {
          const resultsByTab = hideRedesign
            ? res.results
            : res.results.filter((result) => {
                const isFilteredByDoctor = result.issuer.id && doctorIds.includes(result.issuer.id);
                const isFilteredByDate = filterByDate(result as any, date, datesRangeFrom, datesRangeTo);
                const filtersCondition = isFilteredByDate || isFilteredByDoctor;
                return result.status === selectedTab && filtersCondition;
              });
          const updatedResults = sortFromNewToOld ? resultsByTab : resultsByTab.reverse();
          setResults(isMobileView ? res.results : updatedResults);
          setPaginator({
            total: res.total,
            size: res.size,
            page: res.page,
          });
          setLoading(false);
        })
        .catch(() => notification(t('homepage.results.notification.error'), 'error'));
  }, [notification, paginator.size, paginator.page, t, currentUser, filterData, defaultValues, selectedTab, sortFromNewToOld, isMobileView, hideRedesign]);

  useEffect(() => {
    getData();
  }, [getData]);
  const doctors = useMemo(() => {
    const allDoctors = results.map((res) => res.issuer) as ResultIssuer[];
    const uniqDoctorsIds = [...new Set(allDoctors.map((doctor) => doctor.id))];
    const uniqDoctors = uniqDoctorsIds.reduce((acc, id) => {
      const doctor = allDoctors.find((doctor) => doctor.id === id);
      if (doctor) {
        return [...acc, doctor];
      }
      return acc;
    }, [] as ResultIssuer[]);
    return uniqDoctors;
  }, [results]);

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginator(pagination);
  };

  const handleChangeTab = (tab: ResultStatus) => {
    setSelectedTab(tab);
    setFilterData(null);
  };

  return (
    <BodyTemplate title={t('common.results')}>
      <Box display='flex' alignItems='center' justifyContent='flex-end'>
        <FormControlLabel
          control={<Switch sx={{ m: 1 }} />}
          checked={hideRedesign === null ? true : !hideRedesign}
          sx={{ flexDirection: 'row-reverse', mb: 2 }}
          onChange={(e, checked) => toggleHideRedesign(!checked)}
          label={
            <Typography variant='body1' fontWeight='500' mr={1}>
              {t('result_doctor.enable_reports')}
            </Typography>
          }
        />
      </Box>
      <Grid container justifyContent='center'>
        {!isMobileView && !hideRedesign && <ResultStatusTabs selectedTab={selectedTab} setSelectedTab={handleChangeTab} results={results} />}
        {selectedTab === ResultStatus.complete && !isMobileView && !hideRedesign && (
          <ResultsFilterBar
            setOpen={setOpen}
            filterData={filterData}
            sortFromNewToOld={sortFromNewToOld}
            setSortFromNewToOld={() => setSortFromNewToOld((prev) => !prev)}
            setFilterData={(data) => setFilterData(data)}
            doctors={doctors}
          />
        )}
        <Grid item xs={12}>
          {!isMobileView ? (
            <DesktopResults
              hideRedesign={!!hideRedesign}
              results={results}
              title={t('result_doctor.table_title')}
              selectedStatus={selectedTab}
              pagination={paginator}
              isLoading={isLoading}
              onPaginationChange={handlePaginationChange}
            />
          ) : (
            <MobileResults results={results} isLoading={isLoading} />
          )}
        </Grid>
      </Grid>
      {open && (
        <ResultsFilterDrawer
          doctors={doctors}
          results={results}
          open={open}
          onClose={() => setOpen(false)}
          onApply={(data) => {
            setFilterData(data);
            setOpen(false);
          }}
          defaultFilters={filterData || defaultValues}
          onClear={() => {
            setFilterData(null);
            setOpen(false);
          }}
        />
      )}
      {hideRedesign === null && (
        <ConfirmDialog
          hideCancel
          title={t('result_doctor.new_screen_dialog.title')}
          subtitle={t('result_doctor.new_screen_dialog.description')}
          submitTitle={t('common.action.understood')}
          open={openInfoModal}
          onClose={() => {
            setOpenInfoModal(false);
            toggleHideRedesign(false);
          }}
          onSubmit={() => toggleHideRedesign(false)}
        />
      )}
    </BodyTemplate>
  );
};

export default Results;
