import { LoadingButton } from '@mui/lab';
import { Button, Card, CardContent, CircularProgress, Grid, Stack, Theme, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useAuthenticated, useContextRedirection, useDisclaimerNeeded, useNotification } from 'hooks';
import { Disclaimer } from 'models/disclaimer.model';
import { logoutUser, validateDisclaimer } from 'store/actions/session.actions';
import { useDispatch } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RoutePaths from 'utils/RoutePaths';
import { redesignColors } from 'resources/theme/theme.colors';

const DisclaimerPage: FC = () => {
  const { t } = useTranslation();
  const isAuthenticated = useAuthenticated();
  const isDisclaimerNeeded = useDisclaimerNeeded();
  const { notification } = useNotification();
  const navigate = useContextRedirection();
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const [disclaimer, setDisclaimer] = useState<Disclaimer>();

  useEffect(() => {
    setLoading(true);
    if (isAuthenticated) {
      api.disclaimer
        .getDisclaimer()
        .then((response) => setDisclaimer(response.data))
        .catch(() => notification(t('bodyTemplate.notification.error.no_disclaimer'), 'error'))
        .finally(() => setLoading(false));
    } else {
      api.disclaimer
        .getPublicDisclaimer()
        .then((response) => setDisclaimer(response.data))
        .catch(() => notification(t('bodyTemplate.notification.error.no_disclaimer'), 'error'))
        .finally(() => setLoading(false));
    }
  }, [notification, t, isAuthenticated]);

  const acceptDisclaimer = () => {
    if (isAuthenticated && disclaimer) {
      setLoading(true);
      api.disclaimer.acceptDisclaimer(disclaimer.id).finally(() => {
        setLoading(false);
        dispatch(validateDisclaimer());
        navigate(RoutePaths['HOME']);
      });
    }
  };

  const linkStyle = {
    a: {
      color: (theme: Theme) => theme.palette.common.black,
      fontWeight: 500,
      textDecoration: 'none',
      '&[href^="https"], &[href^="mailto"]': {
        color: redesignColors.blue.dark,
        borderBottom: `1px solid ${redesignColors.blue.dark}`,
        transition: (theme: Theme) => `border-bottom ${theme.transitions.duration.enteringScreen}ms  ${theme.transitions.easing.easeOut} 0ms`,
        ':hover': {
          borderColor: (theme: Theme) => theme.palette.common.black,
        },
      },
    },
    'li>a:hover': {
      borderBottom: (theme: Theme) => `1px solid ${theme.palette.common.black}`,
    },
    h2: {
      fontSize: '20px',
    },
  };

  return (
    <BodyTemplate title={disclaimer?.name ?? ''} backButton={!isAuthenticated}>
      <Grid container direction='row' spacing={0} p={0}>
        <Grid item sm={12}>
          <Card
            sx={{
              width: 'auto',
            }}
          >
            <CardContent>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography
                    sx={linkStyle}
                    dangerouslySetInnerHTML={{
                      __html: disclaimer?.description ?? '',
                    }}
                  ></Typography>
                  {isAuthenticated && isDisclaimerNeeded && (
                    <Stack direction='row' spacing={2} sx={{ pt: 2 }}>
                      <Button
                        variant='outlined'
                        onClick={() => {
                          dispatch(logoutUser());
                          navigate(RoutePaths['LANDING_PAGE']);
                        }}
                      >
                        {t('bodyTemplate.disclaimer.cancel')}
                      </Button>
                      <LoadingButton variant='contained' onClick={acceptDisclaimer}>
                        {t('bodyTemplate.disclaimer.accept')}
                      </LoadingButton>
                    </Stack>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default DisclaimerPage;
