import { Box, Collapse, IconButton, SvgIcon, TableCell, TableRow, Typography } from '@mui/material';
import { Directory } from 'models/directory.model';
import { FC } from 'react';
import { ReactComponent as DoctorIcon } from 'resources/icons/doctena.svg';
import { ReactComponent as  ArrowUp } from 'resources/icons/arrow-up.svg';
import { ReactComponent as  ArrowDown } from 'resources/icons/arrow-down.svg';
import FindDoctorCollapseResult from './FindDoctorCollapseResult';

type Props = {
  directory: Directory;
  openStates: boolean[];
  handleChangeOpen: React.Dispatch<React.SetStateAction<boolean[]>>;
  keyOpen: number;
};

const FindDoctorResults: FC<Props> = ({ directory, openStates, handleChangeOpen, keyOpen }) => {
  const toggleOpenCollapse = () => {
    handleChangeOpen(
      openStates.map((openState: boolean, i: number) => {
        return i === keyOpen ? !openState : false;
      }),
    );
  };

  const hasDoctenaAgenda = (): boolean => {
    return !!directory.doctena_agenda_id;
  };

  return (
    <>
      <TableRow
        hover
        onClick={() => toggleOpenCollapse()}
        sx={{
          cursor: 'pointer',
          zIndex: 2,
          position: 'relative',
        }}
      >
        <TableCell sx={{p: '16px 32px'}}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{`${directory.identity} - ${directory.address.city}` || ''}</Typography>
              {hasDoctenaAgenda() && <SvgIcon fontSize='small' sx={{ ml: 1 }} component={DoctorIcon} />}
            </Box>
            <IconButton size='small'>{openStates[keyOpen] ? <SvgIcon component={ArrowUp}/> : <SvgIcon component={ArrowDown}/>}</IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          '&:not(:first-of-type)': { borderTop: 'none' },
        }}
      >
        <TableCell sx={{ padding: 0 }}>
          <Collapse in={openStates[keyOpen]} timeout='auto' unmountOnExit>
            <FindDoctorCollapseResult directory={directory} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default FindDoctorResults;
