import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, MenuItem, SvgIcon, TextField, Typography } from '@mui/material';
import api from 'api';
import { useIsMobileView, useNotification } from 'hooks';
import { ContactRequest } from 'models/contact-request.model';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconActive } from 'resources/icons/active.svg';
import { redesignColors } from 'resources/theme/theme.colors';

type Props = {
  open: boolean;
  setOpen: (bool: boolean) => any;
};

const ConfirmDialog: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const { notification } = useNotification();

  const [submitting, setSubmitting] = useState(false);
  const { register, handleSubmit, formState, reset } = useForm<ContactRequest>({
    mode: 'onChange',
  });

  const handleClose = useCallback(() => {
    setOpen(false);
    reset({ subject: '', message: '' });
  }, [reset, setOpen]);

  const onSubmit: SubmitHandler<ContactRequest> = useCallback(
    (formData) => {
      setSubmitting(true);
      const requestData: ContactRequest = {
        subject: formData.subject,
        message: formData.message,
      };
      api.contact
        .postContact(requestData)
        .then(() => {
          handleClose();
          notification(t('contact.notifications.success'), 'success');
        })
        .catch((error) => {
          notification(t('contact.notifications.error'), 'error');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [handleClose, notification, t],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { xs: '300px', lg: '440px' },
          maxWidth: 'fit-content',
          borderRadius: '28px',
          p: 3,
        },
      }}
    >
      <IconActive style={{ marginBottom: 2 }} />
      <DialogTitle sx={{ position: 'relative', px: 1 }}>
        <Box component='h2' sx={{ fontSize: '24px', fontWeight: '500', mb: 2 }}>
          {t('feedback.confirm_dialog.title')}
        </Box>
        <Box sx={{ fontSize: '16px', fontWeight: '400', color: redesignColors.grey.grey1, mb: 3 }}>{t('feedback.confirm_dialog.description')}</Box>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'grid', gridTemplateRows: 'auto', gap: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton variant='text' fullWidth={isMobileView} loading={submitting} sx={{ mr: 2 }}>
                {t('feedback.confirm_dialog.close')}
              </LoadingButton>
              <LoadingButton type='submit' variant='text' fullWidth={isMobileView} loading={submitting}>
                {t('feedback.confirm_dialog.app_store_submit')}
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default ConfirmDialog;
