import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { resultTaggedEl } from 'models/result.model';
import { FC } from 'react';
import GenericTable, { GenericPaginator } from '../generic-table';
import { GenericHeader } from '../generic-table/GenericTableHeader';
import NoResultsTableRow from '../no-results-table-row';
import TableContentSkeleton from '../table-content-skeleton';
import ResultsTableContent from './ResultsTableContent';

interface ResultsTableProps {
  data: any[];
  title: string | JSX.Element;
  pagination: GenericPaginator;
  onPaginationChange: (pagination: GenericPaginator) => void;
  isLoading: boolean;
}

const ResultsTable: FC<ResultsTableProps> = ({ data, title, pagination, onPaginationChange, isLoading }) => {
  const tableHeaders: GenericHeader[] = [
    { id: 'tags', label: undefined },
    {
      id: 'prescriptionDate',
      label: 'common.table.tableHead.prescription_date',
    },
    {
      id: 'sampleDate',
      label: 'common.table.tableHead.sample_date',
    },
    {
      id: 'referenceNumber',
      label: 'common.table.tableHead.reference_number',
    },
    {
      id: 'patient',
      label: 'common.table.tableHead.patient',
    },
    {
      id: 'dateOfBirth',
      label: 'common.table.tableHead.birth_date',
    },
    { id: 'cns', label: 'common.table.tableHead.cns_number' },
    { id: 'issuer', label: 'common.table.tableHead.issuer' },
    { id: 'status', label: 'common.table.tableHead.status' },
    { id: 'actions', label: undefined },
  ];

  return (
    <Card sx={{ p: 0 }}>
      <GenericTable
        headers={tableHeaders}
        {...(data.length
          ? {
              pagination: pagination,
              setPagination: onPaginationChange,
            }
          : {
              noPagination: true,
            })}
      >
        {!isLoading && data ? (
          data.length ? (
            data.map((elem: resultTaggedEl, key: number) => {
              return <ResultsTableContent key={key} elem={elem} />;
            })
          ) : (
            <NoResultsTableRow colSpan={tableHeaders.length} />
          )
        ) : (
          <TableContentSkeleton columns={tableHeaders.length} rows={4} />
        )}
      </GenericTable>
    </Card>
  );
};

export default ResultsTable;
