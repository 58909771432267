import { Container, Grid } from '@mui/material';
import ContextRedirect from 'components/_base/ContextRedirect';
import { useAuthenticated } from 'hooks';
import { FC } from 'react';
import LeftCard from './components/LeftCard';
import RightCard from './components/RightCard';
import BottomCard from './components/BottomCard';
import RoutePaths from 'utils/RoutePaths';

const IndexLogin: FC = () => {
  const isAuthenticated = useAuthenticated();

  return !isAuthenticated ? (
    <>
      <Container maxWidth={false} disableGutters sx={{ pt: 2 }}>
        <Grid
          container
          spacing={1}
          justifyContent='center'
          sx={{
            alignItems: 'stretch',
          }}
        >
          <Grid item xs={12} lg={4}>
            <LeftCard />
          </Grid>
          <Grid item xs={12} lg={4}>
            <RightCard />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false} disableGutters>
        <Grid
          container
          spacing={1}
          display='flex'
          justifyContent='center'
          sx={{
            marginTop: '40px',
          }}
        >
          <Grid justifyContent='center' item xs={12} md={8.5}>
            <BottomCard />
          </Grid>
        </Grid>
      </Container>
    </>
  ) : (
    <ContextRedirect to={RoutePaths['HOME']} />
  );
};

export default IndexLogin;
