import theme_color, { redesignColors } from 'resources/theme/theme.colors';

import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: '500',
          color: redesignColors.dark,
          '&.Mui-selected': {
            color: redesignColors.blue.dark,
          },
        },
      },
    },
  },
});

export default theme;
