import { redesignColors } from 'resources/theme/theme.colors';

export const resultTagColors = [
  '#F2FAFD',
  '#FECDD2',
  '#F8BBD0',
  '#E1BEE7',
  '#D1C4E9',
  '#C5CAE9',

  '#BBDEFB',
  '#B2EBF2',
  '#C8E6C9',
  '#FFF9C4',
  '#FFE0B2',
  '#FFCCBC',

  '#7A9099',
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',

  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#FFEB3B',
  '#FF9800',
  '#FF5722',
];

export const resultStatusColors = {
  partial: redesignColors.orange,
  complete: redesignColors.green.dark,
};
