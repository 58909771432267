import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { ResultStatus } from 'models/result.model';
import { redesignColors } from 'resources/theme/theme.colors';

const ResultStatusTabs: FC<{
  results: any[];
  selectedTab: ResultStatus;
  setSelectedTab: (status: ResultStatus) => void;
}> = ({ results, selectedTab, setSelectedTab }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Tabs
        value={selectedTab}
        onChange={(e, value) => setSelectedTab(value)}
        variant='scrollable'
        sx={{ borderBottom: `1px solid ${redesignColors.grey.grey4}` }}
        TabIndicatorProps={{
          sx: { background: 'transparent', display: 'flex', justifyContent: 'center', height: '3px' },
          children: <div className='MuiTabs-indicatorSpan' style={{ width: '65%', background: redesignColors.blue.main, borderRadius: '100px 100px 0 0' }} />,
        }}
      >
        {[ResultStatus.partial, ResultStatus.complete].map((status) => (
          <Tab
            label={
              <Box>
                {status === ResultStatus.complete ? t(`result_doctor.completed`) : t(`result_doctor.processing`)}
                {status === selectedTab && <ResultsCounterDot resultsCount={results.length} />}
              </Box>
            }
            value={status}
          />
        ))}
      </Tabs>
    </Grid>
  );
};

const ResultsCounterDot = ({ resultsCount }: { resultsCount: number }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        right: '5px',
        top: '5px',
        background: redesignColors.red,
        color: '#FFFFFF',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '8px',
        width: '14px',
        height: '14px',
      }}
    >
      {resultsCount}
    </Box>
  );
};
export default ResultStatusTabs;
