import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';
import { Button, IconButton, SvgIcon, Typography } from '@mui/material';
import theme, { redesignColors } from 'resources/theme/theme.colors';
import { useTranslation } from 'react-i18next';
import { CentersFormValues, CityChoice, ScheduleChoice, SpecialityChoice } from 'models/centers.model';
import { useForm } from 'react-hook-form';
import CentersForm from './CentersForm';

type FilterDrawerProps = {
  open: boolean;
  locationEnabled?: boolean;
  onApply: (values: CentersFormValues) => void;
  onClear: () => void;
  onClose: () => void;
  cities: CityChoice[];
  specialities: SpecialityChoice[];
  schedules: ScheduleChoice[];
  defaultFilters: CentersFormValues;
};

export default function FilterDrawer({ open, onClose, cities, specialities, schedules, onApply, onClear, defaultFilters, locationEnabled }: FilterDrawerProps) {
  const { t } = useTranslation();

  const { register, watch, getValues } = useForm<CentersFormValues>({
    defaultValues: defaultFilters,
  });

  return (
    <Drawer anchor='right' open={open} onClose={onClose} sx={{ zIndex: 1300 }} PaperProps={{ sx: { width: 464 } }}>
      <Box
        display='flex'
        alignItems='center'
        p={2}
        sx={{ width: '100%', height: '56px', borderBottom: `1px solid ${redesignColors.grey.grey4}`, position: 'absolute', top: 0, left: 0, zIndex: 10 }}
        bgcolor={theme.palette.common.white}
      >
        <IconButton onClick={onClose}>
          <SvgIcon sx={{ fontSize: '16px' }} color='secondary' component={IconClose} />
        </IconButton>
        <Typography pl={2} variant='h2'>
          {t('centers.drawer.title')}
        </Typography>
      </Box>
      <Box pt={9} px={3} pb={11} sx={{ overflowX: 'auto', position: 'relative' }}>
        <CentersForm cities={cities} specialities={specialities} schedules={schedules} register={register} watch={watch} defaultValues={defaultFilters} locationEnabled={locationEnabled} />
      </Box>
      <Box
        display='flex'
        sx={{ borderTop: `1px solid ${redesignColors.grey.grey4}`, position: 'absolute', bottom: 0, left: 0, width: '100%', zIndex: 10 }}
        bgcolor={theme.palette.common.white}
        p={2}
        alignItems='center'
        justifyContent='space-between'
      >
        <Button
          variant='outlined'
          sx={{ width: '212px', height: '44px' }}
          onClick={() => {
            onClear();
            onClose();
          }}
        >
          {t('centers.drawer.clear')}
        </Button>
        <Button
          variant='contained'
          sx={{ width: '212px', height: '44px' }}
          onClick={() => {
            onApply(getValues());
            onClose();
          }}
        >
          {t('centers.drawer.apply')}
        </Button>
      </Box>
    </Drawer>
  );
}
