import { Button, CardContent, Divider, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import { ReactComponent as BionextFavicon } from "resources/icons/bionext-pickendoheen.svg";
import AppointmentCalendar from "./components/calendar/AppointmentCalendar";
import { useTranslation } from "react-i18next";

const PickenDoheem: FC = () => {
   const {t} = useTranslation();
   const [pickedMonth, setPickedMonth] = useState(1);
   const [pickedYear, setPickedYear] = useState(2025);
   const [pickedDay, setPickedDay] = useState(0);
   const [pickedTime, setPickedTime] = useState('');

   const availableSlots = []; //change this to the actual API call every time the month and year changes

   for (let i = 1; i <= 31; i++) {
      availableSlots.push({
         date: `2025-1-${i}`,
         slots: ['8:00', '12:00', '13:00']
      });
   }

   const handleBooking = () => {}; //HANDLE API CALL

   return (
      <CardContent sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', gap: '10px'}}>
         <Stack sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', width: '100%', gap: '10px'}}>
            <BionextFavicon />
            <Typography sx={{ mt: 0, fontWeight: '600' }} component="span" variant="body1">
              PICKEN-DOHEEM 
            </Typography>          
         </Stack>

         <Divider />

         {/* 
            We should connect API to availableSlots
            Right now it is a mock data
         */}
         <AppointmentCalendar 
            pickedMonth={pickedMonth} 
            pickedYear={pickedYear} 
            pickedDay={pickedDay}
            pickedTime={pickedTime} 
            setPickedMonth={setPickedMonth} 
            setPickedYear={setPickedYear} 
            setPickedDay={setPickedDay} 
            setPickedTime={setPickedTime} 
            availableSlots={availableSlots}
         />

         <Divider />

         <Button onClick={handleBooking} sx={{maxWidth: '244px'}} variant="contained" disabled={pickedTime === ''}>
            {t('checkin.stepper.steps.services.button.book_appointment')}
         </Button>
      </CardContent>
   )
};

export default PickenDoheem;