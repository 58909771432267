import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import React, { FC } from 'react';
import { ReactComponent as RightArowIcon } from 'resources/icons/right_arrow.svg';
import { ResultGraph } from 'models/result.model';
import GraphHistory from '../graph';
import { useTranslation } from 'react-i18next';
import { convertDate } from 'utils/func/Date.func';
import { redesignColors } from 'resources/theme/theme.colors';
import { formatToLocaleNumber } from 'utils/func/Number.func';
import ContextLink from 'components/_base/ContextLink';
import { useLocation, useParams } from 'react-router-dom';
import { CustomizedState } from '../../result-history';

export interface PatientDetailsProps {
  graph: ResultGraph;
  smallView?: boolean;
  children?: React.ReactNode;
}

const GraphCardContent: FC<PatientDetailsProps> = ({ graph, smallView, children }) => {
  const { t } = useTranslation();
  const { idResult } = useParams();

  const location = useLocation();
  const { patient, selection } = location.state as CustomizedState;

  const results = smallView ? graph.history.slice(0, 3) : graph.history;

  const renderResultsList = () => {
    if (smallView) {
      return results.map((result) => <ResultRow left={convertDate(result.sampling_time, false, 'yyyy-MM-dd')} right={`${formatToLocaleNumber(result.value)} ${result.unit}`} />);
    }
    const groupedByYear = Object.groupBy(results, (result) => new Date(result.sampling_time).getFullYear());
    return Object.entries(groupedByYear)
      .reverse()
      .map(
        ([year, results]) =>
          !!results?.length &&
          results.map((result, index) =>
            index === 0 ? (
              <>
                <ResultRow left={year} />
                <ResultRow left={convertDate(result.sampling_time, false, 'yyyy-MM-dd')} right={`${formatToLocaleNumber(result.value)} ${result.unit}`} />
              </>
            ) : (
              <ResultRow left={convertDate(result.sampling_time, false, 'yyyy-MM-dd')} right={`${formatToLocaleNumber(result.value)} ${result.unit}`} />
            ),
          ),
      );
  };

  return (
    <CardContent>
      <GraphHistory historyDataValues={[graph]} />

      <Box>{renderResultsList()}</Box>
      {idResult && smallView && (
        <Box mt={3} display='flex' justifyContent='center'>
          <ContextLink
            to='DETAIL_RESULT_HISTORY'
            params={{
              dossierId: idResult,
              patientId: patient.id,
            }}
            state={{
              selection,
              patient,
            }}
          >
            <Button variant='link' endIcon={<RightArowIcon />} sx={{ color: redesignColors.blue.dark }}>
              {t('result.doctor.info.view_all')}
            </Button>
          </ContextLink>
        </Box>
      )}
    </CardContent>
  );
};

const ResultRow = ({ left, right }: { left: string; right?: string }) => (
  <Box pt={1.5} display='flex' justifyContent='space-between' sx={{ borderBottom: `1px solid ${redesignColors.grey.grey4}` }}>
    <Typography variant='body1' sx={left && !right ? { color: redesignColors.grey.grey2, fonSize: '14px', mb: 0.5 } : { mb: 1.5 }}>
      {left}
    </Typography>
    {right && (
      <Typography variant='body1' mb={2}>
        {right}
      </Typography>
    )}
  </Box>
);

export default GraphCardContent;
