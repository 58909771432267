import { Card, CardContent, Grid, Theme, Typography } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useAuthenticated } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';

const Gdprpage: FC = () => {
  const { t } = useTranslation();
  const isAuthenticate = useAuthenticated();

  const linkStyle = {
    a: {
      color: (theme: Theme) => theme.palette.common.black,
      fontWeight: 500,
      textDecoration: 'none',
      '&[href^="https"], &[href^="mailto"]': {
        color: redesignColors.blue.dark,
        borderBottom: `1px solid ${redesignColors.blue.dark}`,
        transition: (theme: Theme) => `border-bottom ${theme.transitions.duration.enteringScreen}ms  ${theme.transitions.easing.easeOut} 0ms`,
        ':hover': {
          borderColor: (theme: Theme) => theme.palette.common.black,
        },
      },
    },
    'li>a:hover': {
      borderBottom: (theme: Theme) => `1px solid ${theme.palette.common.black}`,
    },
    h2: {
      fontSize: '20px',
      '& > a': {
        fontWeight: 600,
        color: (theme: Theme) => theme.palette.common.black,
      },
    },
  };

  return (
    <BodyTemplate title={t(`bodyTemplate.gdpr`)} backButton={!isAuthenticate}>
      <Grid container direction='row' spacing={0} p={0}>
        <Grid item sm={12}>
          <Card
            sx={{
              width: 'auto',
            }}
          >
            <CardContent>
              <Typography sx={linkStyle} dangerouslySetInnerHTML={{ __html: t('gdpr.html_text') }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Gdprpage;
