import { TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { redesignColors } from 'resources/theme/theme.colors';

export interface GenericHeader {
  id: string;
  label?: string;
  alignRight?: boolean;
}

export interface GenericTableHeadProps {
  headers: GenericHeader[];
}

const GenericTableHead: FC<GenericTableHeadProps> = ({ headers }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      {headers.map((header) => {
        return (
          <TableCell {...(header.alignRight && { align: 'right' })} sx={{ background: redesignColors.blue.blue1 }} key={header.id}>
            <Typography variant='subtitle1' sx={{ fontWeight: '400' }}>
              {header.label && t(header.label)}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default GenericTableHead;
