import { Button, Card, CardActions, CardContent, Dialog, DialogContent, Grid, IconButton, SvgIcon, TableCell, TableRow, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import ContextLink from 'components/_base/ContextLink';
import { isAfter, isEqual } from 'date-fns';
import { useCurrentUser, useNotification } from 'hooks';
import { ReactComponent as RightArowIcon } from 'resources/icons/right_arrow.svg';
import { AppointmentItem2023 } from 'models/appointment.item.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertDate } from 'utils/func/Date.func';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import { redesignColors } from 'resources/theme/theme.colors';
import { PreregistrationResponse } from 'models/preregistration.model';
import QrCodeModal from './components/QrCodeModal';

const tableHeaders: GenericHeader[] = [
  {
    id: 'patient',
    label: 'appointment.list.table.patients',
  },

  {
    id: 'date',
    label: 'appointment.list.table.date',
  },

  { id: 'actions', label: undefined },
];

const PreregistrationsList: FC = () => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const [data, setData] = useState<PreregistrationResponse[]>([]);
  const { notification } = useNotification();
  const [reload, setReload] = useState(false);
  const paginatorData: GenericPaginator = {
    total: 0,
    size: DEFAULT_PAGINATION_SIZE,
    page: 1,
  };
  const [paginator, setPaginator] = useState(paginatorData);

  const [selectedPreregistration, setSelectedPreregistration] = useState<PreregistrationResponse | null>(null);

  useEffect(() => {
    if (currentUser) {
      api.preregistration
        .getPreregistrations(currentUser.id)
        .then((preregistrations) => {
          setData(preregistrations);
          setPaginator((prev) => ({ ...prev, total: preregistrations.length }));
        })
        .catch(() => notification(t('homepage.preregistration.notification.error'), 'error'));
    }
  }, [currentUser, notification, t, reload]);

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginator(pagination);
  };

  return (
    <BodyTemplate
      title={t('homepage.preregistrations.title')}
      action={
        <ContextLink to='PREREGISTRATION'>
          <Button variant='contained' fullWidth>
            {t('preregistration.list.actionButton')}
          </Button>
        </ContextLink>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ padding: 0 }}>
            <GenericTable headers={tableHeaders} pagination={paginator} setPagination={handlePaginationChange}>
              {data.length > 0 ? (
                data.map((preregistration, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      <Typography variant='body1'>
                        {preregistration.first_name} {preregistration.last_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'> {convertDate(preregistration.created_at, false, 'yyyy-MM-dd HH:mm')}</Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => setSelectedPreregistration(preregistration)}>
                        <SvgIcon component={RightArowIcon} sx={{ fontSize: 28, color: redesignColors.grey.grey2 }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <NoResultsTableRow colSpan={tableHeaders.length} noResultText={'common.noResult'} />
              )}
            </GenericTable>
          </Card>
        </Grid>
      </Grid>
      {selectedPreregistration && <QrCodeModal preregistration={selectedPreregistration} onClose={() => setSelectedPreregistration(null)} />}
    </BodyTemplate>
  );
};

export default PreregistrationsList;
