import { Box, Theme } from '@mui/material';
import CustomRoutes from 'routes/CustomRoutes';
import LeftDrawer from 'components/_layout/navigation/LeftDrawer';
import Footer from 'components/_layout/footer';
import Header from 'components/_layout/header';
import { useAuthenticated, useIsMobileView, useIsTempPatient, useDisclaimerNeeded } from 'hooks';
import { SnackbarProvider } from 'notistack';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GlobalState } from 'store/reducers';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_OPEN } from 'utils/Constants';

const AppLayout: FC = () => {
  const isOpen = useSelector((state: GlobalState) => state.navMenu.isOpen);
  const isAuthenticated = useAuthenticated();
  const isDisclaimerNeeded = useDisclaimerNeeded();
  const isMobileView = useIsMobileView();
  const isTempPatient = useIsTempPatient();
  const { pathname } = useLocation();

  const drawerWidthOpen = DRAWER_WIDTH_OPEN;
  const drawerWidthClosed = DRAWER_WIDTH_CLOSED;
  const style = {
    marginLeft: isAuthenticated && !isTempPatient && !isDisclaimerNeeded && !isMobileView ? (isOpen ? `${drawerWidthOpen}px` : `${drawerWidthClosed}px`) : 0,
    transition: (theme: Theme) => (isAuthenticated && !isTempPatient ? `margin-left ${theme.transitions.duration.enteringScreen}ms  ${theme.transitions.easing.easeOut} 0ms` : 'unset'),
    minHeight: 'calc(100vh - 72px)',
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent',
  };
  const appBackground = 'linear-gradient(180deg, rgba(243,250,253,1) 0%, rgba(203,231,253,1) 100%)';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <SnackbarProvider maxSnack={3} style={{ cursor: 'pointer' }}>
      <Box style={{ background: appBackground }}>
        <Header />
        {!isTempPatient && !isDisclaimerNeeded && (!isMobileView || isOpen) && <LeftDrawer />}
        {!(isOpen && isMobileView) && (
          <Box sx={style}>
            <CustomRoutes />
            <Footer />
          </Box>
        )}
      </Box>
    </SnackbarProvider>
  );
};

export default AppLayout;
