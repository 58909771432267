import { Stack, Typography } from "@mui/material";
import { FC, ReactElement } from "react";

interface Service {
   icon: ReactElement;
   name: string;
}

interface LabServicesProps {
   services: Service[];
}

const LabServices: FC<LabServicesProps> = ({services}) => {
   return services.map((service, i) => (
      <Stack key={`serivice-${i}`} sx={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
         {service.icon}
         <Typography>{service.name}</Typography>
      </Stack>
   ));
};

export default LabServices;