import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { FilterResultsDate, FilterResultsFormValues, ResultNew } from 'models/result.model';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const defaultValues: FilterResultsFormValues = {
  doctors: [],
  tags: [],
  date: FilterResultsDate.all,
  datesRangeFrom: new Date(),
  datesRangeTo: new Date(),
};

export const filterByDate = (result: ResultNew, date: FilterResultsDate, datesRangeFrom: Date, datesRangeTo: Date): boolean => {
  const now = dayjs();
  switch (date) {
    case FilterResultsDate.today:
      return !!dayjs(result.sampling_time).isSame(now, 'day');
    case FilterResultsDate.lastMonth:
      const startOfLastMonth = now.subtract(1, 'month').startOf('month');
      const endOfLastMonth = now.subtract(1, 'month').endOf('month');

      return !!dayjs(result.sampling_time).isSameOrAfter(startOfLastMonth) && dayjs(result.sampling_time).isSameOrBefore(endOfLastMonth);
    case FilterResultsDate.last12Month:
      const twelveMonthsAgo = now.subtract(12, 'month');

      return !!dayjs(result.sampling_time).isSameOrAfter(twelveMonthsAgo) && dayjs(result.sampling_time).isSameOrBefore(now);

    case FilterResultsDate.datesRange:
      return !!dayjs(result.sampling_time).isSameOrAfter(datesRangeFrom) && dayjs(result.sampling_time).isSameOrBefore(datesRangeTo);

    default:
      return true;
  }
};
