import { createTheme } from '@mui/material/styles';

export const redesignColors = {
  blue: {
    dark: '#0F6F99',
    blue1: '#D9F5FF',
    blue2: '#F2FAFD',
    blue_hover: '#42B5E8',
    main: '#46BFF5',
  },
  grey: {
    grey1: '#4B6773',
    grey2: '#7A9099',
    grey3: '#C3D3D9',
    grey4: '#D5E1E5',
  },
  dark: '#203740',
  red: '#FF6A66',
  green: {
    common: '#2EE581',
    light: '#ABF5CD',
    dark: '#24B265',
  },
  orange: '#FF9D00',
  dialogBG: '#E9F0F2',
};
const theme = createTheme({
  palette: {
    common: {
      black: redesignColors.dark,
      white: '#FFFFFF',
    },
    primary: {
      main: '#46BFF5',
      contrastText: redesignColors.dark,
    },
    secondary: {
      main: redesignColors.dark,
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#FF3838',
      contrastText: redesignColors.dark,
    },
    warning: {
      main: '#FF3366',
      contrastText: redesignColors.dark,
    },
    success: {
      main: '#02A521',
      contrastText: redesignColors.dark,
    },
    text: {
      primary: redesignColors.dark,
      secondary: redesignColors.grey.grey1,
      disabled: '#808080',
    },
    graph: {
      success: '#00E661',
      error: '#FF3366',
      warning: '#FF9C33',
      info: '#FFC32C',
    },
    background: {
      default: '#F1F1EF',
      paper: '#FFFFFF', //card
    },
    footer: {
      main: '#FFFFFF',
    },
    header: {
      main: '#ECF9FE',
    },
    divider: '#E6E6E6',
    gold: '#FFD800',
  },
});

export default theme;

declare module '@mui/material/styles' {
  interface Palette {
    graph: {
      success: string;
      error: string;
      warning: string;
      info: string;
    };
    gold: string;
    footer: { main: string };
    header: { main: string };
  }
  interface PaletteOptions {
    graph: {
      success: string;
      error: string;
      warning: string;
      info: string;
    };
    gold: string;
    footer: { main: string };
    header: { main: string };
  }

  export function createTheme(options?: PaletteOptions): Palette;
}
