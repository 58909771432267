import { Box, Card, CardContent, Grid, SvgIcon, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useIsMobileView, useLocale } from 'hooks';
import { FC } from 'react';
import { ReactComponent as factureRegleIcon } from 'resources/icons/factureRegle.svg';
import { ReactComponent as CatalogueAnalysesIcon } from 'resources/icons/catalogue_analyses.svg';
import { ReactComponent as PreregisterIcon } from 'resources/icons/check_in.svg';
import { ReactComponent as LabomobileIcon } from 'resources/icons/collection_service.svg';
import { ReactComponent as PickendoheemIcon } from 'resources/icons/calendar.svg';
import { ReactComponent as FertilityIcon } from 'resources/icons/fertility.svg';
import { ReactComponent as NewsIcon } from 'resources/icons/news.svg';
import { ReactComponent as RightArowIcon } from 'resources/icons/right_arrow.svg';
import RoutePaths from 'utils/RoutePaths';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';

const RightCards: FC = () => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const locale = useLocale();

  const cardsList: Array<{
    title: string;
    subtitle?: string;
    icon: any;
    link: keyof typeof RoutePaths;
    external?: boolean;
    params?: Object;
  }> = [
    {
      title: t('landing_page.card.administrative_preregister.title'),
      // subtitle: t('landing_page.card.administrative_preregister.subtitle'),
      icon: PreregisterIcon,
      link: 'PREREGISTRATION',
    },
    {
      title: t('landing_page.card.anytime_anywhere.title'),
      // subtitle: t('landing_page.card.anytime_anywhere.subtitle'),
      icon: PickendoheemIcon,
      link: 'BOOK_APPOINTMENT',
    },
    {
      title: t('landing_page.card.labomobile.title'),
      // subtitle: t('landing_page.card.labomobile.subtitle'),
      icon: LabomobileIcon,
      link: 'LABOMOBILE',
      external: true,
    },
    {
      title: t('landing_page.card.fertility.title'),
      // subtitle: t('landing_page.card.safeplace.subtitle'),
      icon: FertilityIcon,
      link: 'FERTILITY',
      external: true,
      params: { locale: locale },
    },
    {
      title: t('landing_page.card.news.title'),
      // subtitle: t('landing_page.card.facture.subtitle'),
      icon: NewsIcon,
      link: 'NEWS',
    },
    {
      title: t('landing_page.card.safeplace.title'),
      // subtitle: t('landing_page.card.safeplace.subtitle'),
      icon: CatalogueAnalysesIcon,
      link: 'ANALYSIS_CATALOG',
    },
    {
      title: t('landing_page.card.facture.title'),
      // subtitle: t('landing_page.card.facture.subtitle'),
      icon: factureRegleIcon,
      link: 'INVOICES',
    },
  ];

  return (
    <Card
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        background: redesignColors.blue.blue1,
        backgroundSize: 'cover',
        boxShadow: 'none',
      }}
    >
      <CardContent sx={{ textAlign: 'center', alignItems: 'center', marginBottom: '6%' }}>
        <Grid item>
          <Typography variant='h1'>{t('landing_page.login.title2')}</Typography>
        </Grid>
      </CardContent>
      <CardContent sx={{ margin: '2%' }}>
        {cardsList.map((card) => (
          <Grid
            container
            key={card.title}
            spacing={1}
            sx={{
              alignItems: 'center',
              width: '100%',
              justifyContent: 'center',
              height: '100%',
              margin: isMobileView ? '2%' : '0.1',
            }}
          >
            <Grid
              item
              component={ContextLink}
              to={card.link}
              external={card.external}
              params={card.params}
              sx={{
                backgroundColor: 'white',
                margin: '2%',
                width: '100%',
                paddingTop: '0 !important',
                paddingRight: '8px',
                height: isMobileView ? '100%' : '64px',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                '&:hover': { opacity: 0.6 },
                justifyContent: 'space-between',
              }}
            >
              <Box display='flex' alignItems='center'>
                <SvgIcon component={card.icon} sx={{ fontSize: 48 }} />
                <Typography
                  variant='body2'
                  sx={{
                    fontWeight: 'medium',
                    fontSize: 16,
                    ml: 1,
                  }}
                >
                  {card.title}
                </Typography>
              </Box>
              <SvgIcon component={RightArowIcon} sx={{ fontSize: 24 }} />
            </Grid>
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
};

export default RightCards;
