import { useEffect, useMemo, useState } from 'react';
import { CONST_FAVORITE_CENTERS } from 'utils/Constants';

const useFavorites = (): [string[], (value: string) => void] => {
  const [favorites, setFavorites] = useState<string[]>([]);

  useEffect(() => {
    const storageItem = localStorage.getItem(CONST_FAVORITE_CENTERS);
    if (storageItem) {
      const items = JSON.parse(storageItem) as string[];
      setFavorites(items || []);
    }
  }, []);

  const toggleFavorite = (centerId: string) => {
    const storageItem = localStorage.getItem(CONST_FAVORITE_CENTERS) || JSON.stringify([]);

    const items = JSON.parse(storageItem) as string[] | undefined;
    if (items) {
      const updatedFavorites = favorites.includes(centerId) ? favorites.filter((id) => id !== centerId) : [...favorites, centerId];
      setFavorites(updatedFavorites);

      localStorage.setItem(CONST_FAVORITE_CENTERS, JSON.stringify(updatedFavorites));
    }
  };

  return [favorites, toggleFavorite];
};

export default useFavorites;
