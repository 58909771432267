import { Box, Button, Chip, Grid, useTheme } from '@mui/material';
import { FC } from 'react';

import { ArrowDownward as ArrowDownwardIcon } from '@mui/icons-material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { redesignColors } from 'resources/theme/theme.colors';
import { useTranslation } from 'react-i18next';
import { FilterResultsFormValues, ResultIssuer } from 'models/result.model';
import { mockTags } from './ResultsForm';

type ResultsFilterBarProps = {
  setOpen: (value: boolean) => void;
  setSortFromNewToOld: () => void;
  sortFromNewToOld: boolean;
  doctors: ResultIssuer[];
  filterData: FilterResultsFormValues | null;
  setFilterData: (value: FilterResultsFormValues | null) => void;
};

const ResultsFilterBar: FC<ResultsFilterBarProps> = ({ filterData, sortFromNewToOld, setSortFromNewToOld, setOpen, setFilterData, doctors }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const tagChips = () => {
    if (!filterData) {
      return null;
    }
    const { tags } = filterData;
    return (
      <>
        {tags.map((id) => {
          const tag = mockTags.find((doc) => doc.id === id);
          if (!tag) {
            return null;
          }
          return <Chip variant='outlined' color='secondary' label={tag.name} onDelete={() => setFilterData({ ...filterData, tags: tags.filter((docId) => docId !== id) })} sx={{ m: 1 }} />;
        })}
      </>
    );
  };
  const doctorChips = () => {
    if (!filterData) {
      return null;
    }
    const { doctors: doctorsIds } = filterData;
    return (
      <>
        {doctorsIds.map((id) => {
          const doctor = doctors.find((doc) => doc.id === id);
          if (!doctor) {
            return null;
          }
          return (
            <Chip
              variant='outlined'
              color='secondary'
              label={`${doctor.first_name} ${doctor.last_name}`}
              onDelete={() => setFilterData({ ...filterData, doctors: doctorsIds.filter((docId) => docId !== id) })}
              sx={{ m: 1 }}
            />
          );
        })}
      </>
    );
  };
  return (
    <Grid item xs={12}>
      <Box display='flex' alignItems='center' flexWrap='wrap'>
        <Button
          variant='outlined'
          color='secondary'
          sx={{ width: 80, fontSize: '14px', position: 'relative' }}
          startIcon={<FilterAltIcon sx={{ color: theme.palette.common.white }} />}
          onClick={() => setOpen(true)}
        >
          {filterData && <div style={{ position: 'absolute', top: -5, right: -5, width: '14px', height: '14px', background: redesignColors.red, borderRadius: '50%' }} />}
          {t('centers.filter')}
        </Button>
        <Button
          variant='outlined'
          startIcon={<ArrowDownwardIcon sx={{ transform: `rotate(${sortFromNewToOld ? '0deg' : '-180deg'})`, transition: 'all .3s' }} />}
          color='primary'
          sx={{ ml: 2, border: `1px solid ${redesignColors.grey.grey4}` }}
          onClick={() => setSortFromNewToOld()}
        >
          {sortFromNewToOld ? t('result_doctor.from_new_to_old') : t('result_doctor.from_old_to_new')}
        </Button>
        {tagChips()}
        {doctorChips()}
        {filterData && (
          <Button
            variant='text'
            startIcon={<CloseIcon sx={{ transform: `rotate(${sortFromNewToOld ? '0deg' : '-180deg'})`, transition: 'all .3s' }} />}
            color='primary'
            sx={{ ml: 2 }}
            onClick={() => setFilterData(null)}
          >
            {t('result_doctor.clear_filters')}
          </Button>
        )}
      </Box>
    </Grid>
  );
};

export default ResultsFilterBar;
