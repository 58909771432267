import theme_color from 'resources/theme/theme.colors';

import { createTheme } from '@mui/material';
import { createBreakpoints } from '@mui/system';
import { redesignColors } from 'resources/theme/theme.colors';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          '&:not(:last-of-type)': {
            marginRight: '24px',
            border: '2px solid',
            borderRadius: 8,
            [breakpoints.down('md')]: {
              marginRight: '0px',
              marginBottom: '8px',
            },
          },
          '&:not(:first-of-type)': {
            marginLeft: 0,
            border: '2px solid',
            borderColor: redesignColors.grey.grey2,
            borderRadius: 8,
          },
          '&.Mui-selected': {
            border: '2px solid',
            borderColor: theme_color.palette.primary.main,
            backgroundColor: theme_color.palette.primary.main,
            '&:hover': {
              backgroundColor: redesignColors.blue.blue_hover,
            },
          },
          '&:hover': {
            borderColor: redesignColors.blue.main,
            backgroundColor: "transparent"
          },
          '&.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected': {
            border: '2px solid transparent !important',
            backgroundColor: theme_color.palette.primary.main,
            color: theme_color.palette.text.primary,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          color: theme_color.palette.text.primary,
          border: '2px solid',
          borderColor: redesignColors.grey.grey2,
          padding: '8px 24px',
          borderRadius: 8,
        },
      },
    },
  },
});

export default theme;
