import theme_color, { redesignColors } from 'resources/theme/theme.colors';

import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          width: 'auto',
          p: 0
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'medium',
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          color: `${redesignColors.dark} !important`,
          fontWeight: '500',
        },
        selectLabel: { fontSize: 16 },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:not(:first-of-type)': {
            borderTop: '2px solid',
            borderTopColor: redesignColors.grey.grey4
          },
          '&:first-of-type': {
            border: 'none',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: `2px solid ${redesignColors.grey.grey4}`,
        },
      },
    },
  },
});

export default theme;
