import { Autocomplete, Button, Grid, SvgIcon, TextField } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSvg } from 'resources/icons/close_red.svg';

interface FindFormProps {
  specialities: { id: number; label: string }[];
  cities: { label: string }[];
  isSubmitting: boolean;
}

const FindForm: FC<FindFormProps> = ({ specialities, cities, isSubmitting }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const [specialityInput, setSpecialityInput] = useState('');
  const [cityInput, setCityInput] = useState('');
  const { register, reset, setValue, watch } = useFormContext();

  const nameField = watch('name');
  const isAnyFieldFilled = useMemo(() => Boolean(cityInput || specialityInput || nameField), [cityInput, nameField, specialityInput]);

  useEffect(() => {
    setSpecialityInput('');
    setCityInput('');
  }, [specialities]);

  return (
    <Grid container spacing={isMobileView ? 1 : 3}>
      <Grid item xs={12} sx={{ display: 'flex' }}>
        <Grid container spacing={isMobileView ? 1 : 0}>
          <Grid item>
            <Autocomplete
              sx={{ width: '348px', marginRight: '24px' }}
              clearIcon={<SvgIcon component={CloseSvg} />}
              disablePortal
              inputValue={specialityInput}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              onInputChange={(event, value, reason) => {
                if (event && event.type === 'blur') {
                  setSpecialityInput('');
                } else if (reason !== 'reset') {
                  setSpecialityInput(value);
                }
              }}
              onChange={(_, value) => {
                setValue('speciality', value?.id ?? -1);
                setSpecialityInput(value?.label ?? '');
              }}
              options={specialities}
              renderInput={(params) => {
                return <TextField {...params} variant='filled' label={t('findDoctor.form.speciality')} name='speciality' />;
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '348px', marginRight: '24px' }}
              variant='filled'
              label={t('findDoctor.form.name')}
              {...register('name')}
              inputProps={{
                name: 'name',
                id: 'name',
              }}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              sx={{ width: '348px' }}
              clearIcon={<SvgIcon component={CloseSvg} />}
              disablePortal
              inputValue={cityInput}
              {...register('city')}
              options={cities}
              onInputChange={(event, value, reason) => {
                if (event && event.type === 'blur') {
                  setCityInput('');
                } else if (reason !== 'reset') {
                  setCityInput(value);
                }
              }}
              onChange={(_, value) => {
                setCityInput(value?.label ?? '');
              }}
              renderInput={(params) => <TextField {...params} variant='filled' label={t('findDoctor.form.city')} name='city' />}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} display='flex' alignItems='center'>
        <Button type='submit' variant='contained' disabled={isSubmitting || !isAnyFieldFilled} sx={{ marginRight: '24px' }}>
          {t('common.action.search')}
        </Button>
        {isAnyFieldFilled && (
          <Button
            variant='outlined'
            disabled={isSubmitting}
            onClick={() => {
              reset();
              setSpecialityInput('');
            }}
          >
            {t('findDoctor.searchBar.actions.resetFilter')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default FindForm;
