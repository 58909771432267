import { Accordion, AccordionDetails, AccordionSummary, Button, CardContent, Divider, Stack, Typography } from "@mui/material";
import { FC, ReactElement, useState } from "react";
import { ReactComponent as BionextFavicon } from "resources/icons/bionext-favicon.svg";
import { Circle, ExpandMore } from "@mui/icons-material";
import ContextLink from "components/_base/ContextLink";

import { ReactComponent as PhoneIcon } from "resources/icons/phone-contact.svg";
import { ReactComponent as EmailIcon } from "resources/icons/email.svg";

import DailyHours from "./DailyHours";
import Specialties from "./Specialties";
import LabServices from "./LabServices";
import { useTranslation } from "react-i18next";

interface LabService {
   icon: ReactElement,
   name: string;
}

interface Lab {
   image: string;
   open: boolean;
   name: string;
   address: string;
   distance: string;
   closeAt: string;
   email: string;
   telephone: string;
   dailyHours: object;
   specialties: string[];
   services: LabService[];
}

interface LabInfoProps {
   lab: Lab;
   title: string;
}

const LabInfo: FC<LabInfoProps> = ({lab, title}) => {
   const {t} = useTranslation();
   const [expanded, setExpanded] = useState(false);
   
   const {image, open, name, address, distance, closeAt, email, telephone, dailyHours, specialties, services} = lab;

   return (
      <CardContent sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', gap: '10px'}}>
         <Stack sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', width: '100%', gap: '10px'}}>
            <BionextFavicon />
            <Typography sx={{ mt: 0, fontWeight: '600' }} component="span" variant="body1">
               {title}
            </Typography>          
         </Stack>

         <Accordion id={`${title}-accordion`} variant="elevation" onChange={(e, expanded) => setExpanded(expanded)}>
            <AccordionSummary expandIcon={<ExpandMore sx={{'&.MuiSvgIcon-root': { color: 'black' }}} />} sx={{alignItems: 'start'}}>
               <Stack spacing={2}>
                  <Stack sx={{display: 'flex', flexDirection: 'row', gap: '24px'}} spacing={2}>
                     {expanded && (<img alt="lab" src={image} style={{borderRadius: '8px'}} />)}
                     <Stack spacing={2}>
                        <Typography component="span" variant="body1" sx={{fontWeight: '600'}}>
                           {name}
                        </Typography>

                        <Typography component="span" variant="body1">
                           {address}
                        </Typography>

                        {expanded && (
                           <a href={`mailto:${email}`} style={{ display: 'flex', alignItems: 'center', gap: '5px', textDecoration: 'none', color: 'black' }}>
                              <EmailIcon />
                              {email}
                           </a>
                        )}

                        {expanded && (
                           <div style={{ display: 'flex', alignItems: 'center', gap: '5px', textDecoration: 'none', color: 'black' }}>
                              <PhoneIcon />
                              {telephone}
                           </div>
                        )}

                        {expanded && <Typography sx={{color: open ? 'green' : 'red', fontWeight: '600'}} >{open ? "Open" : 'Closed'}</Typography>}
                     </Stack>
                  </Stack>

                  {!expanded && (
                     <Stack sx={{display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'start'}}>
                        <Typography sx={{fontWeight: '600'}}>{distance}</Typography>
                        <Circle sx={{width: '12px', height:'12px', alignSelf: 'center', '&.MuiSvgIcon-root': {color: 'black'}}}/>
                        <Typography sx={{color: open ? 'green' : 'red', fontWeight: '600'}} >{open ? "Open" : 'Closed'}</Typography>
                        <Typography sx={{mt: 0, alignSelf: 'center'}} variant="body2">{closeAt}</Typography>
                     </Stack>
                  )}
               </Stack> 
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '24px'}}>
                  <Stack>
                     <Typography variant="body1" sx={{fontWeight: 600}}>
                        {t('checkin.stepper.steps.services.lab_info.daily_hours')}
                     </Typography>
                     <DailyHours dailyHours={dailyHours} />
                  </Stack>
                  <Stack spacing={1}>
                     <Typography variant="body1" sx={{fontWeight: 600}}>
                        {t('checkin.stepper.steps.services.lab_info.specialties')}
                     </Typography>
                     <Specialties specialties={specialties} />
                  </Stack>
                  <Stack spacing={1}>
                     <Typography variant="body1" sx={{fontWeight: 600}}>
                        {t('checkin.stepper.steps.services.lab_info.services')}
                     </Typography>
                     <LabServices services={services} />
                  </Stack>
            </AccordionDetails>
         </Accordion>

         <Divider />

         <ContextLink to="CENTERS">
            <Button variant="outlined">
               {t('checkin.stepper.steps.services.lab_info.button.see_more')}
            </Button>
         </ContextLink>
      </CardContent>
   )
};

export default LabInfo;