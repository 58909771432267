import MyLocationIcon from '@mui/icons-material/MyLocation';
import { LoadingButton } from '@mui/lab';
import { Backdrop, Box, Button, Card, CardContent, CircularProgress, Divider, Grid, InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useContextRedirection, useIsMobileView, useLocale, useNotification } from 'hooks';
import { Center, CenterIncludedFile, CenterIncludedService, CentersFormValues, CityChoice, LaboratoireTypeEnum, ScheduleChoice, SpecialityChoice } from 'models/centers.model';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ReactComponent as SortIcon } from 'resources/icons/sort.svg';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import CenterDetails from './components/CenterDetails';
import CentersList from './components/CentersList';
import MapCenters from './components/MapCenters';
import { redesignColors } from 'resources/theme/theme.colors';
import FilterDrawer from './components/FilterDrawer';
import { ReactComponent as IconSearch } from 'resources/icons/search.svg';
import { ReactComponent as NotificationsIcon } from 'resources/icons/notifications.svg';
import { defaultValues, distanceFilter, scheduleFilter, sortFromFarToNear as sortFromNearToFar } from './utils/filters';
import { CenterInfo, Position } from './types';
import RoutePaths from 'utils/RoutePaths';
import ConfirmDialog from 'components/confirm-dialog';
import useFavorites from 'hooks/useFavorites';

const ScheduleChoices: ScheduleChoice[] = [
  { value: 'MOR', label: 'Matin' },
  { value: 'AFT', label: 'Après-midi' },
  { value: 'SAT', label: 'Samedi' },
];

const Centers: FC = () => {
  const [favorites, toggleFavorite] = useFavorites();
  const [open, setOpen] = useState(false);

  const isMobileView = useIsMobileView();
  const navigate = useContextRedirection();

  //  Translations
  const { t } = useTranslation();
  const locale = useLocale();

  // User's current position
  const [myPosition, setMyPosition] = useState<Position | undefined>(undefined);

  // Form
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [isLoadingGeolocation, setLoadingGeolocation] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [fromNearToFar, setFromNearToFar] = useState(true);
  const [searchValue, setSearchValue] = useState<string>('');

  const [cities, setCities] = useState<CityChoice[]>([]);
  const [filterData, setFilterData] = useState<CentersFormValues | null>(null);
  const [specialities, setSpecialities] = useState<SpecialityChoice[]>([]);
  const [schedules, setSchedules] = useState<ScheduleChoice[]>([]);
  const { notification } = useNotification();
  // Data to display
  const [centersInfo, setCentersInfo] = useState<CenterInfo[]>([]);
  const [markersState, setMarkersState] = useState<boolean[]>(centersInfo.map((position) => false));

  const [selectedCenterInfo, setSelectedCenterInfo] = useState<{
    center: Center;
    file: CenterIncludedFile;
    services: CenterIncludedService[];
  }>();

  const centerDetailsRef = useRef<HTMLDivElement>(null);
  // Init data and form options
  useEffect(() => {
    setSchedules(ScheduleChoices);
    api.bnl
      .searchCenters({ lang: locale })
      .then(async (val) => {
        setCentersInfo(val);
        setCities(
          Array.from(new Set(val.map((info) => info.center.attributes.field_prelevement_ville)))
            .sort((a, b) => a.localeCompare(b))
            .map((info) => ({ value: info, label: info })),
        );
        setSpecialities(
          Array.from(new Set(val.map((info) => info.services.map((service) => service.attributes.name)).flat()))
            .sort((a, b) => a.localeCompare(b))
            .map((info) => ({ value: info, label: info })),
        );
        setSubmitting(false);
      })
      .catch(() => notification(t('centers.notification.error'), 'error'));
  }, [locale, notification, t]);

  useEffect(() => {
    const data = filterData || defaultValues;
    const { labomobile, laboratoire, pharmacy } = data;
    setSubmitting(true);
    const laboratoireTypes = [labomobile ? LaboratoireTypeEnum.labomobile : null, laboratoire ? LaboratoireTypeEnum.laboratoire : null, pharmacy ? LaboratoireTypeEnum.pharmacy : null].filter(
      Boolean,
    ) as string[];
    api.bnl
      .searchCenters({
        lang: locale,
        city: data.city,
        speciality: data.speciality,
        title: searchValue,
        PMR: data.PRMAccessRequired,
        parking: data.publicParkingRequired,
        laboratoireTypes,
      })
      .then(async (val) => {
        const filteredVal = val.filter((info) => {
          return distanceFilter(info, data.distance, myPosition) && scheduleFilter(info, data.schedule, data.day, data.time);
        });
        const updatedValues = myPosition ? sortFromNearToFar(filteredVal, myPosition, !fromNearToFar) : filteredVal;

        setCentersInfo(updatedValues);
        setSubmitting(false);
      });
  }, [filterData, locale, fromNearToFar, myPosition, searchValue]);

  // Update markers on data change
  useEffect(() => {
    setMarkersState(centersInfo.map((position) => false));
  }, [centersInfo]);

  useEffect(() => {
    if (selectedCenterInfo && !isMobileView && centerDetailsRef.current) {
      centerDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [centerDetailsRef, isMobileView, selectedCenterInfo]);

  const getCurrentPosition = useCallback(() => {
    setLoadingGeolocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMyPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLoadingGeolocation(false);
        },
        (positionError) => {
          notification(t('centers.notification.errorGeolocation'), 'error');
          setLoadingGeolocation(false);
        },
      );
    } else {
      notification(t('centers.notification.geolocationNotActivated'), 'error');
      setLoadingGeolocation(false);
    }
  }, [notification, t]);

  const handleOpenMapMarker = (index: number) => {
    const newMarkers = Array(markersState.length).fill(false);
    newMarkers[index] = true;
    setMarkersState(newMarkers);
    setSelectedCenterInfo(centersInfo[index]);
  };
  const handleCloseMapMarker = () => {
    const newMarkers = Array(markersState.length).fill(false);
    setMarkersState(newMarkers);
  };

  return (
    <BodyTemplate title={t('bodyTemplate.centers')}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign='right' display={{ xs: 'none', md: 'flex' }} sx={{ justifyContent: 'space-between' }}>
              <TextField
                defaultValue={searchValue}
                variant='filled'
                onChange={(e) => setSearchValue(e?.target?.value || '')}
                label={`${t('common.action.search')}`}
                sx={{ maxWidth: '526px', width: '60%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SvgIcon component={IconSearch} />
                    </InputAdornment>
                  ),
                }}
              />
              <Button variant='outlined' startIcon={<NotificationsIcon />} sx={{ minWidth: '212px' }} onClick={() => setOpen(true)}>
                {filterData && <div style={{ position: 'absolute', top: -5, right: -5, width: '14px', height: '14px', background: redesignColors.red, borderRadius: '50%' }} />}
                {t('centers.lab_updates')}
              </Button>
            </Grid>
            <Grid item xs={12} display={{ xs: 'none', md: 'flex' }} sx={{ justifyContent: 'space-between' }}>
              <Box pt={2}>
                {!isMobileView && (
                  <Button
                    variant='outlined'
                    sx={{ border: `1px solid ${redesignColors.grey.grey4}`, width: 80, fontSize: '14px', position: 'relative' }}
                    startIcon={<FilterAltIcon sx={{ color: redesignColors.blue.main }} />}
                    onClick={() => setOpenDrawer(true)}
                  >
                    {filterData && <div style={{ position: 'absolute', top: -5, right: -5, width: '14px', height: '14px', background: redesignColors.red, borderRadius: '50%' }} />}
                    {t('centers.filter')}
                  </Button>
                )}
                {!!myPosition && !isMobileView && (
                  <Button
                    variant='outlined'
                    sx={{ border: `1px solid ${redesignColors.grey.grey4}`, ml: 2, width: 165, px: 1, fontSize: '14px' }}
                    startIcon={<SvgIcon component={SortIcon} sx={{ color: redesignColors.blue.main }} />}
                    onClick={() => setFromNearToFar((prev) => !prev)}
                  >
                    {fromNearToFar ? t('centers.near_to_far') : t('centers.far_to_near')}
                  </Button>
                )}
              </Box>
              <LoadingButton variant='contained' sx={{ minWidth: '212px' }} startIcon={<MyLocationIcon />} onClick={getCurrentPosition} disabled={isLoadingGeolocation} loading={isLoadingGeolocation}>
                {t('centers.geolocate')}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={0}
                sx={{
                  ...(!isMobileView && { minHeight: '50vh' }),
                }}
              >
                <Grid item xs={isMobileView ? 12 : 3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <CentersList centersInfo={centersInfo} handleOpenMapMarker={handleOpenMapMarker} />
                  </Box>
                </Grid>
                {!isMobileView && (
                  <Grid item xs={9} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                    <MapCenters
                      centersInfo={centersInfo}
                      myPosition={myPosition}
                      markersState={markersState}
                      handleOpenMapMarker={handleOpenMapMarker}
                      handleCloseMapMarker={handleCloseMapMarker}
                      favorites={favorites}
                    />
                  </Grid>
                )}
              </Grid>
              <Backdrop
                open={isSubmitting}
                sx={{
                  position: 'absolute',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <CircularProgress color='inherit' />
              </Backdrop>
            </Grid>
            {selectedCenterInfo && !isMobileView && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} ref={centerDetailsRef}>
                  <CenterDetails centerInfo={selectedCenterInfo} favorites={favorites} toggleFavorite={toggleFavorite} />
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>

      <FilterDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onApply={(data) => setFilterData(data)}
        cities={cities}
        specialities={specialities}
        schedules={schedules}
        defaultFilters={filterData || defaultValues}
        locationEnabled={!!myPosition}
        onClear={() => setFilterData(null)}
      />
      <ConfirmDialog
        title={t('centers.lab_updates_dialog.title')}
        subtitle={t('centers.lab_updates_dialog.subtitle')}
        submitTitle={t('centers.lab_updates_dialog.submitTitle')}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={() => navigate(RoutePaths.CENTERS_UPDATE)}
      />
    </BodyTemplate>
  );
};

export default Centers;
