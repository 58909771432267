import { Grid } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { ReactComponent as NotificationIcon } from 'resources/icons/notification.svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CenterListItem from '../centers/components/CenterListItem';
import dayjs from 'dayjs';

const updates = [
  { title: 'Berbourg LABOMOBILE', status: 'open' },
  { title: 'Berbourg LABOMOBILE', status: 'close' },
  { title: 'Berbourg LABOMOBILE', status: 'open' },
];

const Centers: FC = () => {
  const { t } = useTranslation();

  return (
    <BodyTemplate title={t('bodyTemplate.centers_updates')} backButton icon={NotificationIcon}>
      <Grid container spacing={2}>
        {updates.map((updateItem, i) => (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CenterListItem key={`center-${i}`} showIcon title={updateItem.title} timeLabel={dayjs(new Date()).format('YYYY-MM-DD HH:MM')} status={updateItem.status} />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </BodyTemplate>
  );
};

export default Centers;
