import { LoadingButton } from '@mui/lab';
import { Box, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import { useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import AnalysisResults from './components/AnalysisResults';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';

const Analysis: FC = () => {
  const [data, setData] = useState<any>({});
  const [isSubmitting, setSubmitting] = useState<boolean>(true);
  const paginatorData: GenericPaginator = {
    total: 0,
    size: DEFAULT_PAGINATION_SIZE,
    page: 1,
  };

  const [paginator, setPaginator] = useState(paginatorData);
  const { notification } = useNotification();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const fetch = async () => {
      if (!isSubmitting) {
        return;
      }
      await api.analysis
        .getAnalysis(paginator.size, paginator.page, searchValue)
        .then((response) => {
          setData(response);
          setPaginator({
            total: response.total,
            size: response.size,
            page: response.page,
          });
        })
        .catch(() => {
          notification(t('analysis.notification.error'), 'error');
        });
      setSubmitting(false);
    };
    fetch();
  }, [isSubmitting, searchValue, paginator]);

  const handlePaginatorData = (data: GenericPaginator) => {
    setSearchValue('');
    setPaginator(data);
    setSubmitting(true);
  };

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.analyses')}>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <form>
              <Grid container display='flex' alignItems='center' spacing={1}>
                <Grid item xs={12} md={5}>
                  <TextField
                    variant='filled'
                    placeholder={t('analysis.form.name')}
                    value={searchValue}
                    fullWidth
                    onChange={(e) => setSearchValue(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon sx={{ color: '#212121' }} />
                        </InputAdornment>
                      ),
                      endAdornment: searchValue.length > 0 && (
                        <IconButton
                          onClick={() => {
                            setSearchValue('');
                            setSubmitting(true);
                          }}
                        >
                          <ClearIcon sx={{ fill: `${redesignColors.red} !important` }} />
                        </IconButton>
                      ),
                      sx: { background: 'white !important' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  {searchValue.length > 0 && (
                    <LoadingButton variant='contained' type='submit' fullWidth loading={isSubmitting} onClick={() => setSubmitting(true)}>
                      {t('common.action.search')}
                    </LoadingButton>
                  )}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AnalysisResults data={data} paginatorData={paginator} handlePaginatorAction={handlePaginatorData} />
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Analysis;
