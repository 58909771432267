import theme_color, { redesignColors } from 'resources/theme/theme.colors';

import { alpha, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          color: redesignColors.grey.grey2,
          boxShadow: 'none',
          disableElevation: true,
          '&:hover': {
            backgroundColor: alpha(theme_color.palette.primary.light, 0.2),
            boxShadow: 'none',
          },
        },
      },
    },
  },
});

export default theme;
