export enum SavedAddressType {
   Home = "Home",
   Work = "Work",
   Other = "Other"
};

export interface SavedAddress {
   id: number;
   address: string;
   postcode: number;
   city: string;
   type: SavedAddressType;
};