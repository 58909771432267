export const Days = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];

export const Hours = [
  {
    value: 0,
    label: '00:00',
  },
  {
    value: 100,
    label: '01:00',
  },
  {
    value: 200,
    label: '02:00',
  },
  {
    value: 300,
    label: '03:00',
  },
  {
    value: 400,
    label: '04:00',
  },
  {
    value: 500,
    label: '05:00',
  },
  {
    value: 600,
    label: '06:00',
  },
  {
    value: 700,
    label: '07:00',
  },
  {
    value: 800,
    label: '08:00',
  },
  {
    value: 900,
    label: '09:00',
  },
  {
    value: 1000,
    label: '10:00',
  },
  {
    value: 1100,
    label: '11:00',
  },
  {
    value: 1200,
    label: '12:00',
  },
  {
    value: 1300,
    label: '13:00',
  },
  {
    value: 1400,
    label: '14:00',
  },
  {
    value: 1500,
    label: '15:00',
  },
  {
    value: 1600,
    label: '16:00',
  },
  {
    value: 1700,
    label: '17:00',
  },
  {
    value: 1800,
    label: '18:00',
  },
  {
    value: 1900,
    label: '19:00',
  },
  {
    value: 2000,
    label: '20:00',
  },
  {
    value: 2100,
    label: '21:00',
  },
  {
    value: 2200,
    label: '22:00',
  },
  {
    value: 2300,
    label: '23:00',
  },
];
