import { Typography } from '@mui/material';
import { Gender } from 'models/profile.model';
import healthInsuranceList from './healthInsuranceList.json';

export const DEFAULT_PAGINATION_SIZE = 20;
export const DEFAULT_HISTORY_RESULTS_ANTECEDENT_NUMBER = 10;
export const NO_DATA_DISPLAY: JSX.Element = <Typography variant='body1'>-</Typography>;
export const DRAWER_WIDTH_OPEN = 320;
export const DRAWER_WIDTH_CLOSED = 75;
export const MAX_SIZE_FILE = 5000000;

export const ANALYSIS_PROFILE = {
  MAX_ANALYSIS: 150,
  MAX_FREQUENT_ANALYSIS: 10,
};

export const PRIORITY = {
  NORMAL: 1,
  URGENT: 8,
};

export const MIN_DATE = '1900-01-01';

export const MYDSP = {
  TYPE_CODE_SCHEME: '2.16.840.1.113883.6.1',
  TYPE_CODE: {
    RECOGNIZED_INTOLERANCE_ALLERGIES: '48765-2',
    FAMILY_HISTORY: '8670-2',
    MEDICAL_SURGICAL_HISTORY: '34117-2',
    DEPENDENCE_INSURANCE: 'DT-40006',
    AUTONOMY_CHECKUPS: '28636-9',
    FUNCTIONAL_CHECKUPS: '47420-5',
    DIAGNOSTIC_PROCEDURES_REPORTS: '70004-7',
    THERAPEUTIC_PROCEDURES_REPORTS: '47519-4',
    PREVENTIVE_PURPOSES_PROCEDURES_REPORTS: '28570-0',
    ADMINISTRATION_REPORTS: 'DT-65003',
    HOSPITAL_STAYS_DISCHARGE_LETTERS_REPORTS: '18842-5',
    HEALTH_FUND: 'DT-40002',
    MEDICO_SURGICAL_FUND_OR_OTHER_MUTUAL: 'DT-40003',
    VACCINATION_SCHEDULE: 'DT-70002',
    PALLIATIVE_CARE_NOTEBOOK: 'DT-40009',
    MEDICAL_CERTIFICATES: 'DT-20001',
    DRUG_DISPENSING: '60593-1',
    EDUCATION: '34895-3',
    ENTOURAGE_THIRD_PARTY_CAREGIVER_DSP_SUPPORT: 'DT-40008',
    HOLDER_EXPRESSION: '51855-5',
    INDIVIDUAL_RISK_FACTORS: '51898-5',
    MEDICAL_IMAGING: '18748-4',
    LODGING: 'DT-40004',
    JOB_EDUCATION_LEVEL: 'DT-40001',
    PROSTHESES_AND_DEVICES: '46264-8',
    DISTRESS_PROTOCOL: 'DT-65002',
    CHRONIC_CONDITIONS_CARE_PROTOCOL: 'DT-60002',
    MEDICAL_REPORTS: '47045-0',
    ADDITIONAL_SOCIAL_INFORMATION: 'DT-40005',
    BIOLOGICAL_EXAMINATIONS_RESULTS: '11502-2',
    EMERGENCY_ROOM_VISITS_SUMMARY: '59258-4',
    CARE_SUMMARY: '34745-0',
    PATIENT_SUMMARY: '60591-5',
    REGIMEN: 'DT-65001',
    CARE_FOLLOW_UP_AND_MULTIDISCIPLINARY_ACTS: '34140-4',
    DRUG_TREATMENTS: '57833-6',
    PRESCRIBED_MEDICAL_TREATMENTS: '57828-6',
    PREVENTIVE_TREATMENTS_PRESCRIBED_OR_ADMINISTERED: 'DT-55001',
    NURSING_TREATMENTS_PRESCRIBED: 'DT-60001',
    GUARDIANSHIP_CURATORSHIP_LEGAL_PROTECTION: 'DT-40007',
    VACCINATIONS: 'DT-70001',
  },
};
export const MAX_DISPLAYED_RELATIVES = 3;
// Localstorage constants variable
export const CONST_BIONEXT_CURRENT_USER_TOKEN = 'bionext-currentuser';
export const CONST_BIONEXT_TOKEN = 'bionext-token';
export const CONST_ESANTE_ACCESS_TOKEN = 'esante-access-token';
export const CONST_ESANTE_REFRESH_TOKEN = 'esante-refresh-token';
export const CONST_ESANTE_PKCE_TOKEN = 'esante-pkce-token';
export const CONST_ESANTE_TOKEN_EXPIRATION_TIME = 'esante-expiration-time';
export const CONST_WHATSNEW_FLAG = 'mylab-whatsnew';
export const CONST_FAVORITE_CENTERS = 'mylab-favorite-centers';
export const HIDE_RESULTS_REDESIGN = 'hide-results-redesign';

export const HEALTH_INSURANCE_LIST = healthInsuranceList;
export const CNS_CODE = '999';

export const GENDER = [
  {
    label: 'common.sex.woman',
    value: Gender.FEMALE_V4,
  },
  {
    label: 'common.sex.man',
    value: Gender.MALE_V4,
  },
];
