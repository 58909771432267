import EmailIcon from '@mui/icons-material/AlternateEmail';
import LocationIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Button, Grid, IconButton, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import config from 'config';
import { format, setDay } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import ptLocale from 'date-fns/locale/pt';
import { useIsMobileView } from 'hooks';
import { Center, CenterIncludedFile, CenterIncludedService } from 'models/centers.model';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { waitFormatHours } from 'utils/func/Date.func';
import { getStatus } from '../utils';
import { redesignColors } from 'resources/theme/theme.colors';
import ConfirmDialog from 'components/confirm-dialog';

type Props = {
  centerInfo: {
    center: Center;
    file: CenterIncludedFile;
    services: CenterIncludedService[];
  };
  toggleFavorite?: (value: string) => void;
  favorites?: string[];
  hideHeader?: boolean;
};

const CenterDetails = ({ centerInfo, hideHeader, favorites, toggleFavorite }: Props) => {
  const [open, setOpen] = useState(false);
  const isMobileView = useIsMobileView();
  const { t, i18n } = useTranslation();
  const locale: string = useMemo((): string => {
    const lang = i18n.language.split('-').shift();
    return lang ? lang : 'en';
  }, [i18n.language]);

  const localeMap = useCallback((lang: string) => {
    switch (lang) {
      case 'en':
        return enLocale;
      case 'de':
        return deLocale;
      case 'pt':
        return ptLocale;
      default:
        return frLocale;
    }
  }, []);

  const getAddress = (center: Center) => {
    const nb = center.attributes.field_prelevement_numero_rue;
    const rue = center.attributes.field_prelevement_rue;
    const code = center.attributes.field_prelevement_code_postal;
    const ville = center.attributes.field_prelevement_ville;
    return `${nb}, ${rue} ${code} ${ville}`;
  };

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12}>
        {!hideHeader && (
          <Grid
            item
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {centerInfo.file && (
              <Box
                component={'img'}
                src={`${config.websiteBionextApiUrl}${centerInfo.file.attributes.uri.url.replace('files/', 'files/styles/medium/public/')}`}
                alt={centerInfo.center.relationships.field_prelevement_photo.data.meta.alt}
                sx={{
                  width: 'auto',
                  height: '100%',
                  maxHeight: '290px',
                  borderRadius: 2,
                }}
              />
            )}
          </Grid>
        )}
        <Grid container spacing={0} item xs={!hideHeader ?? 12}>
          {!hideHeader && (
            <>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant='h2'>{centerInfo.center.attributes.title}</Typography>
                  {!!favorites?.length && toggleFavorite && (
                    <IconButton onClick={() => (!favorites?.includes(centerInfo.center.id) ? toggleFavorite(centerInfo.center.id) : setOpen(true))}>
                      {favorites?.includes(centerInfo.center.id) ? <Favorite sx={{ color: redesignColors.red }} /> : <FavoriteBorderIcon />}
                    </IconButton>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>{getAddress(centerInfo.center)}</Typography>
              </Grid>
            </>
          )}
          {hideHeader && (
            <Grid item xs={12} sx={{ ml: 0.5 }}>
              <Button
                variant='link'
                startIcon={<LocationIcon />}
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${centerInfo.center.attributes.title} ${getAddress(centerInfo.center)}`)}`}
              >
                {t('centers.details.location.button')}
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sx={{ ml: 0.5 }}>
            <Button variant='link' startIcon={<EmailIcon sx={{ fill: redesignColors.blue.main }} />} href={`mailto:${centerInfo.center.attributes.field_prelevement_email}`}>
              {centerInfo.center.attributes.field_prelevement_email}
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ ml: 0.5 }}>
            <Button variant='link' startIcon={<PhoneIcon sx={{ fill: redesignColors.blue.main }} />} href={`tel:${centerInfo.center.attributes.field_prelevement_telephone}`}>
              {centerInfo.center.attributes.field_prelevement_telephone}
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ ml: 0.5 }}>
            <Box sx={{ color: getStatus(centerInfo.center) === 'open' ? 'green' : 'red', height: 20, fontSize: 16, fontWeight: 600 }}>
              {t(`centers.details.schedule.${getStatus(centerInfo.center)}`)}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={isMobileView ? 12 : 3}>
          <Stack direction='row' spacing={2}>
            <Typography variant='subtitle1' fontWeight='700' sx={{ pb: 0.5 }}>
              {t('centers.details.schedule.title')}
            </Typography>
          </Stack>

          <Table size='small' padding='none'>
            <TableBody sx={{ px: 2 }}>
              {centerInfo.center.attributes.field_prelevement_horaires.map((h, index) => (
                <TableRow key={index} sx={{ border: 'none !important', backgroundColor: index % 2 !== 0 ? 'inherit' : redesignColors.dialogBG }}>
                  <TableCell sx={{ p: 1 }}>{format(setDay(new Date(), h.day), 'EEEE', { locale: localeMap(locale) })}</TableCell>
                  <TableCell sx={{ p: 1 }}>{`${waitFormatHours(h.starthours)} - ${waitFormatHours(h.endhours)}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={isMobileView ? 12 : 3}>
          <Typography variant='subtitle1' fontWeight='700' sx={{ pb: 0.5 }}>
            {t('centers.details.specialities.title')}
          </Typography>
          {centerInfo.services.map((service) => (
            <Typography key={service.id}>{service.attributes.name}</Typography>
          ))}
        </Grid>
        {(centerInfo.center.attributes.field_prelevement_handicape || centerInfo.center.attributes.field_prelevement_parking) && (
          <Grid item xs={isMobileView ? 12 : 3}>
            <Typography variant='subtitle1' fontWeight='700' sx={{ pb: 0.5 }}>
              {t('centers.details.services.title')}
            </Typography>
            {centerInfo.center.attributes.field_prelevement_handicape && <Typography>{t('centers.details.services.handicape')}</Typography>}
            {centerInfo.center.attributes.field_prelevement_parking && <Typography>{t('centers.details.services.parking')}</Typography>}
          </Grid>
        )}
        {centerInfo.center.attributes.field_prelevement_info_important !== null && (
          <Grid item xs={isMobileView ? 12 : 3}>
            <Typography variant='subtitle1' fontWeight='700' sx={{ pb: 0.5 }}>
              {t('centers.details.informations.title')}
            </Typography>
            <Typography sx={{ whiteSpace: 'pre-line' }}>{centerInfo.center.attributes.field_prelevement_info_important}</Typography>
          </Grid>
        )}
      </Grid>
      <ConfirmDialog
        title={t('centers.remove_favorite_dialog.title')}
        subtitle={t('centers.remove_favorite_dialog.subtitle', { value: centerInfo.center.attributes.title })}
        submitTitle={t('common.action.remove')}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={() => toggleFavorite && toggleFavorite(centerInfo.center.id)}
      />
    </Grid>
  );
};

export default CenterDetails;
