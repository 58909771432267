import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

interface RadioLabelProps {
  label: string;
  description: string;
}

const RadioLabel: FC<RadioLabelProps> = ({ label, description }) => {
  return (
    <Stack spacing={0} sx={{ mt: 1 }}>
      <Typography component='span' variant='h6'>
        {label}
      </Typography>
      <Typography component='span' variant='body2'>
        {description}
      </Typography>
    </Stack>
  );
};

export default RadioLabel;
