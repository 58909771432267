import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, MenuItem, SvgIcon, TextField, Typography } from '@mui/material';
import api from 'api';
import { useIsMobileView, useNotification } from 'hooks';
import { ContactRequest } from 'models/contact-request.model';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EmojiRating from './EmojiRating';
import { redesignColors } from 'resources/theme/theme.colors';
import ConfirmDialog from './ConfirmDialog';

type Props = {
  open: boolean;
  setOpen: (bool: boolean) => any;
};

const FeedbackDialog: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const { notification } = useNotification();

  const [openDialog, setOpenDialog] = useState(false);
  const [readPrivacy, setReadPrivacy] = useState(false);
  const { register, handleSubmit, formState, reset } = useForm<ContactRequest>({
    mode: 'onChange',
  });

  const handleClose = useCallback(() => {
    setOpen(false);
    reset({ subject: '', message: '' });
  }, [reset, setOpen]);

  const onSubmit: SubmitHandler<ContactRequest> = useCallback(
    (formData) => {
      const requestData: ContactRequest = {
        subject: formData.subject,
        message: formData.message,
      };
      api.contact
        .postContact(requestData)
        .then(() => {
          handleClose();
          notification(t('contact.notifications.success'), 'success');
        })
        .catch((error) => {
          notification(t('contact.notifications.error'), 'error');
        });
    },
    [handleClose, notification, t],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { xs: '85vw', md: '70vw', lg: '848px' },
          maxWidth: 'fit-content',
          borderRadius: '28px',
        },
      }}
    >
      <DialogTitle sx={{ position: 'relative' }}>
        <Box component='h2' sx={{ fontSize: '24px', fontWeight: '500', m: 0 }}>
          {t('feedback.title')}
        </Box>
        <Box sx={{ fontSize: '16px', fontWeight: '400', color: redesignColors.grey.grey1, mb: 1 }}>{t('feedback.description')}</Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'grid', gridTemplateRows: 'auto', gap: 2 }}>
            <Box sx={{ fontSize: '16px', fontWeight: '600', color: redesignColors.blue.dark, textAlign: 'center' }}>{t('feedback.rating_title')}</Box>

            <EmojiRating />
            <FormControl fullWidth>
              <TextField select {...register('subject', { required: true })} defaultValue={undefined} variant='filled' label={t('feedback.form.category')}>
                <MenuItem value='Results'>Results</MenuItem>
                <MenuItem value='Covid'>Covid</MenuItem>
                <MenuItem value='billing'>billing</MenuItem>
              </TextField>
            </FormControl>
            <FormControl fullWidth>
              <TextField select {...register('subject', { required: true })} defaultValue={undefined} variant='filled' label={t('feedback.form.visit')}>
                <MenuItem value='Results'>Results</MenuItem>
                <MenuItem value='Covid'>Covid</MenuItem>
                <MenuItem value='billing'>billing</MenuItem>
              </TextField>
            </FormControl>
            <TextField
              variant='filled'
              multiline
              {...register('message', { required: true })}
              defaultValue={undefined}
              label={t('feedback.form.experience')}
              fullWidth
              sx={{
                '&>.MuiFilledInput-root': {
                  height: '100%',
                  '&>.MuiFilledInput-input': {
                    height: '100% !important',
                    overflow: 'auto !important',
                  },
                },
              }}
            />
            <FormControlLabel
              control={<Checkbox value={readPrivacy} onChange={(_, checked) => setReadPrivacy(checked)} sx={{ mx: 1 }} />}
              label={<Typography display='flex'>{t('feedback.form.contact_me')}</Typography>}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton variant='text' fullWidth={isMobileView} loading={formState.isSubmitting} sx={{ mr: 3 }}>
                {t('feedback.form.cancel')}
              </LoadingButton>
              <LoadingButton variant='contained' fullWidth={isMobileView} onClick={() => setOpenDialog(true)} loading={formState.isSubmitting}>
                {t('feedback.form.submit')}
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} />
    </Dialog>
  );
};
export default FeedbackDialog;
