import { ButtonBase, Chip, Grid, SvgIcon, Typography } from '@mui/material';
import { useContextRedirection } from 'hooks';
import { FC, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconNext } from 'resources/icons/next.svg';
import RoutePaths from 'utils/RoutePaths';

const InfoBox: FC<{
  num: number;
  icon: FC<SVGProps<any>>;
  text: string;
  color: string;
  unreadFolders?: boolean;
  to: keyof typeof RoutePaths;
  search?: object;
}> = ({ num, icon, text, color, unreadFolders, to, search }) => {
  const { t } = useTranslation();
  const navigate = useContextRedirection();

  const handleClick = () => {
    //@ts-ignore
    navigate(RoutePaths[to], search);
  };

  return (
    <Grid item xs={12} md={4} sx={{ alignItems: 'stretch' }}>
      <ButtonBase onClick={handleClick} sx={{ width: '100%', height: '100%' }}>
        <Grid
          padding={2}
          sx={{
            backgroundColor: color,
            width: '100%',
            height: '100%',
            borderRadius: 4,
          }}
          display='flex'
          alignItems='center'
        >
          <Grid container>
            <Grid item xs={2} />
            <Grid item xs={8}>
              <Grid sx={{ width: 'fit-content' }} marginX='auto'>
                <SvgIcon sx={{ fontSize: 24 }} component={icon} />
                <Typography display='inline-block' variant='h1'>
                  {num}
                </Typography>
                <Typography textTransform='uppercase' dangerouslySetInnerHTML={{ __html: text }} />
              </Grid>

              {unreadFolders && <Chip variant='filled' sx={{ backgroundColor: '#FFFFFF50' }} label={`${t('homepage_doctor.results_graph.folder')} ${t('homepage_doctor.results_graph.unread')}`} />}
            </Grid>
            <Grid item xs={2} alignSelf='center'>
              <SvgIcon sx={{ fontSize: 16 }} component={IconNext} />
            </Grid>
          </Grid>
        </Grid>
      </ButtonBase>
    </Grid>
  );
};

export default InfoBox;
