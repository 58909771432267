import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { redesignColors } from 'resources/theme/theme.colors';

export type LoginDisplayStatus = 'SELECT' | 'CLASSIC' | 'TOKEN';

const BottomCard: FC = () => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        height: '100%',
        width: '103%',
        boxSizing: 'border-box',
        background: 'transparent',
        opacity: 1,
        overflow: 'auto',
        overflowY: 'auto',
        maxHeight: '100%',
        boxShadow: 'none',
      }}
    >
      <CardContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Grid item>
          <Typography variant='h1'>{t('landing_page.login.service.title')}</Typography>
        </Grid>
      </CardContent>
      <CardContent sx={{ margin: '2%' }}>
        <Grid item>
          <Typography variant='body1' color={redesignColors.grey.grey1} dangerouslySetInnerHTML={{ __html: t('landing_page.login.service.body') }} />
        </Grid>
      </CardContent>
      <CardContent sx={{ margin: '2%' }}>
        <Grid item>
          <ContextLink to='BNL_ENTERPRISES' external>
            <Button variant='contained' sx={{ width: 'fit-content', font: 'bold' }}>
              {t('landing_page.login.service.knowMore')}
            </Button>
          </ContextLink>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default BottomCard;
