import { Popover, Stack, Button } from "@mui/material";
import { MonthCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { FC } from "react";
import DateField from "./DateField";
import { useTranslation } from "react-i18next";

interface DatePopoverProps {
   anchorEl: HTMLInputElement | null;
   handleClose: () => void;
   setPickedMonth: (month: number) => void;
   setPickedYear: (year: number) => void;
   setPickedDay: (day: number) => void;
   pickedYear: number;
   pickedMonth: number;
   pickedMonthString: string;
}

const DatePopover: FC<DatePopoverProps> = ({anchorEl, handleClose, setPickedMonth, setPickedYear, setPickedDay, pickedMonth, pickedMonthString, pickedYear}) => {
   const {t} = useTranslation();
   return (
      <Popover 
            open={anchorEl ? true : false} 
            id="date-picker-popover" 
            anchorEl={anchorEl} 
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'left',
            }}
            slotProps={{
               paper: {
                  sx: {
                     borderRadius: '28px'
                  }
               }
            }}
            sx={{
               '.Mui-selected': {
                  backgroundColor: 'black',
                  color: 'white',
               },
            }}
         >
            <Stack sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '8px 20px', backgroundColor: '#E9F0F2'}} spacing={3}>
               <DateField handleClick={() => {}} setPickedYear={setPickedYear} showMonth={false} fullWidth variant='standard' pickedMonth={pickedMonth} pickedMonthString={pickedMonthString} pickedYear={pickedYear} />
               <MonthCalendar 
                  sx={{
                     padding: 0, 
                     [`.MuiPickersMonth-monthButton`]: { width: '100%', '&.Mui-selected': {backgroundColor: 'black', color: 'white'} }
                  }} 
                  disablePast 
                  value={dayjs().set('month', pickedMonth).set('year', pickedYear).toDate()} 
                  onChange={(val) => {
                     if (val) {
                        setPickedMonth(dayjs(val as unknown as string).month())
                        setPickedDay(-1);
                     }}
                  } 
               />
               <Stack sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '10px'}}>
                  <Button sx={{color: '#0F6F99', fontWeight: 600}} variant='link' onClick={handleClose}>
                     {t('checkin.stepper.steps.services.calendar.popover.button.close')}
                  </Button>
               </Stack>
            </Stack>
         </Popover>
   )
};

export default DatePopover;