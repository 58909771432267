import { Box, Divider } from '@mui/material';
import { FC } from 'react';
import { redesignColors } from 'resources/theme/theme.colors';

const CustomDivider: FC = () => {
  return (
    <Box
      sx={{
        padding: (theme) => `${theme.spacing(0, 3)}`,
      }}
      alignSelf='center'
    >
      <Divider
        orientation='vertical'
        sx={{
          height: '24px',
          border: (theme) => `1px solid ${redesignColors.grey.grey3}`,
        }}
      />
    </Box>
  );
};

export default CustomDivider;
