import { Typography } from "@mui/material";
import { FC } from "react";

interface SpecialtiesProps {
   specialties: string[];
}

const Specialties: FC<SpecialtiesProps> = ({specialties}) => {
   return specialties.map((spec) => (
      <Typography>{spec}</Typography>
   ));
};

export default Specialties;