import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import CustomCalendarHeader from './custom-calendar-header';
import { FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type CustomDatePickerProps = {
  name?: string;
  required?: boolean;
  error?: boolean;
  fullWidth?: boolean;
} & DatePickerProps<Date>;

const DatePickerTextField: FC<TextFieldProps> = (props) => {
  return <TextField {...props} variant='filled' />;
};
const CustomDatePicker: FC<CustomDatePickerProps> = ({ name, required, error, fullWidth, ...props }) => {
  return (
    <DatePicker
      {...props}
      slots={{
        ...props.slots,
        textField: DatePickerTextField,
        calendarHeader: CustomCalendarHeader,
      }}
      slotProps={{
        // @see#22 Allow customization of slotProps and slotProps.textField
        ...props?.slotProps,
        textField: {
          ...props?.slotProps?.textField,
          name,
          required,
          error,
          fullWidth,
        },
        previousIconButton: {
          size: 'medium',
        },
        nextIconButton: {
          size: 'medium',
        },
        leftArrowIcon: {
          fontSize: 'medium',
        },
        rightArrowIcon: {
          fontSize: 'medium',
        },
        popper: {
          sx: {
            '& .MuiDayCalendar-weekDayLabel': {
              fontWeight: '500',
              color: '#212121',
            },
            '& .MuiPickersDay-today': {
              borderWidth: '2px',
            },
            '& .Mui-selected': {
              backgroundColor: '#46BFF5 !important',
            },
          },
        },
      }}
      dayOfWeekFormatter={(day) => `${day[0].toUpperCase()}${day[1].toLowerCase()}`}
    />
  );
};

export default CustomDatePicker;
