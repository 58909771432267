import { FC, useMemo } from 'react';
import { Card } from '@mui/material';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import TableContentSkeleton from 'components/table-content-skeleton';
import DesktopResultsTable from './DesktopResultsTable';
import DesktopResultsTableNew from './DesktopResultsTableNew';
import { ResultStatus, resultTaggedEl } from 'models/result.model';

const DesktopResults: FC<{
  results: any[];
  hideRedesign: boolean;
  selectedStatus: ResultStatus;
  title: string | JSX.Element;
  pagination: GenericPaginator;
  isLoading: boolean;
  onPaginationChange: (pagination: GenericPaginator) => void;
}> = ({ results, selectedStatus, pagination, onPaginationChange, isLoading, hideRedesign }) => {
  const tableHeaders: GenericHeader[] = useMemo(
    () =>
      hideRedesign
        ? [
            {
              id: 'prescription_date',
              label: 'common.table.tableHead.prescription_date',
            },
            { id: 'sampleDate', label: 'common.table.tableHead.sample_date' },
            { id: 'fileReference', label: 'common.table.tableHead.reference_number' },
            { id: 'patient', label: 'common.table.tableHead.patient' },
            { id: 'dateOfBirth', label: 'common.table.tableHead.birth_date' },
            { id: 'cns', label: 'common.table.tableHead.cns_number' },
            { id: 'issuer', label: 'common.table.tableHead.issuer' },
            { id: 'lastUpdate', label: 'results.list.table.last_update' },
            { id: 'status', label: 'results.list.table.status' },
            { id: 'actions', label: undefined },
          ]
        : [
            { id: 'sampleDate', label: 'common.table.tableHead.sample_date' },
            { id: 'fileReference', label: 'common.table.tableHead.reference_number' },
            { id: 'patient', label: 'common.table.tableHead.patient' },
            { id: 'issuer', label: 'common.table.tableHead.issuer' },
            ...(selectedStatus === ResultStatus.partial ? [{ id: 'lastUpdate', label: 'results.list.table.last_update' }] : []),
            { id: 'status', label: 'results.list.table.status' },
            { id: 'tag', label: 'results.list.table.tag' },
            { id: 'actions', label: undefined },
          ],
    [hideRedesign, selectedStatus],
  );

  return (
    <Card sx={{ padding: 0 }}>
      <GenericTable headers={tableHeaders} pagination={pagination} setPagination={onPaginationChange}>
        {!isLoading && results ? (
          results.length ? (
            results.map((result: resultTaggedEl, key: number) =>
              hideRedesign ? <DesktopResultsTable result={result as any} key={key} /> : <DesktopResultsTableNew result={result as any} key={key} />,
            )
          ) : (
            <NoResultsTableRow colSpan={tableHeaders.length} noResultText={'common.noResult'} />
          )
        ) : (
          <TableContentSkeleton columns={tableHeaders.length} rows={4} />
        )}
      </GenericTable>
    </Card>
  );
};

export default DesktopResults;
