import { CategoryScale, Chart as ChartJS, Legend, Title, LinearScale, LineElement, PointElement, TimeScale, Tooltip, Filler } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { ArrayOfDataset, Axis, HistoryData, HistoryValues } from 'models/graph.model';
import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import { redesignColors } from 'resources/theme/theme.colors';
import { colorGenerator } from 'utils';

const hexToRgbA = (hex: string, opacity: number) => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.slice(1).split('');
    if (c.length === 3) c = c.flatMap((char) => [char, char]);
    const rgb = parseInt(c.join(''), 16);
    return `rgba(${(rgb >> 16) & 255}, ${(rgb >> 8) & 255}, ${rgb & 255}, ${opacity})`;
  }
  throw new Error('Invalid HEX color.');
};

const GraphHistory: FC<HistoryValues> = ({ historyDataValues }) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, TimeScale, Filler, Title);

  var analysisData: ArrayOfDataset = [];

  const firstValue = historyDataValues[0];
  const graphValues = firstValue.history.map((history) => +history.value);
  const maxValue = Math.max(...graphValues);
  const minValue = Math.min(...graphValues);

  const baseDataSet = {
    yAxisID: firstValue.reference,
    label: firstValue.name,
    tension: 0,
    data: firstValue.history.map((el, key) => ({
      x: new Date(el.last_report_time),
      y: el.value,
    })),
    backgroundColor: redesignColors.green.common,
    fill: false,
  };
  const yAxes: Axis = {
    [baseDataSet.yAxisID]: {
      display: true,
      position: 'left',
      axis: 'y',
      weight: 0,
      title: {
        display: true,
        text: baseDataSet.label,
        color: baseDataSet.backgroundColor,
      },
    },
  };

  // Get all dates from all results and histories
  var getAllDates: Date[] = [];
  analysisData.forEach((analysis) => {
    analysis.data.map((e) => getAllDates.push(e.x));
  });

  // Remove all duplicates date to get min and max dates
  // TODO: Typer la variable
  let unduplicateDates: any[] = getAllDates.filter((c, index) => {
    return getAllDates.indexOf(c) === index;
  });

  // Min and max dates without changing anything
  var maxDate = new Date(Math.max.apply(null, unduplicateDates));
  var minDate = new Date(Math.min.apply(null, unduplicateDates));

  return (
    <Line
      options={{
        plugins: { legend: { display: false } },
        scales: {
          xAxis: {
            type: 'time',
            time: {
              unit: 'year',
              displayFormats: {
                month: 'yyyy',
              },
            },
            // Set the min and max (time) on the graph
            min: minDate.setMonth(minDate.getMonth() - 1),
            max: maxDate.setMonth(maxDate.getMonth() + 1),
          },
          ...yAxes,
        },
      }}
      data={{
        datasets: [
          baseDataSet,
          {
            label: 'Maximum',
            data: firstValue.history.map((el, key) => ({
              x: new Date(el.last_report_time),
              y: maxValue.toString(),
            })),
            borderColor: redesignColors.grey.grey4,
            fill: {
              target: 'origin',
              above: 'rgba(46, 229, 129, 0.1)',
            },
          },
          {
            label: 'Minimum',
            tension: 0,
            data: firstValue.history.map((el, key) => ({
              x: new Date(el.last_report_time),
              y: minValue.toString(),
            })),
            borderColor: redesignColors.grey.grey4,
            fill: 'origin',
          },
        ],
      }}
    />
  );
};

export default GraphHistory;
