import { Card, CardContent, Grid, Step, StepLabel, Stepper } from '@mui/material';
import api from 'api';
import ConnectAccountDialog from 'components/connect-account-dialog';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useAuthenticated, useContextRedirection, useCurrentUser, useCurrentUserRelatives, useIsDoctor, useIsMobileView, useNotification } from 'hooks';
import { AppointmentMedicalAct2023 } from 'models/appointment.medical.act.model';
import { Appointment, AppointmentPatient } from 'models/appointment.model';
import { Patient } from 'models/patients.model';
import { Prescription } from 'models/prescription.model';
import { Gender } from 'models/profile.model';
import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { canMakeAppointmentForPatient } from 'utils/func/Appointment.func';
import { convertDate } from 'utils/func/Date.func';
import RoutePaths from 'utils/RoutePaths';
import BookingPatientsStep from './components/Booking.patients.step';
import BookingPlaceStep from './components/Booking.place.step';
import BookingReasonsStep from './components/Booking.reasons.step';
import BookingScheduleStep from './components/Booking.schedule.step';
import ValidationStep from './components/Validation.step';

export type Props = {
  selectablePersons: SelectablePerson[];
  handleNext: () => void;
  handleState: (state: any, field: string) => void;
  state: Appointment;
  isDoctor: boolean;
  handleBack: () => void;
  medicalActs: AppointmentMedicalAct2023[];
};

export type SelectablePerson = AppointmentPatient & {
  id: number;
  name: string;
};

const AppointmentPage: FC<{
  hasComponent?: boolean;
  activeStepNoComponent?: number;
  setActiveStepNoComponent?: React.Dispatch<React.SetStateAction<number>>;
  setDataNoComponent?: any;
  offline?: boolean;
}> = ({ hasComponent, activeStepNoComponent = 0, setActiveStepNoComponent = () => {}, offline = false }) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const relatives = useCurrentUserRelatives();
  const isDoctor = useIsDoctor();
  const isAuthenticated = useAuthenticated();
  const { id: patientId, prescId } = useParams();
  const [patientDetails, setPatientDetails] = useState<Patient>();
  const [selectablePersons, setSelectablePersons] = useState<SelectablePerson[]>([]);
  const isMobileView = useIsMobileView();
  const navigate = useContextRedirection();
  const [activeStep, setActiveStep] = useState(0);
  const [medicalActs, setMedicalActs] = useState<AppointmentMedicalAct2023[]>([]);
  const [prescription, setPrescription] = useState<Prescription>();
  const { notification } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<Appointment>({
    creator: undefined,
    comment: '',
    slot: '',
    address: {
      type: isAuthenticated ? 'home' : 'other',
      number: '',
      street: '',
      complement: '',
      city_name: '',
      company: '',
      zip_code: '',
      country_iso: '',
    },
    patients: [],
    prescription_id: undefined,
  });

  useEffect(() => {
    if (prescId) {
      api.prescriptions
        .getPrescriptionByBasketId(prescId)
        .then((response) => {
          setPrescription(response);
        })
        .catch(() => {
          notification(t('prescriptions_making.step2.yourSelection.notification.errorWhenLoadingPrescription'), 'error');
          navigate(RoutePaths['NOT_FOUND']);
        });
    }
  }, [prescId, notification, t, navigate]);

  const handleState = useCallback((toAdd: any, field: string) => {
    setState((prevstate) => ({
      ...prevstate,
      [field]: toAdd,
    }));
  }, []);

  const handleNext = useCallback(() => {
    let stepToUse: number, setStepToUse: (value: React.SetStateAction<number>) => void;
    if (hasComponent) {
      stepToUse = activeStep;
      setStepToUse = setActiveStep;
    } else {
      stepToUse = activeStepNoComponent;
      setStepToUse = setActiveStepNoComponent;
    }
    setCompleted((completed) => {
      const c = [...completed];
      c[stepToUse] = true;
      return c;
    });
    setStepToUse((activeStep) => (activeStep >= steps.length - 1 ? activeStep : activeStep + 1));
    // eslint-disable-next-line
  }, [activeStep, activeStepNoComponent, hasComponent, setActiveStep, setActiveStepNoComponent]);

  const handleBack = useCallback(() => {
    if (isDoctor && activeStep === 0) navigate(`/patients/${patientDetails?.id}`);
    else if (hasComponent) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setActiveStepNoComponent((prevActiveStep: number) => prevActiveStep - 1);
    }
    setCompleted((completed) => {
      const c = [...completed];
      c[activeStep - 1] = false;
      return c;
    });
  }, [hasComponent, setActiveStepNoComponent, activeStep, isDoctor, patientDetails?.id, navigate]);

  const onSubmit = useCallback(
    (data: Appointment) => {
      setLoading(true);
      const creator = isAuthenticated
        ? {
            last_name: currentUser?.last_name !== '' ? currentUser?.last_name : currentUser?.birth_name,
            first_name: currentUser?.first_name,
            phone: currentUser?.phone,
            mobile_phone: currentUser?.mobile,
            email: currentUser?.email,
          }
        : {
            last_name: data.patients[0]?.last_name !== '' ? data.patients[0]?.last_name : data.patients[0]?.birth_name,
            first_name: data.patients[0]?.first_name,
            phone: data.patients[0]?.phone,
            mobile_phone: data.patients[0]?.mobile_phone,
            email: data.patients[0]?.email,
          };

      data.patients = data.patients.map((patient) => ({
        ...patient,
        gender: patient.gender === Gender.MALE || patient.gender === Gender.MALE_V4 ? Gender.MALE_FULL : Gender.FEMALE_FULL,
        birth_date: convertDate(patient.birth_date, false, 'yyyy-MM-dd'),
        last_name: patient.last_name ? patient.last_name : patient.birth_name,
        birth_name: patient.birth_name ? patient.birth_name : patient.last_name,
        prescriptions: patient.prescriptions.map((prescription) => prescription.file_content),
      }));
      data.creator = creator;
      data.slot = convertDate(data.slot, false, 'yyyy-MM-dd hh:mm');
      if (data.address.type === 'other') {
        data.address.type = 'home';
      }

      api.appointments
        .bookAppointment2023(data)
        .then(() => {
          notification(t('appointment.book.notification.success'), 'success');
          if (isDoctor) {
            navigate(RoutePaths['HOME']);
          } else {
            navigate(RoutePaths['APPOINTMENT']);
          }
        })
        .catch(() => {
          notification(t('appointment.book.notification.error'), 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [isDoctor, navigate, notification, t, currentUser, isAuthenticated],
  );

  const steps: { title: string; component: JSX.Element }[] = useMemo(() => {
    const props = {
      state,
      handleState,
      handleNext,
      isDoctor,
      handleBack,
      medicalActs,
      patientHomeAddress: isDoctor ? patientDetails?.addresses.home : currentUser?.addresses.home,
      patientWorkAddress: isDoctor ? patientDetails?.addresses.work : currentUser?.addresses.work,
      selectablePersons,
    };
    const stepper = [
      {
        title: t('appointment.book.steps_labels.patients'),
        component: <BookingPatientsStep {...props} />,
      },
      {
        title: t('appointment.book.steps_labels.reasons'),
        component: <BookingReasonsStep {...props} />,
      },
      {
        title: t('appointment.book.steps_labels.place'),
        component: <BookingPlaceStep {...props} />,
      },
      {
        title: t('appointment.book.steps_labels.schedule'),
        component: <BookingScheduleStep {...props} />,
      },
      {
        title: t('appointment.book.steps_labels.validation'),
        component: <ValidationStep {...props} onSubmit={onSubmit} loading={loading} />,
      },
    ];
    return isDoctor || !hasComponent ? stepper.slice(1) : stepper;
  }, [
    state,
    handleState,
    handleBack,
    onSubmit,
    handleNext,
    medicalActs,
    isDoctor,
    patientDetails?.addresses.home,
    patientDetails?.addresses.work,
    currentUser?.addresses.home,
    currentUser?.addresses.work,
    selectablePersons,
    t,
    hasComponent,
    loading,
  ]);

  const [completed, setCompleted] = useState<boolean[]>(new Array(steps.length).fill(false));
  useEffect(() => {
    if (isDoctor && patientId) {
      api.patients
        .getPatientDetails(parseInt(patientId))
        .then((patient) => {
          if (!canMakeAppointmentForPatient(patient)) {
            notification(t('appointment.tooltip.incompleteProfil'), 'warning');
            navigate(RoutePaths['DOCTOR_PATIENTS_DETAILS'](patientId));
          }
          setPatientDetails(patient);
          const _patient = {
            patient_id: patient.id,
            cns: patient.cns,
            birth_date: patient.birth_date,
            birth_name: patient.birth_name,
            gender: patient.gender === Gender.MALE || patient.gender === Gender.MALE_V4 ? Gender.MALE_FULL : Gender.FEMALE_FULL,
            last_name: patient.last_name,
            first_name: patient.first_name,
            phone: patient.phone,
            mobile_phone: patient.mobile_phone,
            email: patient.email,
          };
          setState((prev) => ({
            ...prev,
            prescription_id: prescription?.id,
            patients: [
              {
                ..._patient,
                patient_id: `${_patient.patient_id}`,
                fasting: prescription?.fasting ?? false,
                medical_acts: [],
                prescriptions: [],
                comment: undefined,
              },
            ],
          }));
        })
        .catch(() => notification(t('appointment.book.steps.patients.notification.error'), 'error'));
    }
    api.appointments
      .getMedicalActs2023()
      .then((res) => {
        setMedicalActs(res);
      })
      .catch(() => notification(t('appointment.book.steps.reasons.medical_acts.notification.error'), 'error'));
  }, [isDoctor, notification, patientId, currentUser, t, navigate, hasComponent, offline, handleState, prescription]);

  useEffect(() => {
    let list: SelectablePerson[] = [];
    if (currentUser !== undefined) {
      list = relatives
        ? relatives.map((relative) => ({
            name: `${relative.last_name} ${relative.first_name}`,
            id: relative.id,
            patient_id: `${relative.id}`,
            cns: relative.cns,
            birth_date: relative.birth_date ?? undefined,
            birth_name: relative.birth_name,
            gender: relative.gender === Gender.MALE || relative.gender === Gender.MALE_V4 ? Gender.MALE_FULL : Gender.FEMALE_FULL,
            last_name: relative.last_name,
            first_name: relative.first_name,
            phone: relative.phone ?? undefined,
            mobile_phone: relative.mobile_phone ?? undefined,
            email: relative.email ?? undefined,
            fasting: false,
            medical_acts: [],
            prescriptions: [],
            comment: undefined,
          }))
        : [];
    }
    setSelectablePersons(list);
  }, [relatives, currentUser]);

  return hasComponent ? (
    <BodyTemplate title={t('homepage.appointment.title')} backButton>
      <Grid container justifyContent='center'>
        {activeStep === 0 && !isAuthenticated && <ConnectAccountDialog redirect={RoutePaths['BOOK_APPOINTMENT']} />}
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step, index) => (
              <Step key={index} completed={completed[index]}>
                <StepLabel color='inherit'>{isMobileView ? '' : step.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Card sx={{ mt: 2, width: 'auto' }}>
            {((selectablePersons.length > 0 && medicalActs.length > 0 && (isDoctor ? state.patients.length > 0 : true)) || !isAuthenticated) && (
              <CardContent>{steps[activeStep].component}</CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </BodyTemplate>
  ) : (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Stepper activeStep={activeStepNoComponent} alternativeLabel>
              {steps.map((step, index) => (
                <Step key={index} completed={completed[index]}>
                  <StepLabel color='inherit'>{isMobileView ? '' : step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            {((selectablePersons.length > 0 && medicalActs.length > 0 && (isDoctor ? state.patients.length > 0 : true)) || !isAuthenticated) && (
              <CardContent>{steps[activeStep].component}</CardContent>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentPage;
