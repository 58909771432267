import { Fragment, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SvgIcon } from '@mui/material';
import { ReactComponent as LocationSvg } from 'resources/icons/my-location.svg';
import { useGeolocation } from 'hooks/useGeolocation';

const AllowLocationDialog = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { handleGeolocation, hasLocation } = useGeolocation();

  const handleClickShowDialog = () => setShowDialog(true);
  const handleClose = () => setShowDialog(false);

  const handleCloseAfterConfirm = () => {
    handleGeolocation();
    handleClose();
  };

  // TODO: Get city from position
  return (
    <Fragment>
      {!hasLocation && (
        <Fragment>
          <Button variant={'text'} onClick={handleClickShowDialog} startIcon={<SvgIcon component={LocationSvg} />} sx={{ border: 'none' }}>
            Determine location
          </Button>
          {showDialog && (
            <Dialog 
              open={showDialog} 
              onClose={handleClose} 
              PaperProps={{ 
                sx: { 
                  width: '440px', 
                  minHeight: '208px', 
                  borderRadius: '28px', 
                  p: 2,
                }
              }}
            >
              <DialogTitle id='allow-location-title' marginBottom={1}>{'Allow MYLAB to use your location?'}</DialogTitle>
              <DialogContent>
                <DialogContentText id='allow-location-description'>MYLAB needs to access your location.</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant={'text'} onClick={handleClose} sx={{ marginRight: '32px' }}>
                  Not now
                </Button>
                <Button variant={'text'} onClick={handleCloseAfterConfirm} startIcon={<SvgIcon component={LocationSvg} />} autoFocus>
                  Determine location
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default AllowLocationDialog;
