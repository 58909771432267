import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, ReactElement } from 'react';
import { redesignColors } from 'resources/theme/theme.colors';

const ResultAccordionSummary: FC<{
  title: string;
  children: ReactElement;
  nbResults: number;
}> = ({ title, children, nbResults }) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded={true} sx={{ marginBottom: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          '&[aria-expanded="true"]': { background: redesignColors.blue.main },
          '&[aria-expanded="false"]': { borderBottom: `2px solid ${redesignColors.blue.main}` },
          transition: '0.3s all',
          px: 2,
          borderRadius: '8px 8px 0 0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h3'>{title}</Typography>
          <Typography variant='subtitle1'>
            {t('results.details.accordionResultLabel', {
              count: nbResults,
            })}
          </Typography>
        </Box>
      </AccordionSummary>
      {children}
    </Accordion>
  );
};

export default ResultAccordionSummary;
