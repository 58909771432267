import { Box, Card, CircularProgress, Grid, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useTranslation } from 'react-i18next';
import { Patient } from 'models/patients.model';
import { Selection } from 'models/record.model';
import { Result, ResultGraph, ResultHistory, ResultObject } from 'models/result.model';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LastResultCard from './LastResultCard';
import InformationBlock from './InformationBlock';
import GraphCardContent from './GraphCardContent';

interface CustomizedState {
  patient: Patient;
  selection: Selection[];
}

const ResultInfo: FC = () => {
  const location = useLocation();
  const { patient, selection } = location.state as CustomizedState;
  const referencesArray: string[] = useMemo(() => [], []);
  const [historyDataValues, setHistoryDataValues] = useState<ResultHistory[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  const { t } = useTranslation();
  const historyDataValuesArray: ResultGraph[] = [];

  useEffect(() => {
    setLoading(true);

    selection.map((el: { ref: string; name: string }) => referencesArray.push(el.ref));
    api.results
      .getHistoryFormResults(patient.id, referencesArray)
      .then((response: ResultHistory[]) => {
        setHistoryDataValues(response);
      })
      .finally(() => setLoading(false));
  }, [patient.id, referencesArray, selection]);

  historyDataValues.forEach((el: ResultObject, key: number) => {
    const historyArray: Result[] = [];
    Object.keys(el.history).forEach((el2, key2) => {
      historyArray.push(el.history[el2]);
    });
    if (!historyArray.length) {
      return;
    }
    historyDataValuesArray.push({
      name: historyArray[0].name,
      reference: el.reference,
      history: historyArray.filter((value) => value.sampling_time !== null),
    });
  });

  return (
    <BodyTemplate title={historyDataValuesArray[0] && historyDataValuesArray[0]?.name} backButton>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          {!!historyDataValuesArray?.length && (
            <Grid item xs={12}>
              <LastResultCard result={historyDataValuesArray[0].history[0]} />
            </Grid>
          )}

          <Grid item xs={12}>
            <InformationBlock />
          </Grid>
          {!!historyDataValuesArray?.length && (
            <Grid item xs={12}>
              <Card sx={{ width: { xs: '100%', md: '50%' } }}>
                <Typography variant='h2' mb={2} fontWeight='500'>
                  {t('result.doctor.info.history')}
                </Typography>
                <GraphCardContent graph={historyDataValuesArray[0]} smallView />
              </Card>
            </Grid>
          )}
        </Grid>
      )}
    </BodyTemplate>
  );
};

export default ResultInfo;
