import { Button, InputAdornment, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { Lock } from '@mui/icons-material';
import api from 'api';
import Nope from 'nope-validator';
import { useContextRedirection, useNotification } from 'hooks';
import { ForgotPasswordRequest } from 'models/user.model';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RoutePaths from 'utils/RoutePaths';
import { nopeResolver } from '@hookform/resolvers/nope';
import { useParams } from 'react-router-dom';
import { redesignColors } from 'resources/theme/theme.colors';

const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const { notification } = useNotification();
  const navigate = useContextRedirection();
  const params = useParams();

  const ResetSchema = Nope.object().shape({
    plainPassword: Nope.object().shape({
      first: Nope.string().required(),
      second: Nope.string()
        .oneOf([Nope.ref('first')], t('security.validation.password_match'))
        .required(),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({ resolver: nopeResolver(ResetSchema) });

  const onSubmit: SubmitHandler<ForgotPasswordRequest> = useCallback(
    (data) => {
      setSubmitting(true);

      api.authentication
        .resetPassword(data, params.resetToken)
        .then(() => {
          notification(t('landing_page.resetPwd.notification.success'), 'success');
          navigate(RoutePaths['LANDING_PAGE']);
        })
        .catch(() => {
          notification(t('landing_page.resetPwd.notification.error'), 'error');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [navigate, notification, t, params],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack
        direction='column'
        spacing={3}
        sx={{
          minWidth: '100%',
          minHeight: '100%',
          justifyContent: 'center',
          marginTop: '5px',
        }}
      >
        <Typography fontWeight='700'>{t('landing_page.resetPwd.header')}</Typography>
        <Typography textAlign='left' color={redesignColors.grey.grey1}>
          {t('landing_page.forgotPwd.header')}
        </Typography>
        <Stack direction='column' spacing={2}>
          <TextField
            variant='filled'
            sx={{
              backgroundColor: '#FFFFFF',
            }}
            {...register('plainPassword.first')}
            name='plainPassword.first'
            type='password'
            label={t('landing_page.resetPwd.password_new')}
            fullWidth
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon component={Lock} sx={{ fontSize: 24 }} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant='filled'
            sx={{
              backgroundColor: '#FFFFFF',
            }}
            {...register('plainPassword.second')}
            name='plainPassword.second'
            type='password'
            label={t('landing_page.resetPwd.password_confirm')}
            fullWidth
            required
            error={errors?.plainPassword !== undefined}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon component={Lock} sx={{ fontSize: 24 }} />
                </InputAdornment>
              ),
            }}
          />
          <Stack direction='column' spacing={1} alignItems='center'>
            <Button type='submit' variant='contained' disabled={isSubmitting} fullWidth>
              {t('common.action.confirm')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default ResetPassword;
