import { ArrowBackIos, ArrowForwardIos, ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Stack, ToggleButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import { AvailableBookingSlots } from "models/available-appointment-slots.model";
import { FC, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";

interface DayProps {
   day: number;
   month: number;
   year: number;
   selectedDayKey: string | null | undefined;
   handleDayPick: (day: number, key: string) => void;
   hasAvailableSlots: boolean;
}

const Day: FC<DayProps> = ({day, month, year, selectedDayKey, handleDayPick, hasAvailableSlots}) => {
   const {t} = useTranslation();
   const [selected, setSelected] = useState(false);
   const days: string[] = t('checkin.stepper.steps.services.calendar.days', {returnObjects: true});

   useEffect(() => {
      if (selectedDayKey === `day-${day}-${month}-${year}`) {
         setSelected(true);
      } else {
         setSelected(false);
      }
   }, [selectedDayKey, day, month, year]);

   const handleChange = (e: MouseEvent<HTMLElement>) => {
      const day = e.currentTarget.getAttribute('value');
      const key = e.currentTarget.getAttribute('aria-label');

      if (day && key) {
         handleDayPick(parseInt(day), key);
      }
   }

   return (
      <ToggleButton 
         aria-label={`day-${day}-${month}-${year}`}
         value={day}
         selected={selected}
         onChange={handleChange}
         sx={(theme) => ({
            height: '100%',
            width: '100%',
            border: '0.5px solid grey',
            backgroundColor: hasAvailableSlots ? 'white' : 'lightgrey',
            '&.Mui-selected': {
               backgroundColor: theme.palette.primary.main,
               '&:hover': {
                  backgroundColor: theme.palette.primary.light,
               },
            },
            '&:hover': {
               backgroundColor: theme.palette.primary.light,
            }
         })}
      >
         <Stack>
            <Typography color="GrayText" variant="body2">
               {days[dayjs(`${year}-${month+1}-${day}`).day()]}
            </Typography>
            <Typography variant="body1">
               {day < 9 ? `0${day+1}` : day+1}/{month < 9 ? `0${month + 1}` : month + 1}
            </Typography>
            <Typography color="GrayText" variant="body2">
               {year} 
            </Typography>
         </Stack>
      </ToggleButton>
   )
};

interface DaySliderProps {
   pickedMonth: number;
   pickedYear: number;
   setPickedDay: (day: number) => void;
   setPickedTime: (time: string) => void;
   availableSlots: AvailableBookingSlots[];
}

const DaySlider: FC<DaySliderProps> = ({pickedMonth, pickedYear, setPickedDay, setPickedTime, availableSlots}) => {
   const [days, setDays] = useState<ReactElement<Day>[]>([]);
   const [selectedDayKey, setSelectedDayKey] = useState<string | null>();

   useEffect(() => {
      const handleDayPick = (day: number, key: string) => {
         setPickedDay(day);
         setSelectedDayKey(key);
         setPickedTime('');
      };

      const daysInMonth = dayjs(`${pickedYear}-${pickedMonth+1}-01`).daysInMonth(); //+1 because dayjs months are 0 indexed

      const monthDays: ReactElement<Day>[] = [];

      for (let i = 0; i < daysInMonth; i++) {
         const dayKey = `day-${i}-${pickedMonth}-${pickedYear}`;

         const hasAvailableSlots = availableSlots.some(slot => {
            if(dayjs(slot.date).isSame(dayjs(`${pickedYear}-${pickedMonth+1}-${i+1}`), 'date')) {
               return slot.slots.length > 0;
            }
         });

         monthDays.push(<Day key={dayKey} selectedDayKey={selectedDayKey} handleDayPick={handleDayPick} day={i} month={pickedMonth} year={pickedYear} hasAvailableSlots={hasAvailableSlots} />)   
      }

      setDays(monthDays);
   }, [setDays, pickedMonth, pickedYear, selectedDayKey, availableSlots]);

   const getWeeks = () => {
      const perChunk = 7 //week 

      return days.reduce((resultArray: ReactElement<Day>[][], item: ReactElement<Day>, index) => { 
         const chunkIndex = Math.floor(index/perChunk)

         if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
         }

         resultArray[chunkIndex].push(item)

         return resultArray
      }, []);
   };

   return (
      <Carousel
         animation="slide"
         navButtonsAlwaysVisible 
         indicators={false} 
         autoPlay={false} 
         sx={{ width: '100%' }}
         fullHeightHover={true}
         navButtonsProps={{
            style: {
               borderRadius: 8,
               height: '100%',
               width: '32px',
               position: 'static',
               backgroundColor: '#E9F0F2',
               color: 'black'
            },
        }}
        navButtonsWrapperProps={{
            style: {
               top: 0,
               bottom: 0,
            }
        }}
        height={73}
      >
         {getWeeks().map((week, index) => (
            <div 
               style={{ 
                  display: 'flex', 
                  width: '100%', 
                  flexDirection: 'row',
                  gap: '10px', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  height: '100%', 
                  paddingLeft: '50px', 
                  paddingRight: '50px' 
               }} 
               key={`week-${index}`}
            >
               {week}
            </div>
         ))}
      </Carousel>
   )
};

export default DaySlider;