import { Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useNotification } from 'hooks';
import { DSPStatus } from 'models/myDSP.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import ListDocumentDSP from './components/ListDocumentDSP';
import LoadingDSP from './components/LoadingDSP';
import NoDSP from './components/NoDSP';

const MyDSP: FC = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const [isLoading, setLoading] = useState(true);
  const [hasDSP, setHasDSP] = useState(false);
  const { notification } = useNotification();

  const checkDSPStatus = useCallback((dspStatus: DSPStatus) => {
    switch (dspStatus.status) {
      case 'created':
      case 'open':
        setHasDSP(true);
        break;
      case 'no_dsp':
      default:
        setHasDSP(false);
        break;
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    currentUser &&
      api.myDSP
        .checkIfDSPExists(currentUser.id)
        .then((response: DSPStatus) => {
          checkDSPStatus(response);
          setLoading(false);
        })
        .catch(() => notification(t('myDSP.notification.error'), 'error'));
  }, [checkDSPStatus, currentUser, notification, t]);

  return (
    <BodyTemplate>
      <Grid container spacing={3} pt='10%'>
        <Grid item xs={12}>
          {!isLoading ? hasDSP ? <ListDocumentDSP /> : <NoDSP /> : <LoadingDSP />}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};
export default MyDSP;
