import { FC } from "react";

interface DailyHoursProps {
   dailyHours: object;
}

const DailyHours: FC<DailyHoursProps> = ({dailyHours}) => {
   return (
      <table style={{ borderSpacing: 0 }}>
         {
            Object.entries(dailyHours).map((entry, i) => (
               <tr key={`tr-${i}`} style={{ backgroundColor: i % 2 === 0 ? '#E9F0F2' : 'white'}}>
                  <td style={{ padding: '8px 16px 8px 16px' }}>{entry[0]}:</td>
                  <td style={{ padding: '8px 16px 8px 16px' }}>{entry[1]}</td>
               </tr>
            ))
         }
      </table>
   );
};

export default DailyHours;