import { createTheme } from '@mui/material';
import theme_color from 'resources/theme/theme.colors';

const theme = createTheme({
  components: {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&:before': {
            backgroundColor: theme_color.palette.divider,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '8px 0',
          '&  p': {
            fontSize: '16px',
            fontWeight: '500',
          },
          '& .MuiSvgIcon-root': {
            color: theme_color.palette.text.primary,
            fontSize: '24px',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0',
          marginTop: '16px',
          marginBottom: '16px',
          '&  p': {
            fontSize: '14px',
          },
        },
      },
    },
  },
});

export default theme;
