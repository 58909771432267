import { Avatar, Card, CardContent, TableCell, TableRow, Typography } from '@mui/material';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import NoResultsTableRow from 'components/no-results-table-row';
import { Directory } from 'models/directory.model';
import { FC, Fragment, useEffect, useState } from 'react';
import { getSortedResults } from 'utils/func/SortedResults.func';
import FindDoctorResults from './FindDoctorResults';

interface FindResultsProps {
  directoriesWrapper: Directory[];
  pagination: GenericPaginator;
  onPaginationChange: (pagination: GenericPaginator) => void;
}

const FindResults: FC<FindResultsProps> = ({ directoriesWrapper, pagination, onPaginationChange }) => {
  const [openStates, setOpenStates] = useState<boolean[]>([]);
  const [alphabeticallyArrangedData, setAlphabeticallyArrangedData] = useState<{ title: string; items: Directory[]; indexOffset: number }[]>([]);

  useEffect(() => {
    setOpenStates(Array(directoriesWrapper && directoriesWrapper.length).fill(false));
    if (directoriesWrapper && directoriesWrapper.length > 0) {
      const sortedData = getSortedResults<Directory>(directoriesWrapper, 'identity');
      setAlphabeticallyArrangedData(sortedData);
    } else {
      setAlphabeticallyArrangedData([]);
    }
  }, [directoriesWrapper]);

  return (
    directoriesWrapper && (
      <Card sx={{p: 0}}>
        <CardContent>
          <GenericTable
            noHeaders
            {...(!alphabeticallyArrangedData.length
              ? {
                  noPagination: true,
                }
              : {
                  pagination: pagination,
                  setPagination: onPaginationChange,
                })}
          >
            {!alphabeticallyArrangedData?.length && <NoResultsTableRow colSpan={1} />}
            {alphabeticallyArrangedData.length > 0 &&
              alphabeticallyArrangedData.map((alpha, key) => (
                <Fragment key={key}>
                  <TableRow>
                    <TableCell sx={{p: '24px 32px'}}>
                      <Avatar
                        sx={{
                          backgroundColor: 'primary.main',
                          color: 'primary.contrastText',
                        }}
                      >
                        <Typography variant='h2'>{alpha.title.toUpperCase()}</Typography>
                      </Avatar>
                    </TableCell>
                  </TableRow>
                  {alpha.items.map((directory, index) => (
                    <FindDoctorResults directory={directory} openStates={openStates} handleChangeOpen={setOpenStates} keyOpen={index + alpha.indexOffset} key={index + alpha.indexOffset} />
                  ))}
                </Fragment>
              ))}
          </GenericTable>
        </CardContent>
      </Card>
    )
  );
};

export default FindResults;
