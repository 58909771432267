import { Box, Checkbox, IconButton, SvgIcon, TableCell, TableRow, Typography } from '@mui/material';
import { useIsAdmin, useIsDoctor, useIsLaboratory, useIsMobileView } from 'hooks';
import { Selection } from 'models/record.model';
import { Result } from 'models/result.model';
import { FC, useCallback } from 'react';
import { ReactComponent as RightArowIcon } from 'resources/icons/right_arrow.svg';
import { getBodyValue } from './DetailTabPanel.func';
import ContextLink from 'components/_base/ContextLink';
import { redesignColors } from 'resources/theme/theme.colors';
import { useParams } from 'react-router-dom';
import { Person } from 'models/profile.model';

const DetailTabPanelBody: FC<{
  val: Result;
  props: any;
  patient?: Person;
  hideRedesign?: boolean;
  isHighlight: boolean;
}> = ({ val, props, isHighlight, patient, hideRedesign }) => {
  const { selection, setSelection } = props;
  const { idResult } = useParams();
  const isMobileView = useIsMobileView();
  const isDoctor = useIsDoctor();
  const isAdmin = useIsAdmin();
  const isLaboratory = useIsLaboratory();

  const getFirstCell = useCallback(
    (val: Result) => {
      return (
        <TableCell
          sx={{
            width: '200px',
            paddingLeft: val.tabulated ? (isMobileView ? (theme) => theme.spacing(2) : (theme) => theme.spacing(4)) : '0',
          }}
        >
          <Box display='flex'>
            {(isDoctor || isLaboratory || isAdmin) && hideRedesign && (
              <Checkbox
                sx={{ pl: 2 }}
                checked={selection.findIndex((elm: Selection) => elm.ref === val.analysis_reference) !== -1}
                onClick={() =>
                  setSelection({
                    ref: val.analysis_reference,
                    name: val.name,
                  })
                }
                disabled={val.flag !== 'numeric'}
              />
            )}

            <Box sx={{ pl: 1 }}>
              <Typography variant='body1'>{val.name}</Typography>
              {val.analysis_method && <Typography variant='body2'>{val.analysis_method}</Typography>}
              {val.name_hint && <Typography variant='body2'>{val.name_hint}</Typography>}
            </Box>
          </Box>
        </TableCell>
      );
    },
    [isDoctor, isLaboratory, isAdmin, isMobileView, selection, setSelection],
  );
  const isCommonView = ['comment', 'graph', 'value', 'file', 'table'].includes(val.type);

  return (
    val && (
      <TableRow sx={{ '& p': { fontWeight: '500' } }}>
        {isCommonView && getFirstCell(val)}
        {getBodyValue(val, isHighlight, isDoctor || isLaboratory || isAdmin)}
        <TableCell>
          {isCommonView && !isMobileView && patient && idResult && !hideRedesign && (
            <ContextLink
              to='DETAIL_RESULT_INFO'
              params={{
                dossierId: idResult,
                patientId: patient.id,
              }}
              state={{
                selection: [
                  {
                    ref: val.analysis_reference,
                    name: val.name,
                  },
                ],
                patient: patient,
              }}
            >
              <IconButton>
                <SvgIcon component={RightArowIcon} sx={{ fontSize: 28, color: redesignColors.grey.grey2 }} />
              </IconButton>
            </ContextLink>
          )}
        </TableCell>
      </TableRow>
    )
  );
};

export default DetailTabPanelBody;
