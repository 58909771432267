import { Stepper, Step, StepLabel, Stack, styled, StepConnector, stepConnectorClasses, stepLabelClasses, Breadcrumbs } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import ChoosePatients from './components/ChoosePatients';
import ContextLink from 'components/_base/ContextLink';
import StepLayout from './components/StepLayout';
import Address from './components/Address';
import Services from './components/Services';
import { useTranslation } from 'react-i18next';

const StyledStepLabel = styled(StepLabel)(() => ({
  [`&.${stepLabelClasses.root}`]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0,
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    position: 'absolute',
    width: 'auto',
    transform: 'translateY(130%)',
  },
}));

const CustomStepperConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    position: 'relative',
    top: 11,
  },
}));

const CheckIn: FC = () => {
  const {t} = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean}
  >({});
  const [nextButtonActive , setNextButtonActive] = useState(true);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const isFirstStep = () => {
    return activeStep === 0;
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNextButtonActive(true);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  // const handleComplete = () => {
  //   setCompleted({
  //     ...completed,
  //     [activeStep]: true,
  //   });
  //   handleNext();
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted({});
  // };


  const steps = [
    {
      label: t('checkin.stepper.labels.introduction'),
      component: <StepLayout activeNextButton={nextButtonActive} setActiveStep={handleStep(activeStep+1)} isLastStep={isLastStep} isFirstStep={isFirstStep} handleBack={handleBack} component={<ChoosePatients setNextButtonActive={setNextButtonActive}/>} />,
    },
    {
      label:  t('checkin.stepper.labels.address'),
      component: <StepLayout activeNextButton={nextButtonActive} setActiveStep={handleStep(activeStep+1)} isLastStep={isLastStep} isFirstStep={isFirstStep} handleBack={handleBack} component={<Address setNextButtonActive={setNextButtonActive} />} />,
    },
    {
      label:  t('checkin.stepper.labels.services'),
      component: <Services handleBookAppointment={() => {}} handleBack={handleBack} />,
    }
  ];

  return (
    <Stack sx={{ padding: '16px 24px 16px', width: '100%', display: 'flex', justifyContent: 'center' }} spacing={8}>
      {activeStep !== 2 && ( 
        <Stepper
          activeStep={activeStep}
          sx={{
            alignItems: 'flex-start',
            mb: 4,
            '.MuiStepLabel-labelContainer span': {
              fontSize: '16px',
              transform: activeStep % 2 ? 'translateX(40%)' : 'translateX(-40%)',
            },
            '.MuiStepLabel-labelContainer .Mui-active': {
              fontWeight: 700,
              transform: activeStep % 2 ? 'translateX(-40%)' : 'translateX(40%)',
            },
            '.MuiStep-root': {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
          connector={<CustomStepperConnector />}
        >
          {steps.map((step, index) => {
              if (index < 2) {
                return (
                  <Step key={index} completed={completed[index]}>
                    <StyledStepLabel>{step.label}</StyledStepLabel>
                  </Step>
                )
              }

              return null;
          })}
        </Stepper>
      )}

      <Stack sx={{ width: '100%' }} spacing={2}>
        <Breadcrumbs aria-label='breadcrumbs'>
          <ContextLink to='HOME'>
            {t('checkin.breadcrumbs.home')}
          </ContextLink>
          <ContextLink to='CHECKIN'>
            {t('checkin.title')}
          </ContextLink>
          {activeStep === 2 && (
            <ContextLink to="CHECKIN">
              {t('checkin.breadcrumbs.services')}
            </ContextLink>
          )}
        </Breadcrumbs>

        {steps[activeStep].component}
      </Stack>
    </Stack>
  );
};

export default CheckIn;
