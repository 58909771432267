import { Button, Card, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ReactComponent as ArrowBack } from "resources/icons/arrow-back.svg";
import Labs from "./services-cards/Labs";
import Labomobile from "./services-cards/Labomobile";
import PickenDoheem from "./services-cards/PickenDoheem";
import { useTranslation } from "react-i18next";

interface ServicesProps {
   handleBack: () => void;
   handleBookAppointment: () => void;
}

const cardStyles = {
   borderRadius: '8px',
   padding: '24px 16px 16px 16px',
   boxShadow: 'none'
}

const Services: FC<ServicesProps> = ({handleBack}) => {
   const {t} = useTranslation();

   return (
      <Stack sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection:'column', width: '80%', alignSelf: 'center' }} spacing={3}>
         <Stack sx={{ display: 'flex', justifyContent: 'space-between', flexDirection:'row', width: '100%'}}>
            <Button sx={{width: 'fit-content', border: 'none'}} startIcon={<ArrowBack />} onClick={handleBack}>
               <Typography component="a" variant="h6" sx={{ color: 'black' }}>{t('checkin.stepper.steps.services.title')}</Typography> 
            </Button>

            <Button variant="contained">
               {t('checkin.stepper.steps.services.button.make_pre_registration')}
            </Button>
         </Stack>
         

         <Stack spacing={1}>
            <Card sx={cardStyles} variant="outlined">
               <Labs />
            </Card>

            <Card sx={cardStyles} variant="outlined">
               <Labomobile />
            </Card>

            <Card sx={cardStyles} variant="outlined">
               <PickenDoheem />
            </Card>
         </Stack>
      </Stack>
   );
};

export default Services;