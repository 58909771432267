import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { useNotification } from 'hooks';
import ShareIcon from '@mui/icons-material/Share';

import { PreregistrationResponse } from 'models/preregistration.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { redesignColors } from 'resources/theme/theme.colors';
import { convertDate } from 'utils/func/Date.func';
import RoutePaths from 'utils/RoutePaths';

const QrCodeModal: FC<{
  preregistration: PreregistrationResponse;
  onClose: () => void;
}> = ({ preregistration, onClose }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();

  const copyLink = () => {
    const url = `${window.location.origin}${RoutePaths.PREREGISTRATIONS}/${preregistration.qrcode}`;
    navigator.clipboard.writeText(url);
    notification(t('preregistration.list.dialog.copy_notification'), 'success');
  };
  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '28px',
          width: '312px',
          py: 2,
          background: redesignColors.dialogBG,
        },
      }}
      open
      onClose={onClose}
    >
      <DialogContent>
        <Typography variant='h2' textAlign='center' mb={2}>
          {t('preregistration.list.dialog.title')}
        </Typography>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <QRCode value={preregistration.qrcode} size={264} />
            <Typography variant='h3' sx={{ fontSize: '20px', fontWeight: '500' }} mt={2} mb={1} textAlign='center'>
              {preregistration.first_name} {preregistration.last_name}
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '14px', color: redesignColors.grey.grey1 }} mb={3} textAlign='center'>
              {t('preregistration.list.dialog.description', {
                time: convertDate(preregistration.created_at, false, 'HH:mm'),
                date: convertDate(preregistration.created_at, false, 'yyyy-MM-dd'),
                name: `${preregistration.first_name} ${preregistration?.last_name || ''}`,
              })}
            </Typography>

            <Button startIcon={<ShareIcon />} variant='outlined' fullWidth onClick={copyLink}>
              {t('common.action.share')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default QrCodeModal;
